import React, { Component, createRef } from 'react';
import './PriceList.scss';
import ItemProduct from './ItemProduct';
import { ModalWindow } from '../../../commonComponents/ModalWindow/ModalWindow';
import CreateProduct from './CreateProduct';
import { NotificationManager } from 'react-notifications';
import ReactPaginate from 'react-paginate';
import { keyCode } from '../../../assets/constants';
import { IProfileInfo, IProduct } from '../module/interfaces';

interface IState {
  isAddItem: boolean;
  imageUrl: any;
  fileImg: any;
  price: string;
  name: string;
  currency: string | null;
  searchValue: string;
}

interface IProps {
  getSearchProducts: any;
  getCurrenciesList: () => void;
  getMyProfileInfo: () => void;
  createItemProduct: (fileImg: any, name: string, price: string, currency: string | null) => void;
  searchProductList: IProduct[];
  total: number;
  arrCurrencies: [];
  profileInfo: IProfileInfo;
}
const initialState = {
  isAddItem: false,
  imageUrl: null,
  fileImg: null,
  price: '',
  name: '',
  currency: null,
  searchValue: '',
};

class PriceList extends Component<IProps, IState> {

  private readonly refSearchInput: any;

  constructor(props: IProps) {
    super(props);
    this.state = initialState;
    this.refSearchInput = createRef();
  }

  componentDidMount() {
    const { getMyProfileInfo, getSearchProducts } = this.props;
    getMyProfileInfo();
    getSearchProducts();
  }

    addItemProduct = () => {
      this.setState({ isAddItem: true });
      this.props.getCurrenciesList();
    }

    onDropImage = (file: string | any[]) => {
      const reader = new FileReader();
      const uploadFile = file[file.length - 1];
      const fileSize = parseInt(((uploadFile.size / 1024) / 1024).toFixed(2));
      if (file && fileSize < 5) {
        reader.onload = (event) => {
          this.setState({
            // @ts-ignore
            imageUrl: event.target.result, fileImg: uploadFile
          });
        };
        reader.readAsDataURL(uploadFile);
      } else {
        NotificationManager.error('Image size exceeds 5 mb');
      }
    }

    onAddItem = async () => {
      const { fileImg, price, name, currency } = this.state;
      const { createItemProduct } = this.props;
      await createItemProduct(fileImg, name, price, currency);
      // @ts-ignore
      this.setState(initialState);
    }

    handleChangeCurrencies = (selectedOption: { value: any }) => {
      this.setState({ currency: selectedOption.value });
    }

    onPageChange = (currentPage: any) => {
      const { getSearchProducts } = this.props;
      let offset = 0;
      if (currentPage !== 0) {
        offset = currentPage.selected * 6;
      }
      getSearchProducts({ offset });
    };

    handleChangeSearch = (event: { target: { value: string } }) => {
      this.setState({ searchValue: event.target.value });
    }

    submitSearch = () => {
      const { getSearchProducts } = this.props;
      const { searchValue } = this.state;
      getSearchProducts({ search: searchValue });
    }

    enterClick = (event: { keyCode: number }) => {
      if (event.keyCode === keyCode.ENTER) {
        return this.submitSearch();
      }
    };

    cleanSearch = () => {
      const { getSearchProducts } = this.props;
      this.setState({ searchValue: '' });
      this.refSearchInput.current.value = '';
      getSearchProducts();
    }

    render() {
      const { profileInfo, searchProductList, arrCurrencies, total } = this.props;
      const { isAddItem, imageUrl, searchValue } = this.state;
      const totalPage = Math.ceil(total / 6);
      const nextLabel = <div className="arrow arrow_right" />;
      const prevLabel = <div className="arrow arrow_left" />;
      return (
        <>
          <div className="price-list-container">
            <div className="price-list-wrap">
              <span className="station-name">{ profileInfo.name }</span>
              <button onClick={ this.addItemProduct } className="button button-add-item">
                <span className="icon icon-plus" />
                <span className="text-btn-price-regular">{ 'Add item' }</span>
              </button>
              <div className="sorting-panel">
                <span className="text-sorting-panel">{ 'Price list' }</span>
                <div className="sorting-panel-container">
                  <span onClick={ this.submitSearch } className="icon icon-search" />
                  <input
                    onChange={ this.handleChangeSearch }
                    onKeyUp={ this.enterClick }
                    placeholder={ 'Search' }
                    ref={ this.refSearchInput }
                    type="text"
                    className="input input-search-item" />
                  {
                    searchValue
                      ? <span onClick={ this.cleanSearch } className="icon icon-close-search" />
                      : null
                  }
                </div>
              </div>
              <div className="price-list">
                {
                  searchProductList && searchProductList.map((item, index) => (<ItemProduct
                    key={ index }
                    urlImage={ item.photoUrl }
                    price={ item.price }
                    name={ item.name }
                    currency={ item.currency.name }
                    id={ item.id } />))
                }
              </div>
            </div>
            <div className="pagination-wrap">
              <ReactPaginate
                pageCount={ totalPage }
                onPageChange={ this.onPageChange }
                containerClassName="pagination"
                nextLabel={ nextLabel }
                previousLabel={ prevLabel }
                marginPagesDisplayed={ 6 }
                pageRangeDisplayed={ 6 } />
            </div>
          </div>
          <ModalWindow
            modalClassName="modal-add-item"
            isModalOpen={ isAddItem }
          >
            <CreateProduct
              onDrop={ this.onDropImage }
              options={ arrCurrencies }
              image={ imageUrl }
              onCancel={ () => this.setState(initialState) }
              onSave={ this.onAddItem }
              onChangeSelect={ (selectedOption: { value: number }) => this.handleChangeCurrencies(selectedOption) }
              onChangePrice={ (event: { target: { value: string } }) => this.setState({ price: event.target.value }) }
              onChangeName={ (event: { target: { value: string } }) => this.setState({ name: event.target.value }) } />
          </ModalWindow>
        </>
      );
    }

}

export default PriceList;
