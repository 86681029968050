export default {
  GET_INFO_PROFILE_REQUEST: 'GET_INFO_PROFILE_REQUEST',
  GET_INFO_PROFILE_SUCCESS: 'GET_INFO_PROFILE_SUCCESS',
  GET_INFO_PROFILE_FAIL: 'GET_INFO_PROFILE_FAIL',

  UPDATE_INFO_PROFILE_REQUEST: 'UPDATE_INFO_PROFILE_REQUEST',
  UPDATE_INFO_PROFILE_SUCCESS: 'UPDATE_INFO_PROFILE_SUCCESS',
  UPDATE_INFO_PROFILE_FAIL: 'UPDATE_INFO_PROFILE_FAIL',

  GET_CURRENCIES_REQUEST: 'GET_CURRENCIES_REQUEST',
  GET_CURRENCIES_SUCCESS: 'GET_CURRENCIES_SUCCESS',
  GET_CURRENCIES_FAIL: 'GET_CURRENCIES_FAIL',

  GET_SEARCH_PRODUCT_REQUEST: 'GET_SEARCH_PRODUCT_REQUEST',
  GET_SEARCH_PRODUCT_SUCCESS: 'GET_SEARCH_PRODUCT_SUCCESS',
  GET_SEARCH_PRODUCT_FAIL: 'GET_SEARCH_PRODUCT_FAIL',

  DELETE_ITEM_PRODUCT_REQUEST: 'DELETE_ITEM_PRODUCT_REQUEST',
  DELETE_ITEM_PRODUCT_SUCCESS: 'DELETE_ITEM_PRODUCT_SUCCESS',
  DELETE_ITEM_PRODUCT_FAIL: 'DELETE_ITEM_PRODUCT_FAIL',

  CREATE_ITEM_PRODUCT_REQUEST: 'CREATE_ITEM_PRODUCT_REQUEST',
  CREATE_ITEM_PRODUCT_SUCCESS: 'CREATE_ITEM_PRODUCT_SUCCESS',
  CREATE_ITEM_PRODUCT_FAIL: 'CREATE_ITEM_PRODUCT_FAIL',

  GET_ITEM_PRODUCT_REQUEST: 'GET_ITEM_PRODUCT_REQUEST',
  GET_ITEM_PRODUCT_SUCCESS: 'GET_ITEM_PRODUCT_SUCCESS',
  GET_ITEM_PRODUCT_FAIL: 'GET_ITEM_PRODUCT_FAIL',

  UPDATE_ITEM_PRODUCT_REQUEST: 'UPDATE_ITEM_PRODUCT_REQUEST',
  UPDATE_ITEM_PRODUCT_SUCCESS: 'UPDATE_ITEM_PRODUCT_SUCCESS',
  UPDATE_ITEM_PRODUCT_FAIL: 'UPDATE_ITEM_PRODUCT_FAIL',

  GET_COUNTRIES_REQUEST: 'GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAIL: 'GET_COUNTRIES_FAIL',

  GET_SELF_BRANDS_REQUEST: 'GET_SELF_BRANDS_REQUEST',
  GET_SELF_BRANDS_SUCCESS: 'GET_SELF_BRANDS_SUCCESS',
  GET_SELF_BRANDS_FAIL: 'GET_SELF_BRANDS_FAIL',

  CHANGE_PASS_STATION_REQUEST: 'CHANGE_PASS_STATION_REQUEST',
  CHANGE_PASS_STATION_SUCCESS: 'CHANGE_PASS_STATION_SUCCESS',
  CHANGE_PASS_STATION_FAIL: 'CHANGE_PASS_STATION_FAIL',

  GET_SCHEDULE_LIST_REQUEST: 'GET_SCHEDULE_LIST_REQUEST',
  GET_SCHEDULE_LIST_SUCCESS: 'GET_SCHEDULE_LIST_SUCCESS',
  GET_SCHEDULE_LIST_FAIL: 'GET_SCHEDULE_LIST_FAIL',

  UPDATE_START_TIME: 'UPDATE_START_TIME',

  UPDATE_END_TIME: 'UPDATE_END_TIME',

  CLOSED_DAY: 'CLOSED_DAY',

  UPDATE_LIST_SCHEDULE_REQUEST: 'UPDATE_LIST_SCHEDULE_REQUEST',
  UPDATE_LIST_SCHEDULE_SUCCESS: 'UPDATE_LIST_SCHEDULE_SUCCESS',
  UPDATE_LIST_SCHEDULE_FAIL: 'UPDATE_LIST_SCHEDULE_FAIL',

};

