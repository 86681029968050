import React from 'react';
import { render } from 'react-dom';
import {
  createStore, applyMiddleware, compose
} from 'redux';
import { Provider } from 'react-redux';
import { routerMiddleware } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import reducer from './reducers';
import App from './App';
import { translations } from './assets/translations/translations';
import I18n from 'redux-i18n';
export const history = createBrowserHistory();
const initialState = {};
const enhancers = [];
const middleware = [
  thunkMiddleware,
  routerMiddleware(history),
];

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  if (typeof composeEnhancers === 'function') {
    enhancers.push(composeEnhancers());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);
const locale = localStorage.getItem('locale');
const lang = !locale ? 'en' : locale;

export const store = createStore(
  reducer,
  initialState,
  composedEnhancers
);

render(
  <Provider
    store={ store }
  >
    <I18n translations={ translations } initialLang={ lang }>
      <Router
        history={ history }
      >
        <App />
      </Router>
    </I18n>
  </Provider>,
  document.getElementById('root')
);

