import EditProfile from './EditProfile';
import { connect } from 'react-redux';
import { getMyProfileInfo, updateProfileInfo } from '../module/actions';
import { IProfileStation } from '../module/interfaces';

const mapStateToProps = (state: { profileStationReducer: IProfileStation, i18nState: IProfileStation }) => ({
  profileInfo: state.profileStationReducer.profileInfo,
  language: state.i18nState.lang,
});

const mapDispatchToProps = {
  updateProfileInfo,
  getMyProfileInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
