import React, { Component } from 'react';
import './ChangePassword.scss';
import { history } from '../../../index';
import { Input } from '../../../commonComponents/Input/Input';
import { makeFieldValidation } from '../../../services/utils';
import { validationPatterns } from '../../../assets/constants';
import ls from 'local-storage';

interface IProps {
  profileInfo: {
    id: number
  };
  changePassAction: (data: object, idStation: number) => void;
  errorClear: () => void
}

interface IState {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
  isPasswordVisible: boolean;
  errorPass: boolean;
  errorNewPass: boolean;
  errorConfirmPass: boolean;
}

class ChangePassword extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      isPasswordVisible: false,
      errorPass: false,
      errorNewPass: false,
      errorConfirmPass: false,
    };
  }

  handleChangeField = (name: string, value: string) => {
    this.setState({ [name]: value } as unknown as IState);
  }

  toggleVisibleOfPassword = () => {
    const {
      isPasswordVisible,
    } = this.state;
    return this.setState({ isPasswordVisible: !isPasswordVisible });
  }

  handlePass = (event: { target: { value: string } }) => {
    const isPasswordInvalid = makeFieldValidation(event.target.value, validationPatterns.password);
    // @ts-ignore
    const isOldPass = event.target.value !== ls.get('password');
    if (isPasswordInvalid || isOldPass) {
      this.setState({ errorPass: true });
    } else {
      this.clearErrorPass();
    }
  };

  clearErrorPass = () => {
    const { errorPass } = this.state;
    const { errorClear } = this.props;

    if (errorPass) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorPass: false });
    }
  };

  submitNewPass = () => {
    const { oldPassword, newPassword, newPasswordRepeat, errorPass, errorNewPass, errorConfirmPass } = this.state;
    const { profileInfo, changePassAction } = this.props;
    const isValid = errorPass || errorNewPass || errorConfirmPass;
    if (!isValid) {
      changePassAction({
        oldPassword, newPassword, newPasswordRepeat
      }, profileInfo.id);
      localStorage.setItem('password', newPassword);
    }
  }

  handleNewPass = (event: { target: { value: string } }) => {
    const isPasswordInvalid = makeFieldValidation(event.target.value, validationPatterns.password);
    if (isPasswordInvalid) {
      this.setState({ errorNewPass: true });
    } else {
      this.clearErrorNewPass();
    }
  };

  clearErrorNewPass = () => {
    const { errorNewPass } = this.state;
    const { errorClear } = this.props;

    if (errorNewPass) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorNewPass: false });
    }
  };

  handleConfirmPass = (event: { target: { value: string } }) => {
    const { newPasswordRepeat, newPassword } = this.state;
    const isPasswordRepeat = makeFieldValidation(event.target.value, validationPatterns.password);
    const isPasswordInvalid = newPassword !== newPasswordRepeat;
    if (isPasswordInvalid || isPasswordRepeat) {
      this.setState({ errorConfirmPass: true });
    } else {
      this.clearErrorConfirmPass();
    }
  };

  clearErrorConfirmPass = () => {
    const { errorConfirmPass } = this.state;
    const { errorClear } = this.props;

    if (errorConfirmPass) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorConfirmPass: false });
    }
  };

  render() {
    const { oldPassword, newPassword, newPasswordRepeat, isPasswordVisible,
      errorPass, errorNewPass, errorConfirmPass } = this.state;
    return (
      <div className="edit-profile">
        <div className="edit-profile-container">
          <div className="navigation-wrap">
            <span onClick={ () => history.push('/station-service/profile') }
              className="icon icon-btn-back icon-btn-back--gallery" />
            <span className="station-name station-name--gallery">{ 'Change password' }</span>
          </div>
          <div className="edit-profile edit-profile__wrap edit-profile__wrap__password">
            <div className="change-pass-form">
              <Input className="input input-change-password"
                labelName={ 'Registered password*' }
                name={ 'oldPassword' }
                maxLength={ 30 }
                value={ oldPassword }
                error={ errorPass }
                onBlur={ (event: { target: { value: string } }) => this.handlePass(event) }
                onClickToggle={ this.toggleVisibleOfPassword }
                isPasswordVisible={ isPasswordVisible }
                onChange={ (event: any) => this.handleChangeField('oldPassword', event.target.value) }
                type={ isPasswordVisible ? 'text' : 'password' } />
              { errorPass ? <span className="error-text"> { 'Wrong password' }</span> : null }
            </div>
            <div className="change-pass-form">
              <Input className="input input-change-password"
                labelName={ 'New password*' }
                name={ 'newPassword' }
                value={ newPassword }
                error={ errorNewPass }
                onClickToggle={ this.toggleVisibleOfPassword }
                onBlur={ (event: {target : { value: string }}) => this.handleNewPass(event) }
                isPasswordVisible={ isPasswordVisible }
                maxLength={ 30 }
                onChange={ (event: any) => this.handleChangeField('newPassword', event.target.value) }
                type={ isPasswordVisible ? 'text' : 'password' } />
              { errorNewPass ? <span className="error-text">
                { 'Must be min 8 characters and include at least 1 letter, 1 number' }
                               </span> : null }
            </div>
            <div className="change-pass-form">
              <Input className="input input-change-password"
                labelName={ 'Confirm new password*' }
                name={ 'newPasswordRepeat' }
                value={ newPasswordRepeat }
                onClickToggle={ this.toggleVisibleOfPassword }
                isPasswordVisible={ isPasswordVisible }
                maxLength={ 30 }
                error={ errorConfirmPass }
                onBlur={ (event: {target : { value: string }}) => this.handleConfirmPass(event) }
                onChange={ (event: any) => this.handleChangeField('newPasswordRepeat', event.target.value) }
                type={ isPasswordVisible ? 'text' : 'password' } />
              { errorConfirmPass ? <span className="error-text">{ 'Must be the same as password' }</span> : null }
            </div>
          </div>
        </div>
        <div className="button-wrap-sing-out">
          <button onClick={ () => history.push('/station-service/profile') } className="button button-cancel">{ 'Cancel' }</button>
          <button onClick={ this.submitNewPass } className="button button-save">{ 'Save' }</button>
        </div>
      </div>
    );
  };

}

export default ChangePassword;
