import ItemReminder from './ItemReminder';
import { connect } from 'react-redux';
import { deleteItemReminder, updateItemReminder } from '../module/actions';

const mapDispatchToProps = {
  deleteItemReminder,
  updateItemReminder,
};

export default connect(null, mapDispatchToProps)(ItemReminder);
