import React from 'react';
import './FilterPanel.scss';
import Select from 'react-select';

interface IProps {
  arrOptionsEmployee?: [];
  onCloseFilter?: () => void;
  onChangeEmployee?: any;
  onResetFilter?: () => void;
  refEmployee?: any;
  refVin?: any;
  onChangeVin?: any;
  onChangeOwnerName?: any;
  onChangePhone?: any;
  arrOptionsBrands?: [];
  onChangeBrand?: any;
  arrOptionsModels?: [];
  onChangeModel?: any;
  refBrand?: any;
  refModel?: any;
  refName?: any;
  refPhone?: any;
}

export const FilterPanel: React.FC<IProps> = (props) => {
  const { arrOptionsEmployee, onChangeOwnerName, onCloseFilter, onChangeVin, onChangePhone,
    onResetFilter, refEmployee, refVin, refBrand, onChangeEmployee, arrOptionsBrands, onChangeBrand,
    arrOptionsModels, onChangeModel, refPhone, refName, refModel } = props;
  return (
    <div className="filter-container">
      <div className="filter-wrap">
        <div className="filter">
          <span className="title-filter">{ 'Filter' }</span>
          <span onClick={ onCloseFilter } className="icon icon-close-filter" />
        </div>
        <div className="filter-field">
          <span className="field-label">{ 'Station employee' }</span>
          <Select
            className="filter-select-wrap"
            placeholder={ 'Enter  station employee' }
            classNamePrefix="filter-select"
            name={ 'employee' }
            ref={ refEmployee }
            isSearchable
            onChange={ onChangeEmployee }
            options={ arrOptionsEmployee } />
        </div>
        <div className="filter-field">
          <span className="field-label">{ 'VIN' }</span>
          <input onChange={ onChangeVin } name={ 'vin' }
            placeholder={ 'Enter VIN number' }
            type="text"
            maxLength={ 17 }
            ref={ refVin }
            className="input-filter" />
        </div>
        <div className="filter-field">
          <span className="field-label">{ 'Vehicle Brand' }</span>
          <Select
            className="filter-select-wrap"
            placeholder={ 'Vehicle brand' }
            ref={ refBrand }
            name={ 'brand' }
            onChange={ onChangeBrand }
            isSearchable
            options={ arrOptionsBrands }
            classNamePrefix="filter-select" />
        </div>
        <div className="filter-field">
          <span className="field-label">{ 'Vehicle Model' }</span>
          <Select
            className="filter-select-wrap"
            placeholder={ 'Vehicle model' }
            ref={ refModel }
            name={ 'model' }
            options={ arrOptionsModels }
            onChange={ onChangeModel }
            classNamePrefix="filter-select" />
        </div>
        <div className="filter-field">
          <span className="field-label">{ 'Vehicle owner name' }</span>
          <input onChange={ onChangeOwnerName } name={ 'ownerName' }
            ref={ refName }
            maxLength={ 50 }
            placeholder={ 'Enter vehicle owner name' } type="text" className="input-filter" />
        </div>
        <div className="filter-field">
          <span className="field-label">{ 'Vehicle owner telephone number' }</span>
          <input onChange={ onChangePhone } name={ 'ownerPhoneNumber' }
            ref={ refPhone }
            maxLength={ 14 }
            placeholder={ 'Enter phone number' } type="number" className="input-filter" />
        </div>
      </div>
      <div className="filter-reset-wrap">
        <span className="line-filter" />
        <span onClick={ onResetFilter } className="reset-filter-text">{ 'Reset the filters' }</span>
      </div>
    </div>
  );
};
