import React, { Component } from 'react';
import './VehiclePage.scss';
import PropTypes from 'prop-types';
import ItemVehicle from './ItemVehicle';
import { IVehicle } from './module/interfaces';
import ReactPaginate from 'react-paginate';

interface IProps {
  ownerPhoneNumber: string;
  ownerName: string;
  manufacturingYear: number;
  vin: string;
  model: any;
  brand: any;
  getMyProfileInfo: () => void;
  profileInfo: {
    name: string
  };
  getVehicleList: any;
  vehicleList: IVehicle[];
  totalVehicle: number;
}

class VehiclePage extends Component<IProps, {}> {

  componentDidMount() {
    const { getMyProfileInfo, getVehicleList } = this.props;
    getMyProfileInfo();
    getVehicleList();
  }

  onPageChange = (currentPage: any) => {
    const { getVehicleList } = this.props;
    let offset = 0;
    if (currentPage !== 0) {
      offset = currentPage.selected * 10;
    }
    getVehicleList({ offset });
  }

  render() {
    const { profileInfo, vehicleList, totalVehicle } = this.props;
    const totalPage = Math.ceil(totalVehicle / 10);
    const nextLabel = <div className="arrow arrow_right" />;
    const prevLabel = <div className="arrow arrow_left" />;
    return (
      <>
        <div className="reminders-container">
          <div className="reminders-wrap">
            <span className="station-name station-name--reminders">{ profileInfo.name }</span>
            <div className="button-employee-wrap">
              <button className="button btn btn_filter">
                <span className="icon icon-filter" />
                <span className="text-btn">{ this.context.t('filter_btn') }</span>
              </button>
              <button className="button button-add-vehicle">
                <span className="icon icon-add-vehicle" />
                <span className="text-btn-price-regular">{ this.context.t('add_vehicle_text') }</span>
              </button>
            </div>
            <div className="sorting-panel">
              <span className="text-sorting-panel">{ this.context.t('vehicle_list_text') }</span>
            </div>
            <div className="employee-list">
              { (totalVehicle !== 0)
                ? vehicleList?.map((item) => (
                  <ItemVehicle
                    brandCar={ item.brand.name }
                    modelCar={ item.model.name }
                    vinNumber={ item.vin }
                    id={ item.id }
                    yearCar={ item.manufacturingYear }
                    ownerName={ item.ownerName }
                    ownerPhone={ item.ownerPhoneNumber } />
                ))
                : <div className="empty-state-booking">
                  <span className="empty-state-text">{ this.context.t('empty_state_vehicle') }</span>
                  <span className="icon icon-empty-state icon-empty-state--vehicle" />
                  </div>
              }
            </div>
          </div>
          {
            (totalVehicle !== 0)
              ? <div className="pagination-wrap">
                <ReactPaginate
                  pageCount={ totalPage }
                  onPageChange={ this.onPageChange }
                  containerClassName="pagination"
                  nextLabel={ nextLabel }
                  previousLabel={ prevLabel }
                  marginPagesDisplayed={ 6 }
                  pageRangeDisplayed={ 6 } />
                </div> : null
          }
        </div>
      </>
    );
  };

}
// @ts-ignore
VehiclePage.contextTypes = { t: PropTypes.func.isRequired };

export default VehiclePage;
