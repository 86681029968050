import React, { Component } from 'react';
import { Input } from '../../commonComponents/Input/Input';
import './SignIn.scss';
import { validationPatterns } from '../../assets/constants';
import { makeFieldValidation } from '../../services/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';

interface IProps {
  serviceStationSignIn: (data: object) => void;
  errorClear: () => void;
  sendEmailToPassRecovery: (email: { email: string }) => void;
  isOpen: boolean;
  statusRestore: number;
}

interface IState {
  email: string;
  password: string;
  error: boolean;
  isRecoveryPassword: boolean;
  isSubmitButtonDisabled: boolean;
  errorPass: boolean;
  errorEmail: boolean;
  errorPassValid: boolean;
  isPasswordVisible: boolean;
}

class SignIn extends Component <IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: false,
      errorPass: false,
      errorEmail: false,
      isRecoveryPassword: false,
      isSubmitButtonDisabled: true,
      errorPassValid: false,
      isPasswordVisible: false,
    };
  }

  onFieldChangeEmail = (event: { target: { value: any } }) => this.setState({ email: event.target.value }, () => {
    this.clearErrorEmail();
    this.handleButtonDisable();
  });

  onFieldChangePassword = (event: { target: { value: any } }) => this.setState({ password: event.target.value }, () => {
    this.clearErrorPass();
    this.handleButtonDisable();
  });

  clearErrorPass = () => {
    const { errorPass, errorPassValid } = this.state;
    const { errorClear } = this.props;

    if (errorPass) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorPass: false });
    } else if (errorPassValid) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorPassValid: false });
    }
  };

  clearErrorEmail = () => {
    const { errorEmail } = this.state;
    const { errorClear } = this.props;

    if (errorEmail) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorEmail: false });
    }
  };

  onLoginClick = () => {
    const { email, password } = this.state;
    const { serviceStationSignIn } = this.props;
    const isEmailInvalid = makeFieldValidation(email, validationPatterns.email);
    const isPasswordInvalid = makeFieldValidation(password, validationPatterns.password);
    if (isEmailInvalid || isPasswordInvalid) {
      return this.setState({
        errorPass: true, errorEmail: true, isSubmitButtonDisabled: true
      }, () => this.handleButtonDisable());
    }
    const data = {
      email, password
    };
    serviceStationSignIn(data);
  };

  onRecoveryPassword = () => {
    const { email } = this.state;
    const { sendEmailToPassRecovery } = this.props;
    const isEmailInvalid = makeFieldValidation(email, validationPatterns.email);
    if (isEmailInvalid) {
      return this.setState({
        errorEmail: true, isSubmitButtonDisabled: true
      }, () => this.handleButtonDisable());
    }

    sendEmailToPassRecovery({ email });
  };

  handleButtonDisable = () => {
    const { email, password, isRecoveryPassword } = this.state;
    if (isRecoveryPassword) {
      return this.setState({ isSubmitButtonDisabled: !email.length });
    }
    return this.setState({ isSubmitButtonDisabled: !email.length || !password.length });
  };

  changeOriginalPassword = (event: { target: { value: string } }) => {
    const isPasswordInvalid = makeFieldValidation(event.target.value, validationPatterns.password);
    if (isPasswordInvalid && event.target.value) {
      this.setState({ errorPassValid: true });
    } else if (!event.target.value.length) {
      this.setState({ errorPass: true });
    } else {
      this.clearErrorPass();
    }
  };

  changeOriginalEmail = (event: { target: { value: string } }) => {
    const isPasswordInvalid = makeFieldValidation(event.target.value, validationPatterns.email);
    if (isPasswordInvalid) {
      this.setState({ errorEmail: true });
    } else {
      this.clearErrorEmail();
    }
  };

  toggleVisibleOfPassword = () => {
    const {
      isPasswordVisible,
    } = this.state;
    return this.setState({ isPasswordVisible: !isPasswordVisible });
  };

  render() {
    const { isRecoveryPassword, email, errorPassValid, errorPass, errorEmail, isSubmitButtonDisabled, isPasswordVisible } = this.state;
    const { statusRestore, isOpen } = this.props;
    if (isRecoveryPassword) {
      return (
        <div className="sign-in-wrap">
          <div className="icon-sign-in-background">
            <span className="icon icon-sign-in" />
          </div>
          {
            (isOpen && statusRestore !== 200)
              ? <>
                <span className="sign-in-text">{ this.context.t('restore_password') }</span>
                <div className="restore-email">
                  <Input
                    labelName={ this.context.t('email_label') }
                    error={ errorEmail }
                    className="input input-sign-in"
                    placeholder={ 'Enter your registered email address' }
                    type={ 'email' }
                    name={ 'email' }
                    onBlur={ (event: { target: { value: string } }) => this.changeOriginalEmail(event) }
                    onChange={ this.onFieldChangeEmail }
                    value={ email }
                    maxLength={ 30 } />
                  { errorEmail && !email
                    ? <span className="error-text">{ this.context.t('required_filed_text') }</span> : null }
                  { errorEmail && email ? <span className="error-text">{ this.context.t('valid_email') }</span> : null }
                </div>
                <button onClick={ this.onRecoveryPassword } disabled={ isSubmitButtonDisabled }
                  className={ classNames('button button-recovery', { 'disabled': isSubmitButtonDisabled }) }
                >{ this.context.t('restore_password') }
                </button>
                </>
              : <span className="sign-in-text">{ this.context.t('text_restore_pass_success') }</span>

          }
        </div>
      );
    }
    return (
      <div className="sign-in-wrap">
        <div className="icon-sign-in-background">
          <span className="icon icon-sign-in" />
        </div>
        <span className="sign-in-text">{ this.context.t('sign_in') }</span>
        <div className={ classNames('login-form', { 'error-wrap': errorEmail }) }>
          <Input
            labelName={ this.context.t('email_label') }
            error={ errorEmail }
            onBlur={ (event: { target: { value: string } }) => this.changeOriginalEmail(event) }
            className="input input-sign-in"
            placeholder={ 'Enter your registered email' }
            type={ 'email' }
            name={ 'email' }
            onChange={ this.onFieldChangeEmail }
            value={ this.state.email }
            maxLength={ 30 } />
          { errorEmail && !email ? <span className="error-text">{ this.context.t('valid_email') }</span> : null }
          { errorEmail && email ? <span className="error-text">{ this.context.t('validation_error_email') }</span> : null }
        </div>
        <div className={ classNames('login-form', { 'error-wrap': errorPass || errorPassValid }) }>
          <Input
            labelName={ this.context.t('registered_pass_label') }
            error={ errorPass || errorPassValid }
            onBlur={ (event: { target: { value: string } }) => this.changeOriginalPassword(event) }
            className="input input-sign-in"
            placeholder={ 'Enter your registered password' }
            type={ isPasswordVisible ? 'text' : 'password' }
            name={ 'password' }
            onClickToggle={ this.toggleVisibleOfPassword }
            isPasswordVisible={ isPasswordVisible }
            onChange={ this.onFieldChangePassword }
            value={ this.state.password }
            maxLength={ 30 } />
          { errorPass ? <span className="error-text">{ this.context.t('required_filed_text') }</span> : null }
          { errorPassValid ? <span className="error-text">{ this.context.t('error_pass') }</span> : null }
        </div>
        <div className="button-wrap-sign-in">
          <button onClick={ this.onLoginClick } disabled={ isSubmitButtonDisabled }
            className={ classNames('button button-sign-in',
              { 'disabled': isSubmitButtonDisabled || errorEmail || errorPass || errorPassValid }
            ) }
          >{ this.context.t('sign_in') }
          </button>
          <button onClick={ () => this.setState({
            isRecoveryPassword: true, errorPass: false, errorEmail: false, email: ''
          }) }
          className="button button-restore-password"
          >{ this.context.t('restore_password') }
          </button>
        </div>
      </div>
    );
  };

}

// @ts-ignore
SignIn.contextTypes = { t: PropTypes.func.isRequired };

export default SignIn;
