import VehiclePage from './VehiclePage';
import { connect } from 'react-redux';
import { getMyProfileInfo } from '../ProfileInfoPage/module/actions';
import { IProfileStation } from '../ProfileInfoPage/module/interfaces';
import { getVehicleList } from './module/actions';
import { IVehicleReducer } from './module/interfaces';

const mapStateToProps = (state: { profileStationReducer: IProfileStation, vehicleReducer: IVehicleReducer }) => ({
  profileInfo: state.profileStationReducer.profileInfo,
  vehicleList: state.vehicleReducer.vehicleList,
  totalVehicle: state.vehicleReducer.totalVehicle
});

const mapDispatchToProps = {
  getMyProfileInfo,
  getVehicleList
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclePage);

