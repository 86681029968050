export const EN = {
  edit_profile_btn: 'Edit profile',
  text_log_out: 'Are you sure you want to sign out ?',
  valid_email: 'Invalid email format',
  sign_in: 'Sign in',
  station_added_text: 'Service station was added',
  phone_number: 'Phone number',
  second_phone_number: 'Additional phone number',
  third_phone_number: 'Additional phone number',
  e_mail: 'E-mail',
  station_verified_text: 'Service station\'s account verified',
  country_text: 'Country',
  city_text: 'City',
  address_text: 'Address',
  price_list_btn: 'Price list',
  change_password: 'Change password',
  sign_out: 'Sign out',
  photos_title: 'Photos',
  schedule_title: 'Schedule',
  brands_title: 'Brands',
  view_photos_btn: 'View photos',
  view_list_btn: 'View list',
  edit_schedule_btn: 'Edit schedule',
  car_text: 'Car',
  moto_text: 'Motorcycle',
  save_btn: 'Save',
  empty_state_brands: 'Click the list above to add brands that you service so that customers know more about you',
  add_brands_text: 'Add brands',
  delete_btn: 'Delete',
  add_photo_btn: 'Add photo',
  empty_state_photos: 'You have no photos yet :(',
  day_off_text: 'Day off',
  start_time: 'Start time',
  end_time: 'End time',
  monday_text: 'Monday',
  tuesday_text: 'Tuesday',
  wednesday_text: 'Wednesday',
  thursday_text: 'Thursday',
  friday_text: 'Friday',
  saturday_text: 'Saturday',
  sunday_text: 'Sunday',
  empty_state_preview_gallery: 'You have no photos yet :(\nPlease, click Add photos to add them',
  restore_password: 'Restore password',
  email_label: 'Email',
  password_label: 'Password',
  text_restore_pass_success: 'Link has been sent to your email.\nPlease, check it.',
  new_password_label: 'New password',
  confirm_password_label: 'Confirm new password',
  submit_btn: 'Submit',
  account_blocked_text: 'Your account was blocked by the administrator',
  contact_text_carvis: ' Please, contact us for more details: info@carvis.app',
  text_success_pass_change: 'Your password has been successfully changed.',
  error_pass: 'Must be min 8 characters and include at least 1 letter, 1 number',
  error_confirm_pass: 'Must be the same as password',
  required_filed_text: 'This field is required',
  add_item_btn: 'Add item',
  search_text: 'Search',
  special_offers_text: 'Special offers',
  price_text: 'Price',
  successfully_verified: 'Your email has been successfully verified ',
  sub_text_verified: 'Now create a password for your account to be able to log in',
  placeholder_create_pass: 'Enter your new password',
  placeholder_confirm_pass: 'Confirm your new password',
  registered_pass_label: 'Registered password',
  cancel_btn: 'Cancel',
  item_name_label: 'Item name',
  currency_label: 'Currency',
  enter_currency_text: 'Enter currency',
  adding_item_text: 'Adding item',
  edit_text: 'Edit',
  remove_btn: 'Remove',
  show_item_app_text: 'Show item in app',
  remove_item_title: 'Remove item',
  sub_text_remove_item: 'Are you sure you want to remove an item?',
  filter_btn: 'Filter',
  add_booking_btn: 'Add booking',
  booking_list: 'Booking list',
  requests_text: 'Requests',
  sort_by_text: 'Sort by',
  status_upcoming: 'Upcoming',
  status_done: 'Done',
  create_service_record: 'Create service record',
  sorting_early_late: 'From early to late',
  sorting_late_early: 'From late to early',
  sorting_employee: 'Station employee',
  sorting_upcoming_first: 'Upcoming first',
  sorting_done_first: 'Done first',
  empty_state_booking: 'You don’t have any records yet',
  data_label: 'Date',
  station_employee_label: 'Station employee',
  booking_text_label: 'Booking text',
  select_vehicle: 'Select vehicle from the list',
  new_vehicle_text: 'New vehicle',
  vehicle: 'Vehicle',
  telephone_number_booking: 'Telephone number',
  vehicle_owner_name: 'Vehicle owner name',
  placeholder_select_vehicle: 'Select vehicle from the list',
  placeholder_station_employees: 'Enter station employee\'s name',
  delete_booking_text: 'Delete booking',
  delete_booking_sub_text: 'Are you sure you want to delete this booking from booking list?',
  unassigned_text: 'Unassigned',
  confirm_booking_request_text: 'Confirm booking request',
  assign_station: 'Assign station employee',
  confirm_btn: 'Confirm',
  reset_filter_btn: 'Reset the filters',
  vehicle_brand: 'Vehicle Brand',
  vehicle_model: 'Vehicle Model',
  vehicle_owner_telephone_number: 'Vehicle owner telephone number',
  add_employee_btn: 'Add employee',
  employees: 'Employees',
  empty_state_employee: 'You don’t have any employee yet',
  success_add_employee: 'Employee have been successfully added!',
  delete_employee_text: 'Employee have been removed!',
  remove_employee_text: 'Remove employee',
  remove_employee_sub_text: 'Are you sure you want to remove this employee from employees list?',
  empty_state_reminders: 'You don’t have any reminders yet',
  reminders_list_text: 'Reminders list',
  add_reminder_btn: 'Add reminder',
  delete_reminder_title: 'Delete reminder',
  delete_reminder_text: 'Are you sure you want to delete this reminder from reminders list?',
  empty_state_vehicle: 'You don’t have any vehicle in your list yet',
  add_vehicle_text: 'Add vehicle',
  vehicle_list_text: 'Vehicle list',
};
