import constants from './constants';
import { IAction, IState } from './interfaces';

export const initialState: IState = {
  isFetching: false,
  error: null,
  isOpenForm: false,
};

const serviceStation = (state: IState = initialState, action: IAction) => {
  switch (action.type) {
    case constants.VERIFICATION_EMAIL_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isOpenForm: false,
      };
    }

    case constants.VERIFICATION_EMAIL_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isOpenForm: true,
        error: null,
      };
    }

    case constants.VERIFICATION_EMAIL_FAIL: {
      return {
        ...state,
        isFetching: false,
        isOpenForm: false,
      };
    }

    default:
      return state;
  }
};

export default serviceStation;
