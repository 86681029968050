export default {
  SERVICE_STATION_SIGN_IN_REQUEST: 'SERVICE_STATION_SIGN_IN_REQUEST',
  SERVICE_STATION_SIGN_IN_SUCCESS: 'SERVICE_STATION_SIGN_IN_SUCCESS',
  SERVICE_STATION_SIGN_IN_FAIL: 'SERVICE_STATION_SIGN_IN_FAIL',

  ERROR_CLEAR: 'ERROR_CLEAR',

  LOG_OUT_STATION: 'LOG_OUT_STATION',

  EMAIL_STATION_RESTORATION_REQUEST: 'EMAIL_STATION_RESTORATION_REQUEST',
  EMAIL_STATION_RESTORATION_SUCCESS: 'EMAIL_STATION_RESTORATION_SUCCESS',
  EMAIL_STATION_RESTORATION_FAIL: 'EMAIL_STATION_RESTORATION_FAIL',

  PASS_RECOVERY_REQUEST: 'PASS_RECOVERY_REQUEST',
  PASS_RECOVERY_SUCCESS: 'PASS_RECOVERY_SUCCESS',
  PASS_RECOVERY_FAIL: 'PASS_RECOVERY_FAIL',

  SET_LOCALE: 'SET_LOCALE',
};
