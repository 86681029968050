import constants from './constants';
import { IAction, IState } from './interfaces';
import { timeFormat } from '../../../services/utils';

export const initialState: IState = {
  isFetching: false,
  error: null,
  profileInfo: {},
  searchProductList: [],
  currencies: [],
  productItems: {},
  isOpen: false,
  countries: [],
  arrCar: [],
  arrMotorcycle: [],
  scheduleList: [],
};

enum TypeVehicle {
  car = 'CAR',
  motorcycle = 'MOTO'
}

const profileStationReducer = (state: IState = initialState, action: IAction) => {
  switch (action.type) {
    case constants.GET_INFO_PROFILE_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_INFO_PROFILE_SUCCESS: {
      return {
        ...state,
        profileInfo: action.payload,
        arrCar: action.payload.brands.filter((item: { vehicleType: string }) => item.vehicleType === TypeVehicle.car),
        arrMotorcycle: action.payload.brands.filter((item: { vehicleType: string }) => item.vehicleType === TypeVehicle.motorcycle),
        isFetching: false,
        error: null,
      };
    }

    case constants.GET_INFO_PROFILE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.UPDATE_INFO_PROFILE_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isOpen: true,
      };
    }

    case constants.UPDATE_INFO_PROFILE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        isOpen: false
      };
    }

    case constants.UPDATE_INFO_PROFILE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        isOpen: true,
      };
    }

    case constants.GET_SEARCH_PRODUCT_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_SEARCH_PRODUCT_SUCCESS: {
      return {
        ...state,
        searchProductList: action.payload.data,
        total: action.payload.total,
        isFetching: false,
      };
    }

    case constants.GET_SEARCH_PRODUCT_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case constants.DELETE_ITEM_PRODUCT_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.DELETE_ITEM_PRODUCT_SUCCESS: {
      return {
        ...state,
        searchProductList: state.searchProductList.filter((item) => item.id !== action.payload),
        isFetching: false,
      };
    }

    case constants.DELETE_ITEM_PRODUCT_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.GET_CURRENCIES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_CURRENCIES_SUCCESS: {
      const arrCurrencies = action.payload.data.map((item: { id: number, name: string, }) => ({
        value: item.id,
        label: item.name
      }));
      return {
        ...state,
        isFetching: false,
        currencies: arrCurrencies,
        error: null,
      };
    }

    case constants.GET_CURRENCIES_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.CREATE_ITEM_PRODUCT_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.CREATE_ITEM_PRODUCT_SUCCESS: {
      return {
        ...state,
        searchProductList: [...state.searchProductList, action.payload.data],
        isFetching: false,
      };
    }

    case constants.CREATE_ITEM_PRODUCT_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.GET_ITEM_PRODUCT_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_ITEM_PRODUCT_SUCCESS: {
      return {
        ...state,
        productItems: action.payload,
        isFetching: false,
      };
    }
    case constants.GET_ITEM_PRODUCT_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case constants.UPDATE_ITEM_PRODUCT_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.UPDATE_ITEM_PRODUCT_SUCCESS: {
      return {
        ...state,
        searchProductList: state.searchProductList.map((item) => (item.id === action.payload.id ? { ...action.payload.data } : item)),
        isFetching: false,
      };
    }

    case constants.UPDATE_ITEM_PRODUCT_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.GET_COUNTRIES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_COUNTRIES_SUCCESS: {
      const arrCountries = action.payload.data.map((item: { id: number, name: string }) => ({
        value: item.id,
        label: item.name
      }));
      return {
        ...state,
        isFetching: false,
        countries: arrCountries,
        error: null,
      };
    }

    case constants.GET_COUNTRIES_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.GET_SCHEDULE_LIST_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_SCHEDULE_LIST_SUCCESS: {
      const arrSchedule = action.payload.data.map((item: { endTime: string, startTime: string}) => ({
        ...item, endTime: timeFormat(item.endTime), startTime: timeFormat(item.startTime)
      }));
      return {
        ...state,
        scheduleList: arrSchedule,
        isFetching: false,
      };
    }

    case constants.GET_SCHEDULE_LIST_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case constants.UPDATE_START_TIME: {
      const { id, startTime } = action.payload;
      return {
        ...state,
        scheduleList: state.scheduleList.map((item) => (item.id === id ? {
          ...item,
          startTime
        } : item)),
        error: null,
        isFetching: false,
      };
    }

    case constants.UPDATE_END_TIME: {
      const { id, endTime } = action.payload;
      return {
        ...state,
        scheduleList: state.scheduleList.map((item) => (item.id === id ? {
          ...item,
          endTime
        } : item)),
        error: null,
        isFetching: false,
      };
    }

    case constants.CLOSED_DAY: {
      return {
        ...state,
        scheduleList: state.scheduleList.map((item) => (item.id === action.payload ? {
          ...item,
          startTime: null,
          endTime: null,
        } : item)),
        error: null,
        isFetching: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default profileStationReducer;
