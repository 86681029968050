import VerificationPage from './VerificationPage';
import { connect } from 'react-redux';
import { verifyEmailAction } from '../module/actions';
import { errorClear } from '../SignIn/module/actions';

const mapDispatchToProps = {
  verifyEmailAction,
  errorClear,
};

export default connect(null, mapDispatchToProps)(VerificationPage);
