import React from 'react';
import './CreateEmployee.scss';
import classNames from 'classnames';
import { Input } from '../../../commonComponents/Input/Input';

interface IProps {
    onBlurName?: any;
    onChangeName?: (event: { target: { value: string } }) => void;
    defaultValueName?: string;
    onBlurNumber?: any;
    onChangeNumber?: (event: { target: { value: string } }) => void;
    defaultValueNumber?: string;
    errorName: boolean;
    errorPhone?: boolean;
    onSaveEmployee?: () => void;
    onCancel?: () => void;
}

const CreateEmployee: React.FC<IProps> = (props) => {
  const { errorPhone, errorName, onBlurName, onChangeName,
    defaultValueName, onBlurNumber, onChangeNumber, onCancel, onSaveEmployee, defaultValueNumber } = props;
  return (
    <div className="container-employee">
      <span className="title-modal-employee">{ 'Add employee' }</span>
      <div className={ classNames('change-pass-form', { 'error-wrap': errorName }) }>
        <Input
          labelName={ 'Station employee*' }
          error={ errorName }
          name={ 'nameEmployee' }
          placeholder={ 'Enter employees name' }
          type={ 'text' }
          className={ 'input input-employee' }
          onBlur={ onBlurName }
          onChange={ onChangeName }
          defaultValue={ defaultValueName } />
        { errorName ? <span className="error-text">{ 'Field is required' }</span> : null }
      </div>
      <div className={ classNames('change-pass-form', { 'error-wrap': errorPhone }) }>
        <Input
          labelName={ 'Telephone number*' }
          error={ errorPhone }
          placeholder={ 'Enter phone number' }
          type={ 'number' }
          name={ 'phoneNumber' }
          className={ 'input input-employee' }
          onBlur={ onBlurNumber }
          onChange={ onChangeNumber }
          defaultValue={ defaultValueNumber } />
        { errorPhone ? <span className="error-text">{ 'Min 8 characters, max 14' }</span> : null }
      </div>
      <div className="button-wrap-employee">
        <button onClick={ onCancel } className="button button-cancel">{ 'Cancel' }</button>
        <button onClick={ onSaveEmployee } className="button button-remove">{ 'Save' }</button>
      </div>
    </div>
  );
};

export default CreateEmployee;
