export const routesStation = {
  booking: '/station-service/booking',
  requests: '/station-service/booking/requests',
  vehicle: '/station-service/vehicle',
  reminders: '/station-service/reminders',
  employees: '/station-service/employees',
  profile: '/station-service/profile',
};

export const errorMessage = {
  authError: 'Bad Request',
  statusCode: '401',
};

export const googleApiKey = 'AIzaSyCGOMpWdjSt_Y1-aZTkfmbxbhCaUOFToIg';

export const validationPatterns = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  password: /(?=.*[0-9])(?=.*[a-z]){8,30}/g,
};
export const keyCode = { ENTER: 13 };

export const sortingOptions = [
  {
    value: 'asc', label: 'From early to late'
  },
  {
    value: 'desc', label: 'From late to early'
  },
  {
    value: 'employee', label: 'Station employee'
  },
  {
    value: 'start_time', label: 'Upcoming first'
  },
  {
    value: 'is_done', label: 'Done first'
  },
];

export const arrLanguages = [
  {
    value: 'en', label: 'EN'
  },
  {
    value: 'ru', label: 'RU'
  },
  {
    value: 'ua', label: 'UA'
  },
];

export const arrTimeInterval = () => {
  const interval = 15;
  const times = [];
  let startTime = 0;

  for (let i = 0; startTime < 24 * 60; i++) {
    const hh = Math.floor(startTime / 60);
    const mm = (startTime % 60);
    times[i] = `${(`0${hh % 24}`).slice(-2)}:${(`0${mm}`).slice(-2)}`;
    startTime = startTime + interval;
  }
  return times.map((elem) => ({
    value: elem,
    label: elem,
  }));
};
