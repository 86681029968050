import { combineReducers } from 'redux';
import auth from './StationService/SignIn/module/reducer';
import serviceStation from './StationService/module/reducer';
import profileStationReducer from './StationService/ProfileInfoPage/module/reducer';
import employeeReducer from './StationService/EmployeesPage/module/reducer';
import remindersReducer from './StationService/RemindersPage/module/reducer';
import bookingReducer from './StationService/BookingPage/module/reducer';
import galleryReducer from './StationService/ProfileInfoPage/PhotoGallery/module/reducer';
import vehicleReducer from './StationService/VehiclePage/module/reducer';
import { i18nState } from 'redux-i18n';

const reducer = combineReducers({
  auth,
  serviceStation,
  profileStationReducer,
  bookingReducer,
  i18nState,
  galleryReducer,
  employeeReducer,
  remindersReducer,
  vehicleReducer,
});

export default reducer;
