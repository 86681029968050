import React, { Component } from 'react';
import './PhotoGallery.scss';
import { history } from '../../../index';
import { IPhoto } from './module/interfaces';
import ItemPhoto from './ItemPhoto';
import { ModalWindow } from '../../../commonComponents/ModalWindow/ModalWindow';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

interface IGalleryProps {
  getGalleryList: () => void;
  arrPhotoGallery: IPhoto[];
  uploadPhotoAction: (file: any) => void;
}

interface IState {
  isOpenPhoto: boolean;
  indexPhoto: any;
}

class PhotoGallery extends Component<IGalleryProps, IState> {

  constructor(props: IGalleryProps) {
    super(props);
    this.state = {
      isOpenPhoto: false,
      indexPhoto: null,
    };
  }

  componentDidMount() {
    const { getGalleryList } = this.props;
    getGalleryList();
  }

  onOpenPhoto = (index: number) => {
    this.setState({
      isOpenPhoto: true, indexPhoto: index
    });
  }

  addPhoto = (file: any) => {
    const { uploadPhotoAction } = this.props;
    file.forEach(uploadPhotoAction);
  }

  render() {
    const { arrPhotoGallery } = this.props;
    const { isOpenPhoto, indexPhoto } = this.state;
    return (
      <>
        <div className="photo-gallery">
          <div className="photo-gallery-container">
            <div className="navigation-wrap">
              <span onClick={ () => history.push('/station-service/profile') } className="icon icon-btn-back icon-btn-back--gallery" />
              <span className="station-name station-name--gallery">{ this.context.t('photos_title') }</span>
            </div>
            {
              (arrPhotoGallery.length === 15)
                ? null
                : <>
                  <Dropzone onDrop={ this.addPhoto }>
                    { ({ getRootProps, getInputProps }) => (
                      <section>
                        <div style={{ outline: 'none' }} { ...getRootProps() }>
                          <input { ...getInputProps() } />
                          <button className="button button-add-item button-add-item--photo">
                            <span className="icon icon-plus" />
                            <span className="text-btn-price-regular">{ this.context.t('add_photo_btn') }</span>
                          </button>
                        </div>
                      </section>
                    ) }
                  </Dropzone>
                  </>
            }
            <div className="photo-gallery photo-gallery__wrap">
              {
                arrPhotoGallery && arrPhotoGallery.map((item, index) => (
                  <ItemPhoto onOpen={ () => this.onOpenPhoto(index) } id={ item.id } urlPhoto={ item.photoUrl } />))
              }
            </div>
          </div>
          {
            !arrPhotoGallery.length && <span className="empty-state-gallery">{ this.context.t('empty_state_photos') }</span>
          }
        </div>
        <ModalWindow isModalOpen={ isOpenPhoto } modalClassName="modal-gallery">
          <div className="full-photo"
            style={{ backgroundImage: `url("${arrPhotoGallery[indexPhoto] && arrPhotoGallery[indexPhoto].photoUrl}")` }}
          >
            <span onClick={ () => this.setState({ isOpenPhoto: false }) } className="icon icon-close-modal" />
          </div>
        </ModalWindow>
      </>
    );
  };

}

// @ts-ignore
PhotoGallery.contextTypes = { t: PropTypes.func.isRequired };

export default PhotoGallery;
