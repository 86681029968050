import React, { Component } from 'react';
import './RemindersPage.scss';
import { DatePicker } from '../../commonComponents/DatePicker/DatePicker';
import ItemReminder from './ItemReminder';
import ReactPaginate from 'react-paginate';
import { IReminder } from './module/interfaces';
import {
  dateFormat, timeFormatBooking, dateCalendar, dateAction
} from '../../services/utils';
import PropTypes from 'prop-types';

interface IReminders {
  getListReminders: any;
  remindersList: IReminder[];
  totalReminders: number;
  getMyProfileInfo: () => void;
  profileInfo: {
    name: string
  }
}

interface IState {
  isOpenCalendar: boolean;
  currentDate: Date | Date[] | null;
  newDate: Date | Date[] | null;
}

class RemindersPage extends Component<IReminders, IState> {

  constructor(props: IReminders) {
    super(props);
    this.state = {
      isOpenCalendar: false,
      currentDate: new Date(),
      newDate: new Date(),
    };
  }

  componentDidMount() {
    const { getListReminders, getMyProfileInfo } = this.props;
    const { currentDate } = this.state;
    getMyProfileInfo();
    getListReminders({ time: dateAction(currentDate) });
  }

  onSelectDate = () => {
    const { currentDate } = this.state;
    const { getListReminders } = this.props;
    this.setState({
      newDate: currentDate, isOpenCalendar: false
    });
    getListReminders({ date: dateAction(currentDate) });
  };

  handleChangeDate = (date: Date) => {
    this.setState({ currentDate: date });
  };

  onPageChange = (currentPage: any) => {
    const { getListReminders } = this.props;
    let offset = 0;
    if (currentPage !== 0) {
      offset = currentPage.selected * 10;
    }
    getListReminders({ offset });
  }

  render() {
    const { isOpenCalendar, currentDate, newDate } = this.state;
    const { totalReminders, remindersList, profileInfo } = this.props;
    const totalPage = Math.ceil(totalReminders / 10);
    const nextLabel = <div className="arrow arrow_right" />;
    const prevLabel = <div className="arrow arrow_left" />;
    return (
      <>
        <div className="reminders-container">
          <div className="reminders-wrap">
            <span className="station-name station-name--reminders">{ profileInfo.name }</span>
            <div className="button-employee-wrap">
              <button onClick={ () => this.setState({ isOpenCalendar: true }) } className="button btn-calendar">
                <span className="icon icon-calendar" />
                <span className="text-btn">{ dateCalendar(newDate) }</span>
              </button>
              {
                isOpenCalendar ? <DatePicker
                  value={ currentDate }
                  onSaveDate={ this.onSelectDate }
                  onChangeDate = { (date: Date) => this.handleChangeDate(date) }
                  onCancel={ () => this.setState({ isOpenCalendar: false }) } /> : null
              }
              <button className="button button-add-reminder">
                <span className="icon icon-add-reminder" />
                <span className="text-btn-price-regular">{ this.context.t('add_reminder_btn') }</span>
              </button>
            </div>
            <div className="sorting-panel">
              <span className="text-sorting-panel">{ this.context.t('reminders_list_text') }</span>
            </div>
            <div className="employee-list">
              { (totalReminders !== 0)
                ? remindersList && remindersList.map((item) => (
                  <ItemReminder
                    dateReminder={ dateFormat(item.time) }
                    timeReminder={ timeFormatBooking(item.time) }
                    employeeName={ item.employee.name }
                    id={ item.id }
                    descriptionReminder={ item.text }
                    brandCar={ item.vehicle.brand.name }
                    modelCar={ item.vehicle.model.name }
                    vinNumber={ item.vehicle.vin }
                    yearCar={ item.vehicle.manufacturingYear }
                    ownerName={ item.vehicle.ownerName }
                    ownerPhone={ item.vehicle.ownerPhoneNumber }
                    isDone={ item.isDone } />
                ))
                : <div className="empty-state-booking">
                  <span className="empty-state-text">{ this.context.t('empty_state_reminders') }</span>
                  <span className="icon icon-empty-state icon-empty-state--reminders" />
                  </div>
              }
            </div>
          </div>
          {
            (totalReminders !== 0)
              ? <div className="pagination-wrap">
                <ReactPaginate
                  pageCount={ totalPage }
                  onPageChange={ this.onPageChange }
                  containerClassName="pagination"
                  nextLabel={ nextLabel }
                  previousLabel={ prevLabel }
                  marginPagesDisplayed={ 6 }
                  pageRangeDisplayed={ 6 } />
                </div> : null
          }
        </div>
      </>
    );
  };

}
// @ts-ignore
RemindersPage.contextTypes = { t: PropTypes.func.isRequired };

export default RemindersPage;
