export default {
  GET_BOOKING_LIST_REQUEST: 'GET_BOOKING_LIST_REQUEST',
  GET_BOOKING_LIST_SUCCESS: 'GET_BOOKING_LIST_SUCCESS',
  GET_BOOKING_LIST_FAIL: 'GET_BOOKING_LIST_FAIL',

  GET_BOOKING_REQUEST_LIST_REQUEST: 'GET_BOOKING_REQUEST_LIST_REQUEST',
  GET_BOOKING_REQUEST_LIST_SUCCESS: 'GET_BOOKING_REQUEST_LIST_SUCCESS',
  GET_BOOKING_REQUEST_LIST_FAIL: 'GET_BOOKING_REQUEST_LIST_FAIL',

  UPDATE_ITEM_BOOKING_REQUEST: 'UPDATE_ITEM_BOOKING_REQUEST',
  UPDATE_ITEM_BOOKING_SUCCESS: 'UPDATE_ITEM_BOOKING_SUCCESS',
  UPDATE_ITEM_BOOKING_FAIL: 'UPDATE_ITEM_BOOKING_FAIL',

  DELETE_ITEM_BOOKING_REQUEST: 'DELETE_ITEM_BOOKING_REQUEST',
  DELETE_ITEM_BOOKING_SUCCESS: 'DELETE_ITEM_BOOKING_SUCCESS',
  DELETE_ITEM_BOOKING_FAIL: 'DELETE_ITEM_BOOKING_FAIL',

  CREATE_ITEM_BOOKING_REQUEST: 'CREATE_ITEM_BOOKING_REQUEST',
  CREATE_ITEM_BOOKING_SUCCESS: 'CREATE_ITEM_BOOKING_SUCCESS',
  CREATE_ITEM_BOOKING_FAIL: 'CREATE_ITEM_BOOKING_FAIL',

  GET_ITEM_BOOKING_REQUEST: 'GET_ITEM_BOOKING_REQUEST',
  GET_ITEM_BOOKING_SUCCESS: 'GET_ITEM_BOOKING_SUCCESS',
  GET_ITEM_BOOKING_FAIL: 'GET_ITEM_BOOKING_FAIL',

  GET_EMPLOYEES_LIST_REQUEST: 'GET_EMPLOYEES_LIST_REQUEST',
  GET_EMPLOYEES_LIST_SUCCESS: 'GET_EMPLOYEES_LIST_SUCCESS',
  GET_EMPLOYEES_LIST_FAIL: 'GET_EMPLOYEES_LIST_FAIL',

  GET_VEHICLES_LIST_REQUEST: 'GET_VEHICLES_LIST_REQUEST',
  GET_VEHICLES_LIST_SUCCESS: 'GET_VEHICLES_LIST_SUCCESS',
  GET_VEHICLES_LIST_FAIL: 'GET_VEHICLES_LIST_FAIL',

  GET_ITEM_BOOKING_REQUESTS_REQUEST: 'GET_ITEM_BOOKING_REQUESTS_REQUEST',
  GET_ITEM_BOOKING_REQUESTS_SUCCESS: 'GET_ITEM_BOOKING_REQUESTS_SUCCESS',
  GET_ITEM_BOOKING_REQUESTS_FAIL: 'GET_ITEM_BOOKING_REQUESTS_FAIL',

  UPDATE_BOOKING_REQUESTS_REQUEST: 'UPDATE_ITEM_BOOKING_REQUEST',
  UPDATE_BOOKING_REQUESTS_SUCCESS: 'UPDATE_BOOKING_REQUESTS_SUCCESS',
  UPDATE_BOOKING_REQUESTS_FAIL: 'UPDATE_BOOKING_REQUESTS_FAIL',

  DELETE_ITEM_BOOKING_REQUESTS_REQUEST: 'DELETE_ITEM_BOOKING_REQUESTS_REQUEST',
  DELETE_ITEM_BOOKING_REQUESTS_SUCCESS: 'DELETE_ITEM_BOOKING_REQUESTS_SUCCESS',
  DELETE_ITEM_BOOKING_REQUESTS_FAIL: 'DELETE_ITEM_BOOKING_REQUESTS_FAIL',

  GET_BRANDS_LIST_REQUEST: 'GET_BRANDS_LIST_REQUEST',
  GET_BRANDS_LIST_SUCCESS: 'GET_BRANDS_LIST_SUCCESS',
  GET_BRANDS_LIST_FAIL: 'GET_BRANDS_LIST_FAIL',

  GET_MODEL_LIST_REQUEST: 'GET_MODEL_LIST_REQUEST',
  GET_MODEL_LIST_SUCCESS: 'GET_MODEL_LIST_SUCCESS',
  GET_MODEL_LIST_FAIL: 'GET_MODEL_LIST_FAIL',

  CLEAN_MODELS: 'CLEAN_MODELS',
};
