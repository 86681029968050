import constants from './constants';
import HTTP from '../../../../services/api';
import { NotificationManager } from 'react-notifications';

export const getGalleryList = () => (dispatch: (arg: { type: string; payload?: []; }) => void) => {
  dispatch({ type: constants.GET_PHOTOS_LIST_REQUEST });

  return HTTP.get('/api/service_station/gallery')
    .then((res) => {
      dispatch({
        type: constants.GET_PHOTOS_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_PHOTOS_LIST_FAIL,
        payload: err,
      });
    });
};

export const deleteItemPhoto = (id: number) => (dispatch: (arg: { type: string; payload?: any; }) => void) => {
  dispatch({ type: constants.DELETE_ITEM_PHOTO_REQUEST });

  return HTTP.delete(`/api/service_station/gallery/${id}`)
    .then(() => {
      dispatch({
        type: constants.DELETE_ITEM_PHOTO_SUCCESS,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.DELETE_ITEM_PHOTO_FAIL,
        payload: err,
      });
    });
};

export const uploadPhotoAction = (photos: any) => (dispatch: (arg: {
  type: string; payload?: any; }) => void) => {
  dispatch({ type: constants.UPLOAD_PHOTO_REQUEST });
  const formData = new FormData();
  formData.append('photos', photos);

  return HTTP.post('/api/service_station/gallery', formData)
    .then((res) => {
      dispatch({
        type: constants.UPLOAD_PHOTO_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      NotificationManager.error('Image size exceeds 10 mb');
      dispatch({
        type: constants.UPLOAD_PHOTO_FAIL,
        payload: err,
      });
    });
};
