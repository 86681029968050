import constants from './constants';
import { getQuery } from '../../../services/utils';
import HTTP from '../../../services/api';

export const getVehicleList = (query: object) => (dispatch: (arg: {
    type: string, payload?: object }) => void) => {
  dispatch({ type: constants.GET_VEHICLE_LIST_REQUEST });

  const defaultQueryParams = {
    limit: 10,
    offset: 0,
  };
  const queryParams = {
    ...defaultQueryParams,
    ...query,
  };

  return HTTP.get(`/api/service_station/vehicles${getQuery(queryParams)}`)
    .then((res) => {
      dispatch({
        type: constants.GET_VEHICLE_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_VEHICLE_LIST_FAIL,
        payload: err,
      });
    });
};
