import constants from './constants';
import ls from 'local-storage';
import jwtDecode from 'jwt-decode';
import { get } from 'lodash';

const accessToken = ls.get('accessToken');

export const initialState = {
  isFetching: false,
  accessToken: accessToken || '',
  accessDecoded: accessToken ? jwtDecode(accessToken) : null,
  userType: accessToken ? jwtDecode(accessToken).userType : '',
  error: null,
  errorType: '',
  status: null,
  isOpen: true,
  statusRestore: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case constants.SERVICE_STATION_SIGN_IN_REQUEST: {
      return {
        ...state,
        isFetching: true,
        status: null,
      };
    }

    case constants.SERVICE_STATION_SIGN_IN_SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
        userType: jwtDecode(action.payload.data.token.accessToken).userType,
        accessDecoded: jwtDecode(action.payload.data.token.accessToken),
        accessToken: get(action.payload, 'data.token.accessToken', null),
        isFetching: false,
        error: null,
        status: null,
      };
    }

    case constants.SERVICE_STATION_SIGN_IN_FAIL: {
      return {
        ...state,
        error: { ...action.payload },
        isFetching: false,
        status: null,
      };
    }

    case constants.ERROR_CLEAR: {
      return {
        ...state,
        error: null,
      };
    }

    case constants.LOG_OUT_STATION: {
      return {
        ...state,
        accessToken: null,
        accessDecoded: null,
        error: null,
        status: null,
        isOpen: true,
      };
    }

    case constants.EMAIL_STATION_RESTORATION_REQUEST: {
      return {
        ...state,
        isFetching: true,
        statusRestore: false,
        isOpen: true,
      };
    }

    case constants.EMAIL_STATION_RESTORATION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        statusRestore: action.payload.status,
        isOpen: false,
      };
    }

    case constants.EMAIL_STATION_RESTORATION_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        statusRestore: false,
        isOpen: true,
      };
    }

    case constants.PASS_RECOVERY_REQUEST: {
      return {
        ...state,
        isFetching: true,
        status: null,
        isOpen: true,
      };
    }

    case constants.PASS_RECOVERY_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        status: action.payload.status,
        error: null,
        isOpen: true,
        statusRestore: false,
      };
    }

    case constants.PASS_RECOVERY_FAIL: {
      return {
        ...state,
        isFetching: false,
        status: null,
        isOpen: true,
      };
    }

    default:
      return state;
  }
};

export default auth;
