import HTTP from '../../services/api';
import constants from './constants';
import { history } from '../../index';

export const verifyEmailAction = (uid: string, token: string, data: object) => (dispatch: (arg0: {
    type: string; payload?: object; }) => void) => {
  dispatch({ type: constants.VERIFICATION_EMAIL_REQUEST });

  return HTTP.patch(`/api/service_station/activation/${uid}/${token}/`, data)
    .then((res) => {
      history.push('/');
      dispatch({
        type: constants.VERIFICATION_EMAIL_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({ type: constants.VERIFICATION_EMAIL_FAIL });
    });
};
