import React, { Component } from 'react';
import { history } from '../../index';
import queryString from 'query-string';
import './VerificationPage.scss';
import { Input } from '../../commonComponents/Input/Input';
import { find } from 'lodash';
import { validationPatterns, keyCode } from '../../assets/constants';
import { makeFieldValidation } from '../../services/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';

interface IProps {
  errorClear: () => void;
  verifyEmailAction: (uid: any, token: any, data: object) => void;
}

interface IState {
  password: string;
  passwordRepeat: string;
  errorPass: boolean,
  errorConfirmPass: boolean,
  errorPassValid: boolean;
  isPasswordVisible: boolean;
}

class VerificationPage extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      password: '',
      passwordRepeat: '',
      errorPass: false,
      errorConfirmPass: false,
      errorPassValid: false,
      isPasswordVisible: false,
    };
  }

  onFieldChangePass = (event: { target: { value: string } }) => this.setState({ password: event.target.value }, () => {
    this.clearErrorPass();
  });

  onFieldChangeConfirmPass = (event: { target: { value: string } }) => this.setState({ passwordRepeat: event.target.value }, () => {
    this.clearErrorConfirmPass();
  });

  onVerifyEmailSend = () => {
    const { verifyEmailAction } = this.props;
    const { password, passwordRepeat } = this.state;
    const error = {
      password: !!makeFieldValidation(password, validationPatterns.password, 'password is invalid'),
      passwordRepeat: !!makeFieldValidation(passwordRepeat, validationPatterns.password, 'confirm password is invalid'),
    };
    const isFormInvalid = find(error, (item) => item);
    if (isFormInvalid || password !== passwordRepeat) {
      return this.setState({
        errorPass: true, errorConfirmPass: true
      });
    }
    const parsedString = queryString.parse(history.location.search);
    const data = {
      password,
      passwordRepeat,
    };
    verifyEmailAction(parsedString.uid, parsedString.token, data);
  };

  clearErrorPass = () => {
    const { errorPass, errorPassValid } = this.state;
    const { errorClear } = this.props;

    if (errorPass) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorPass: false });
    } else if (errorPassValid) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorPassValid: false });
    }
  };

  clearErrorConfirmPass = () => {
    const { errorConfirmPass } = this.state;
    const { errorClear } = this.props;
    if (errorConfirmPass) {
      errorClear();
      return this.setState({ errorConfirmPass: false });
    }
  };

  onPassKeyUp = (event: { keyCode: number }) => {
    if (event.keyCode === keyCode.ENTER) {
      return this.onVerifyEmailSend();
    }
  };

  handleConfirmPass = (event: { target: { value: string } }) => {
    const { password, passwordRepeat } = this.state;
    const isPasswordRepeat = makeFieldValidation(event.target.value, validationPatterns.password);
    const isPasswordInvalid = password !== passwordRepeat;
    if (isPasswordInvalid || isPasswordRepeat) {
      this.setState({ errorConfirmPass: true });
    } else {
      this.clearErrorConfirmPass();
    }
  };

  handlePass = (event: { target: { value: string } }) => {
    const isPasswordInvalid = makeFieldValidation(event.target.value, validationPatterns.password);
    if (isPasswordInvalid && event.target.value) {
      this.setState({ errorPassValid: true });
    } else if (!event.target.value.length) {
      this.setState({ errorPass: true });
    } else {
      this.clearErrorPass();
    }
  };

  toggleVisibleOfPassword = () => {
    const {
      isPasswordVisible,
    } = this.state;
    return this.setState({ isPasswordVisible: !isPasswordVisible });
  };

  render() {
    const { errorConfirmPass, errorPass, errorPassValid, isPasswordVisible } = this.state;
    return (
      <div className="verification-page-container">
        <div className="verification-page-wrap">
          <span className="icon icon-checked" />
          <span className="verify-text">{ this.context.t('successfully_verified') }</span>
          <span className="verify-sub-text">{ this.context.t('sub_text_verified') }</span>
          <div className={ classNames('change-pass-form', { 'error-wrap': errorPass || errorPassValid }) }>
            <Input
              labelName={ this.context.t('new_password_label') }
              error={ errorPass || errorPassValid }
              placeholder={ this.context.t('placeholder_create_pass') }
              type={ isPasswordVisible ? 'text' : 'password' }
              className={ 'input input-password' }
              onBlur={ (event: { target: { value: string } }) => this.handlePass(event) }
              onChange={ (event: { target: { value: string }}) => this.onFieldChangePass(event) }
              value={ this.state.password }
              isPasswordVisible={ isPasswordVisible }
              onClickToggle={ this.toggleVisibleOfPassword }
              onKeyUp={ this.onPassKeyUp } />
            { errorPass ? <span className="error-text">{ this.context.t('required_filed_text') }</span> : null }
            { errorPassValid ? <span className="error-text">{ this.context.t('error_pass') }</span> : null }
          </div>
          <div className={ classNames('change-pass-form', { 'error-wrap': errorConfirmPass }) }>
            <Input
              labelName={ this.context.t('confirm_password_label') }
              error={ errorConfirmPass }
              placeholder={ this.context.t('placeholder_confirm_pass') }
              type={ isPasswordVisible ? 'text' : 'password' }
              className={ 'input input-password' }
              onBlur={ (event: { target: { value: string } }) => this.handleConfirmPass(event) }
              onChange={ (event: { target: { value: string }}) => this.onFieldChangeConfirmPass(event) }
              value={ this.state.passwordRepeat }
              isPasswordVisible={ isPasswordVisible }
              onClickToggle={ this.toggleVisibleOfPassword }
              onKeyUp={ this.onPassKeyUp } />
            { errorConfirmPass ? <span className="error-text">{ this.context.t('error_confirm_pass') }</span> : null }
          </div>
          <button className="button button-submit-verify" onClick={ this.onVerifyEmailSend }>{ this.context.t('submit_btn') }</button>
        </div>
      </div>

    );
  }

}

// @ts-ignore
VerificationPage.contextTypes = { t: PropTypes.func.isRequired };

export default VerificationPage;
