import ChangePassword from './ChangePassword';
import { connect } from 'react-redux';
import { IProfileStation } from '../module/interfaces';
import { changePassAction } from '../module/actions';
import { errorClear } from '../../SignIn/module/actions';

const mapStateToProps = (state: { profileStationReducer: IProfileStation}) => ({ profileInfo: state.profileStationReducer.profileInfo });

const mapDispatchToProps = {
  changePassAction,
  errorClear
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
