import RemindersPage from './RemindersPage';
import { connect } from 'react-redux';
import { IReminderReducer } from './module/interfaces';
import { getListReminders } from './module/actions';
import { getMyProfileInfo } from '../ProfileInfoPage/module/actions';
import { IProfileStation } from '../ProfileInfoPage/module/interfaces';

const mapStateToProps = (state: { remindersReducer: IReminderReducer, profileStationReducer: IProfileStation, }) => ({
  remindersList: state.remindersReducer.remindersList,
  totalReminders: state.remindersReducer.totalReminders,
  profileInfo: state.profileStationReducer.profileInfo,
});

const mapDispatchToProps = {
  getListReminders,
  getMyProfileInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(RemindersPage);
