import HTTP from '../../../services/api';
import constants from './constants';
import ls from 'local-storage';
import { get } from 'lodash';
import { history } from '../../../index';
import { NotificationManager } from 'react-notifications';
import { setLanguage } from 'redux-i18n';

const setCredentialsToLocalStorage = (res) => {
  const accessToken = get(res, 'data.token.accessToken', null);
  if (accessToken) {
    ls.set('accessToken', accessToken);
  }
};

export const serviceStationSignIn = (account) => (dispatch) => {
  dispatch({ type: constants.SERVICE_STATION_SIGN_IN_REQUEST });

  return HTTP.post('/api/service_station/sign_in/', account)
    .then((res) => {
      setCredentialsToLocalStorage(res);
      ls.set('password', account.password);
      dispatch({
        type: constants.SERVICE_STATION_SIGN_IN_SUCCESS,
        payload: res,
      });
      history.push('/station-service/booking');
    })
    .catch((err) => {
      NotificationManager.error('Wrong credentials. Try again.');
      dispatch({
        type: constants.SERVICE_STATION_SIGN_IN_FAIL,
        payload: err.response,
      });
      return err;
    });
};

export const errorClear = () => (dispatch) => dispatch({ type: constants.ERROR_CLEAR });

export const logOut = () => (dispatch) => {
  ls.remove('accessToken', 'password');
  ls.remove('password');
  dispatch({ type: constants.LOG_OUT_STATION });
  history.push('/');
};

export const sendEmailToPassRecovery = (email) => (dispatch) => {
  dispatch({ type: constants.EMAIL_STATION_RESTORATION_REQUEST });

  return HTTP.post('/api/service_station/password_reset/', email)
    .then((res) => {
      dispatch({
        type: constants.EMAIL_STATION_RESTORATION_SUCCESS,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.EMAIL_STATION_RESTORATION_FAIL,
        payload: err.response,
      });
    });
};

export const passRecoveryRequest = (data) => (dispatch) => {
  dispatch({ type: constants.PASS_RECOVERY_REQUEST });

  return HTTP.post('/api/service_station/password_reset/confirm/', data)
    .then((res) => {
      dispatch({
        type: constants.PASS_RECOVERY_SUCCESS,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.PASS_RECOVERY_FAIL,
        payload: err.response,
      });
    });
};

export const setLocale = (language) => (dispatch) => {
  dispatch(setLanguage(language));
};
