import React, { Component } from 'react';
import './SingleVehicle.scss';
import PropTypes from 'prop-types';

interface IProps{
  match: {
    params:{
      id: number
    }
  }
}

class SingleVehicle extends Component<IProps, {}> {
  //TODO SingleVehicle page will be added in the next merge this screen is needed to check the correct redirect to the page

  render() {
    const { match: { params } } = this.props;
    return (
      <div>
        <span>{ params.id }</span>
      </div>
    );
  };

}
// @ts-ignore
SingleVehicle.contextTypes = { t: PropTypes.func.isRequired };

export default SingleVehicle;
