export default {
  GET_PHOTOS_LIST_REQUEST: 'GET_PHOTOS_LIST_REQUEST',
  GET_PHOTOS_LIST_SUCCESS: 'GET_PHOTOS_LIST_SUCCESS',
  GET_PHOTOS_LIST_FAIL: 'GET_PHOTOS_LIST_FAIL',

  DELETE_ITEM_PHOTO_REQUEST: 'DELETE_ITEM_PHOTO_REQUEST',
  DELETE_ITEM_PHOTO_SUCCESS: 'DELETE_ITEM_PHOTO_SUCCESS',
  DELETE_ITEM_PHOTO_FAIL: 'DELETE_ITEM_PHOTO_FAIL',

  UPLOAD_PHOTO_REQUEST: 'UPLOAD_PHOTO_REQUEST',
  UPLOAD_PHOTO_SUCCESS: 'UPLOAD_PHOTO_SUCCESS',
  UPLOAD_PHOTO_FAIL: 'UPLOAD_PHOTO_FAIL'
};
