export const RU = {
  edit_profile_btn: 'Редактировать',
  sign_in: 'Войти',
  station_added_text: 'СТО добавлена',
  phone_number: 'Номер телефона',
  second_phone_number: 'Дополнительный номер телефона',
  third_phone_number: 'Дополнительный номер телефона',
  e_mail: 'Email-адрес',
  station_verified_text: 'Аккаунт СТО верифицирован',
  country_text: 'Страна',
  city_text: 'Город',
  address_text: 'Адрес',
  price_list_btn: 'Прейскурант',
  change_password: 'Сменить пароль',
  sign_out: 'Выйти',
  photos_title: 'Фото',
  schedule_title: 'Расписание',
  brands_title: 'Бренды',
  view_photos_btn: 'Просмотр',
  view_list_btn: 'Просмотр',
  edit_schedule_btn: 'Редактировать',
  car_text: 'Автомобиль',
  moto_text: 'Мотоцикл',
  save_btn: 'Сохранить',
  empty_state_brands: 'Нажмите на список вверху, чтобы добавить бренды, которые вы обслуживаете. Так пользователи охотнее выберут именно вас.',
  add_brands_text: 'Добавить бренд',
  delete_btn: 'Удалить',
  add_photo_btn: 'Добавить фото',
  empty_state_photos: 'У вас пока нет фото :(',
  day_off_text: 'Выходной',
  start_time: 'Время начала',
  end_time: 'Время окончания',
  monday_text: 'Понедельник',
  tuesday_text: 'Вторник',
  wednesday_text: 'Среда',
  thursday_text: 'Четверг',
  friday_text: 'Пятница',
  saturday_text: 'Суббота',
  sunday_text: 'Воскресенье',
  empty_state_preview_gallery: 'У вас пока нет фото :( \nНажмите "Добавить", чтобы они появились здесь.',
  restore_password: 'Восстановление пароля',
  email_label: 'Email-адрес',
  password_label: 'Пароль',
  text_restore_pass_success: 'На ваш email-адрес была отправлена ссылка. Пожалуйста, проверьте почту.',
  new_password_label: 'Новый пароль',
  confirm_password_label: 'Подтвердить новый пароль',
  submit_btn: 'Отправить',
  account_blocked_text: 'Ваш аккаунт заблокирован администратором',
  contact_text_carvis: 'Чтобы узнать подробности, свяжитесь с нами: info@carvis.app',
  text_success_pass_change: 'Ваш пароль успешно изменен',
  error_pass: 'Должен быть минимум 8 символов, и содержать хотя бы 1 букву, 1 цифру.',
  error_confirm_pass: 'Должен совпадать с паролем',
  required_filed_text: 'Это обязательное поле',
  add_item_btn: 'Добавить позицию',
  search_text: 'Поиск',
  special_offers_text: 'Акции',
  price_text: 'Цена',
  'successfully_verified ': 'Ваш email-адрес успешно верифицирован',
  sub_text_verified: 'Теперь создайте пароль для вашего аккаунта, чтобы получить к нему доступ',
  placeholder_create_pass: 'Введите новый пароль',
  placeholder_confirm_pass: 'Подтвердите новый пароль',
  registered_pass_label: 'Пароль',
  cancel_btn: 'Отмена',
  item_name_label: 'Наименование',
  currency_label: 'Валюта',
  enter_currency_text: 'Выберите валюту',
  adding_item_text: 'Добавить позицию',
  edit_text: 'Редактировать',
  remove_btn: 'Удалить',
  show_item_app_text: 'Отображать в приложении',
  remove_item_title: 'Удалить',
  sub_text_remove_item: 'Вы уверены, что хотите удалить позицию списка?',
  filter_btn: 'Фильтры',
  add_booking_btn: 'Добавить бронь',
  booking_list: 'Список броней',
  requests_text: 'Заявки',
  sort_by_text: 'Сортировать по',
  status_upcoming: 'Предстоящая',
  status_done: 'Выполнено',
  create_service_record: 'Создать запись об обслуживании',
  sorting_early_late: 'От утренних к вечерним',
  sorting_late_early: 'От вечерних к утренним',
  sorting_employee: 'Сотрудник СТО',
  sorting_upcoming_first: 'Сперва предстоящие',
  sorting_done_first: 'Сперва выполненные',
  empty_state_booking: 'Здесь скоро появятся записи',
  data_label: 'Дата',
  station_employee_label: 'Сотрудник СТО',
  booking_text_label: 'Цель брони',
  select_vehicle: 'Выберите транспорт из списка',
  new_vehicle_text: 'Новый транспорт',
  vehicle: 'Транспорт',
  telephone_number_booking: 'Номер телефона',
  vehicle_owner_name: 'Имя владельца',
  placeholder_select_vehicle: 'Выберите транспорт из списка',
  placeholder_station_employees: 'Введите имя сотрудника СТО',
  delete_booking_text: 'Удалить бронь',
  delete_booking_sub_text: 'Вы уверены, что хотите удалить эту бронь?',
  unassigned_text: 'Сотрудник не выбран',
  confirm_booking_request_text: 'Подтвердить заявку',
  assign_station: 'Назначить сотруднику СТО',
  confirm_btn: 'Подтвердить',
  reset_filter_btn: 'Сбросить фильтры',
  vehicle_brand: 'Бренд транспорта',
  vehicle_model: 'Модель транспорта',
  vehicle_owner_telephone_number: 'Номер телефона владельца',
  add_employee_btn: '',
  employees: '',
  empty_state_employee: '',
  success_add_employee: '',
  delete_employee_text: '',
  empty_state_reminders: '',
  reminders_list_text: '',
  add_reminder_btn: '',
  delete_reminder_title: '',
  delete_reminder_text: '',
};
