import React, { Component } from 'react';
import './ItemBooking.scss';
import { history } from '../../../index';
import { ModalWindow } from '../../../commonComponents/ModalWindow/ModalWindow';
import { routesStation } from '../../../assets/constants';
import { ItemBookingRequest } from '../ItemBookingRequest/ItemBookingRequest';
import { dateFormat, timeFormatBooking } from '../../../services/utils';
import { IBookingList } from '../module/interfaces';
import classNames from 'classnames';

interface IProps {
    dateBooking: string;
    intervalTime: string;
    employeeName: string;
    vinNumber: string;
    ownerName: string;
    ownerPhone: string;
    description: string;
    isDone?: boolean;
    brandCar?: string;
    modelCar?: string;
    yearCar: number;
    id: number;
    updateItemBooking: any;
    deleteItemBooking: (id: number) => void;
    getItemBooking: any;
    bookingItem: object;
    getItemBookingRequests: any;
    bookingRequestsItem: IBookingList;
    updateBookingRequests: any;
    getBookingRequestList: any;
    arrEmployees: [];
    getEmployeesList: () => void;
    deleteItemBookingRequests: (id: number) => void;
}

interface IState {
    isOpenMenu: boolean;
    isDoneBooking: boolean;
    isOpenModal: boolean;
    idBooking: number | null;
    isConfirm: boolean;
    idEmployee: null | number;
}

class ItemBooking extends Component<IProps, IState> {

    private wrapperRef: any;

    constructor(props: IProps) {
      super(props);
      this.state = {
        isOpenMenu: false,
        isDoneBooking: false,
        isOpenModal: false,
        idBooking: null,
        isConfirm: false,
        idEmployee: null,
      };
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: { target: any; }) => {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({ isOpenMenu: false });
      }
    };

    setWrapperRef = (node: any) => {
      this.wrapperRef = node;
    };

    selectActionBooking = async (id: number) => {
      const { isDone, updateItemBooking, getItemBookingRequests, getEmployeesList } = this.props;
      if (history.location.pathname === routesStation.booking) {
        if (!isDone) {
          const data = { isDone: true };
          updateItemBooking(id, data);
          this.setState({ isOpenMenu: false });
        } else {
          history.push('/station-service/vehicle');
        }
      } else {
        await getEmployeesList();
        await getItemBookingRequests(id);
        await this.setState({ isConfirm: true });
      }
    };

    handleChangeEmployee = (selectOption: any) => {
      this.setState({ idEmployee: selectOption.value });
    };

    deleteOrConfirmAction = async (id: number) => {
      const { deleteItemBooking, updateBookingRequests, getBookingRequestList, deleteItemBookingRequests } = this.props;
      const { isConfirm, idEmployee } = this.state;
      if (!isConfirm) {
        if (history.location.pathname === routesStation.booking) {
          await deleteItemBooking(id);
          await this.setState({ isOpenModal: false });
        } else {
          await deleteItemBookingRequests(id);
          await this.setState({ isOpenModal: false });
        }
      } else {
        await updateBookingRequests(id, {
          status: 'approved', employee: idEmployee
        });
        await getBookingRequestList();
        await this.setState({ isConfirm: false });
      }
    };

    render() {
      const { isOpenMenu, isOpenModal, isConfirm } = this.state;
      const { dateBooking, intervalTime, employeeName, vinNumber, ownerName, arrEmployees,
        ownerPhone, description, isDone, brandCar, modelCar, yearCar, id, bookingRequestsItem } = this.props;
      return (
        <>
          <div className="item-booking">
            <div className="item-booking-container">
              {
                (history.location.pathname === routesStation.booking)
                  ? <span className={ classNames('status-booking status-booking_pending', { 'status-booking_done': isDone }) }>
                    { isDone ? 'Done' : 'Upcoming' }
                    </span> : null
              }
              <div className="booking-info">
                <div style={{
                  display: 'flex', flexDirection: 'column'
                }}
                >
                  <div className="booking-wrap">
                    <div className="item-field">
                      <span className="icon icon-booking-calendar" />
                      <span className="text-booking">{ dateBooking }</span>
                    </div>
                    <div className="item-field">
                      <span className="icon icon-car-booking" />
                      <span className="text-booking">{ `${vinNumber} ${brandCar} ${modelCar} ${yearCar}` }</span>
                    </div>
                  </div>
                  <div className="booking-wrap">
                    <div className="item-field">
                      <span className="icon icon-clock" />
                      <span className="text-booking">{ intervalTime }</span>
                    </div>
                    <div className="item-field">
                      <span className="icon icon-employee" />
                      <span className="text-booking">{ ownerName }</span>
                    </div>
                  </div>
                  <div className="booking-wrap">
                    <div className="item-field">
                      <span className="icon icon-employee" />
                      {
                        employeeName ? <span className="text-booking">{ employeeName }</span>
                          : <span className="text-booking text-booking--unassigned">{ 'Unassigned' }</span>
                      }
                    </div>
                    <div className="item-field">
                      <span className="icon icon-phone" />
                      <span className="text-booking">{ ownerPhone }</span>
                    </div>
                  </div>
                </div>
                <span className="description-booking">
                  { description }
                </span>
                <div className="menu-wrap">
                  <button className="button button-menu"
                    onClick={ () => this.setState({ isOpenMenu: true }) }
                  >
                    <span className="icon icon-ellipsis" />
                  </button>
                  { isOpenMenu
                && <div ref={ this.setWrapperRef } className="menu">
                  <span onClick={ () => this.selectActionBooking(id) }
                    className="edit-item"
                  >{ (history.location.pathname === routesStation.booking) ? `${isDone ? 'Create service record' : 'Done'}` : 'Confirm' }
                  </span>
                  <span className="edit-item">{ 'Edit' }</span>
                  <span onClick={ () => this.setState({ isOpenModal: true }) } className="delete-item">{ 'Delete' }
                  </span>
                   </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <ModalWindow
            modalClassName={ isConfirm ? 'confirm-request-modal' : 'modal-remove-item' }
            isModalOpen={ isOpenModal || isConfirm }
          >
            <div className="content-modal">
              <span className="text-remove-item">{ isConfirm ? 'Confirm booking request' : 'Delete booking' }</span>
              {
                isConfirm ? <ItemBookingRequest
                  employeeName={ bookingRequestsItem.employee && bookingRequestsItem.employee.name }
                  textBookingRequest={ bookingRequestsItem.text }
                  vinNumber={ bookingRequestsItem.vehicle && bookingRequestsItem.vehicle.vin }
                  ownerName={ bookingRequestsItem.vehicle && bookingRequestsItem.vehicle.ownerName }
                  ownerPhone={ bookingRequestsItem.vehicle && bookingRequestsItem.vehicle.ownerPhoneNumber }
                  brandCar={ bookingRequestsItem.vehicle && bookingRequestsItem.vehicle.brand.name }
                  modelCar={ bookingRequestsItem.vehicle && bookingRequestsItem.vehicle.model.name }
                  yearCar={ bookingRequestsItem.vehicle && bookingRequestsItem.vehicle.manufacturingYear }
                  optionsEmployee={ arrEmployees }
                  onChangeEmployee={ (selectOption: object) => this.handleChangeEmployee(selectOption) }
                  dateBooking={ dateFormat(bookingRequestsItem.createdAt) }
                  intervalTime={ `${timeFormatBooking(bookingRequestsItem.startTime)}-
                  ${timeFormatBooking(bookingRequestsItem.endTime)}` } />
                  : <span className="title-field">{ 'Are you sure you want to delete this booking from booking list?' }</span>
              }
              <div className="button-wrap-sing-out">
                <button onClick={ () => this.setState({
                  isOpenModal: false, isConfirm: false
                }) }
                className="button button-cancel"
                >{ 'Cancel' }
                </button>
                <button onClick={ () => this.deleteOrConfirmAction(id) }
                  className="button button-remove"
                >{ isConfirm ? 'Confirm' : 'Delete' }
                </button>
              </div>
            </div>
          </ModalWindow>
        </>
      );
    }

}

export default ItemBooking;
