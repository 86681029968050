import React, { Component } from 'react';
import './EditSchedule.scss';
import { history } from '../../../index';
import { ISchedule } from '../module/interfaces';
import classNames from 'classnames';
import InfoDay from './InfoDay';
import { arrTimeInterval } from '../../../assets/constants';

interface IEditSchedule {
    getScheduleList: () => void;
    scheduleList: ISchedule[];
    updateItemDay: any;
    updateStartTime: (id: number, startTime: string) => void;
    updateEndTime: (id: number, startTime: string) => void;
    updateScheduleList: (data: ISchedule[]) => void;
}

interface IState {
    selectDay: any;
    indexDay: number;
    arrTime: [];
    newValueEnd: any;
    newValueStart: any;
}

class EditSchedule extends Component<IEditSchedule, IState> {

  constructor(props: IEditSchedule) {
    super(props);
    this.state = {
      selectDay: null,
      indexDay: 0,
      arrTime: [],
      newValueEnd: {},
      newValueStart: {},
    };
  }

  async componentDidMount() {
    await this.props.getScheduleList();
    await this.setState({ selectDay: this.props.scheduleList[0]?.id });
  }

    onSelectDay = (id: number, index: number) => {
      this.setState({
        selectDay: id, indexDay: index
      });
    }

  changeValueStart = async (selectedOption: { label: string, value: string }) => {
    const { updateStartTime } = this.props;
    const { selectDay } = this.state;
    await this.setState({ newValueStart: selectedOption });
    await updateStartTime(selectDay, selectedOption.value);
    await this.setState({ newValueStart: {} });
  }

  changeValueEnd = async (selectedOption: { label: string, value: string }) => {
    const { updateEndTime } = this.props;
    const { selectDay } = this.state;
    await this.setState({ newValueEnd: selectedOption });
    await updateEndTime(selectDay, selectedOption.value);
    await this.setState({ newValueEnd: {} });
  }

  onUpdateSchedule = () => {
    const { updateScheduleList, scheduleList } = this.props;
    updateScheduleList(scheduleList);
  }

  render() {
    const { scheduleList } = this.props;
    const { selectDay, indexDay, newValueStart, newValueEnd } = this.state;
    const startTime = {
      value: newValueStart?.value || scheduleList[indexDay]?.startTime,
      label: newValueStart?.label || scheduleList[indexDay]?.startTime
    };
    const endTime = {
      value: newValueEnd?.value || scheduleList[indexDay]?.endTime,
      label: newValueEnd?.label || scheduleList[indexDay]?.endTime
    };
    return (
      <div className="edit-profile">
        <div className="edit-schedule-container">
          <div className="navigation-wrap">
            <span onClick={ () => history.push('/station-service/profile') } className="icon icon-btn-back icon-btn-back--gallery" />
            <span className="station-name station-name--gallery">{ 'Edit schedule' }</span>
          </div>
          <div className="schedule-wrapper">
            {
               scheduleList?.map((item, index) => (
                 <div onClick={ () => this.onSelectDay(item.id, index) }
                   className={ classNames('item-day', { active: item.id === selectDay }) }
                 >
                   { item.weekday.name }
                 </div>
               )) }
          </div>
          <div className="info-day-container">
            <InfoDay
              optionsStartTime={ arrTimeInterval() }
              optionsEndTime={ arrTimeInterval() }
              valueStart={ startTime }
              id={ selectDay }
              onChangeStart={ (selectedOption: { label: string, value: string }) => this.changeValueStart(selectedOption) }
              onChangeEnd={ (selectedOption: { label: string, value: string }) => this.changeValueEnd(selectedOption) }
              valueEnd={ endTime } />
          </div>
        </div>
        <div className="button-wrap-sing-out">
          <button onClick={ this.onUpdateSchedule } style={{ marginLeft: 0 }} className="button button-save">{ 'Save' }</button>
        </div>
      </div>
    );
  };

}

export default EditSchedule;
