import moment from 'moment';
import queryString from 'query-string';
import { history } from '../index';

export const getQuery = (query: { [key: string]: any; }) => {
  const stringifyedQuery = queryString.stringify(query);
  if (stringifyedQuery) {
    return `?${stringifyedQuery}`;
  }
  return '';
};

export const makeFieldValidation = (value: string, validationPattern: RegExp, message = 'error') => {
  if (!validationPattern.test(value)) {
    return message;
  }
  return null;
};

export const dateFormat = (date: string | Date | undefined) => moment(date).format('DD.MM.YYYY');

export const dateAction = (date: any) => moment(date).format('YYYY-MM-DD');

export const dateCalendar = (date: any) => moment(date).format('DD MMM YYYY');

export const timeFormatBooking = (date: string | Date) => moment(date).format('h:mm');

export const checkClassName = (isOpen: boolean, routePath: string) => {
  if (isOpen) {
    if (history.location.pathname === routePath) {
      return 'title-icon-active';
    }
    return 'title-icon';
  }
  return 'title-icon title-icon--none';
};

export const ucFirst = (str: string, count: number | undefined) => {
  if (!str) {
    return str;
  }
  return str[0].toUpperCase() + str.slice(1, count);
};

export const timeFormat = (time: string | null) => {
  if (!time) {
    return time;
  }
  return time.slice(0, -3);
};

