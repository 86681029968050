import React from 'react';
import Dropzone from 'react-dropzone';
import { Input } from '../../../../commonComponents/Input/Input';
import Select from 'react-select';
import './CreateProduct.scss';

interface IProps {
    image?: string;
    onDrop?: any;
    onChangeName?: any;
    onChangePrice: (event: { target: { value: string } }) => void;
    options: [],
    onCancel: () => void;
    onSave: () => void;
    value?: object;
    valueItemName?: string;
    valueItemPrice?: number;
    onChangeSelect?: any;
}

const CreateProduct: React.FC<IProps> = (props) => {
  const { image, onDrop, onChangeName, onChangePrice,
    options, onCancel, onSave, value, valueItemName, valueItemPrice,
    onChangeSelect } = props;

  return (
    <div className="item-product-wrapper">
      <span className="title-modal-window">{ 'Adding item' }</span>
      <div className="item-wrap">
        <Dropzone onDrop={ onDrop }>
          { ({ getRootProps, getInputProps }) => (
            <section>
              <div style={{ outline: 'none' }} { ...getRootProps() }>
                <input { ...getInputProps() } />
                <div className="container-image"
                  style={{ backgroundImage: `url("${image || '/icons/icon-product-default.svg'}")` }}
                >
                  <button className="button add-img-wrap">
                    <span className="icon icon-plus" />
                    <span className="text-btn-price-regular">{ 'Add photo' }</span>
                  </button>
                </div>
              </div>
            </section>
          ) }
        </Dropzone>
        <div className="field-container">
          <Input
            className="input input-item-name"
            labelName={ 'Item name' }
            maxLength={ 50 }
            type="text"
            defaultValue={ valueItemName }
            onChange={ onChangeName } />
          <Input
            className="input input-item-price"
            labelName={ 'Price' }
            onChange={ onChangePrice }
            type="number"
            maxLength={ 6 }
            defaultValue={ valueItemPrice } />
          <div className="container-select container-select__currency">
            <span className="label-text">{ 'Currency' }</span>
            <Select
              className="custom-select-wrap"
              classNamePrefix="custom-select"
              required
              options={ options }
              onChange={ (event: any) => onChangeSelect(event) }
              value={ value } />
          </div>
        </div>
      </div>
      <div className="button-wrap-modal">
        <button onClick={ onCancel } className="button button-cancel">{ 'Cancel' }</button>
        <button onClick={ onSave } className="button button-remove">{ 'Save' }</button>
      </div>
    </div>
  );
};

export default CreateProduct;
