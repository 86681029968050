import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { history } from '../../../index';
import './ItemVehicle.scss';

interface IProps {
    vinNumber: string;
    ownerName: string;
    brandCar: string;
    modelCar: string;
    yearCar: number;
    ownerPhone: string
    id: number;
}

interface IState {
    isOpenMenu: boolean;
}

class ItemVehicle extends Component<IProps, IState> {

    private wrapperRef: any;

    constructor(props: IProps) {
      super(props);
      this.state = { isOpenMenu: false };
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: { target: any }) => {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({ isOpenMenu: false });
      }
    }

    setWrapperRef = (node: any) => {
      this.wrapperRef = node;
    }

    redirectToVehicle =(id: number) => {
      history.push(`/station-service/vehicle/${id}`);
    }

    render() {
      const { isOpenMenu } = this.state;
      const { vinNumber, brandCar, modelCar, yearCar, ownerName, ownerPhone, id } = this.props;
      return (
        <>
          <div className="item-vehicle">
            <div className="item-field">
              <span className="icon icon-car-booking" />
              <span onClick={ () => this.redirectToVehicle(id) }
                className="text-booking"
              >{ `${vinNumber || ''} ${brandCar} ${modelCar} ${yearCar}` }
              </span>
            </div>
            <div className="item-field">
              <span className="icon icon-employee" />
              {
                ownerName ? <span onClick={ () => this.redirectToVehicle(id) } className="text-booking">{ ownerName }</span>
                  : <span className="text-booking text-booking--unassigned">{ this.context.t('unassigned_text') }</span>
              }
            </div>
            <div className="employee-wrap">
              <div className="item-field">
                <span className="icon icon-phone" />
                {
                  ownerPhone ? <span onClick={ () => this.redirectToVehicle(id) } className="text-booking">{ ownerPhone }</span>
                    : <span className="text-booking text-booking--unassigned">{ this.context.t('unassigned_text') }</span>
                }
              </div>
            </div>
            <div className="menu-wrap menu-wrap--employee">
              <button className="button button-menu"
                onClick={ () => this.setState({ isOpenMenu: true }) }
              >
                <span className="icon icon-ellipsis" />
              </button>
              { isOpenMenu
                && <div ref={ this.setWrapperRef } className="menu">
                  <span className="edit-item">{ this.context.t('edit_text') }</span>
                  <span className="delete-item">{ this.context.t('remove_btn') }</span>
                   </div>
              }
            </div>
          </div>
        </>
      );
    }

}

// @ts-ignore
ItemVehicle.contextTypes = { t: PropTypes.func.isRequired };

export default ItemVehicle;
