import React, { Component } from 'react';
import './Layout.scss';
import { ModalWindow } from '../commonComponents/ModalWindow/ModalWindow';
import SignIn from '../StationService/SignIn';
import { Redirect } from 'react-router';
import { NotificationContainer } from 'react-notifications';

interface ILayout {
    accessToken: string;
    isOpenForm: boolean;
}

interface IState {
    isOpen: boolean;
}

class Layout extends Component<ILayout, IState> {

  constructor(props: Readonly<ILayout>) {
    super(props);
    this.state = { isOpen: false };
  }

  render() {
    const { isOpen } = this.state;
    const { accessToken, isOpenForm } = this.props;
    if (accessToken) {
      return (
        <Redirect
          to={ '/station-service/booking' } />
      );
    }
    return (
      <>
        <div className="container-role">
          <span onClick={ () => this.setState({ isOpen: true }) }>{ 'Sign in CTO' }</span>
          <span>{ 'Sign in super admin' }</span>
        </div>
        <ModalWindow
          modalClassName="modal-sign-in"
          isModalOpen={ isOpen || isOpenForm }
        >
          <SignIn />
        </ModalWindow>
        <NotificationContainer />
      </>
    );
  }

}

export default Layout;
