import React, { Component } from 'react';
import classNames from 'classnames';
import { ModalWindow } from '../../../commonComponents/ModalWindow/ModalWindow';
import { isTablet } from 'react-device-detect';
import PropTypes from 'prop-types';

interface IProps {
    isDone: boolean;
    id: number;
    dateReminder: string;
    timeReminder: string;
    employeeName: string;
    vinNumber: string;
    brandCar: string;
    modelCar: string;
    yearCar: number;
    ownerName: string;
    ownerPhone: string;
    descriptionReminder: string;
    deleteItemReminder: (id: number) => void;
    updateItemReminder: (id: number, data: { isDone: boolean }) => void;
}

interface IState {
    isOpenMenu: boolean;
    isOpenModal: boolean;
}

class ItemReminder extends Component<IProps, IState> {

    private wrapperRef: any;

    constructor(props: IProps) {
      super(props);
      this.state = {
        isOpenMenu: false,
        isOpenModal: false,
      };
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: { target: any }) => {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({ isOpenMenu: false });
      }
    };

    setWrapperRef = (node: any) => {
      this.wrapperRef = node;
    };

    onDeleteReminder = async (id: number) => {
      const { deleteItemReminder } = this.props;
      await deleteItemReminder(id);
      await this.setState({ isOpenModal: false });
    }

    onUpdateStatus = (id: number) => {
      const { updateItemReminder } = this.props;
      updateItemReminder(id, { isDone: true });
    }

    render() {
      const { isOpenMenu, isOpenModal } = this.state;
      const { isDone, id, dateReminder, timeReminder, employeeName, descriptionReminder,
        vinNumber, brandCar, modelCar, yearCar, ownerName, ownerPhone } = this.props;
      return (
        <>
          <div className="item-booking">
            <div className="item-booking-container">
              <span className={ classNames('status-booking status-booking_pending', { 'status-booking_done': isDone }) }>
                { isDone ? this.context.t('status_done') : this.context.t('status_upcoming') }
              </span>
              <div className="booking-info">
                <div className="booking-info__wrap">
                  <div className="booking-wrap">
                    <div className="item-field">
                      <span className="icon icon-booking-calendar" />
                      <span className="text-booking">{ dateReminder }</span>
                    </div>
                    <div className="item-field">
                      <span className="icon icon-car-booking" />
                      <span className="text-booking">{ `${vinNumber} ${brandCar} ${modelCar} ${yearCar}` }</span>
                    </div>
                  </div>
                  <div className="booking-wrap">
                    <div className="item-field">
                      <span className="icon icon-clock" />
                      <span className="text-booking">{ timeReminder }</span>
                    </div>
                    <div className="item-field">
                      <span className="icon icon-employee" />
                      <span className="text-booking">{ ownerName }</span>
                    </div>
                  </div>
                  <div className="booking-wrap">
                    <div className="item-field">
                      <span className="icon icon-employee" />
                      {
                        employeeName ? <span className="text-booking">{ employeeName }</span>
                          : <span className="text-booking text-booking--unassigned">{ this.context.t('unassigned_text') }</span>
                      }
                    </div>
                    <div className="item-field">
                      <span className="icon icon-phone" />
                      <span className="text-booking">{ ownerPhone }</span>
                    </div>
                  </div>
                  { isTablet ? <span className="description-reminders">{ descriptionReminder }</span> : null }
                </div>
                { isTablet ? null : <span className="description-reminders">{ descriptionReminder }</span> }
                <div className="menu-wrap">
                  <button className="button button-menu"
                    onClick={ () => this.setState({ isOpenMenu: true }) }
                  >
                    <span className="icon icon-ellipsis" />
                  </button>
                  { isOpenMenu && <div ref={ this.setWrapperRef } className="menu">
                    { !isDone
                      ? <span onClick={ () => this.onUpdateStatus(id) } className="edit-item">{ this.context.t('status_done') }</span>
                      : null
                    }
                    <span className="edit-item">{ this.context.t('edit_text') }</span>
                    <span onClick={ () => this.setState({ isOpenModal: true }) }
                      className="delete-item">{ this.context.t('delete_btn') }
                    </span>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <ModalWindow
            modalClassName={ 'modal-remove-item' }
            isModalOpen={ isOpenModal }
          >
            <div className="content-modal">
              <span className="text-remove-item">{ this.context.t('delete_reminder_title') }</span>
              <span className="title-field">{ this.context.t('delete_reminder_text') }</span>
              <div className="button-wrap-sing-out">
                <button onClick={ () => this.setState({ isOpenModal: false }) }
                  className="button button-cancel"
                >{ this.context.t('cancel_btn') }
                </button>
                <button onClick={ () => this.onDeleteReminder(id) }
                  className="button button-remove"
                >{ this.context.t('delete_btn') }
                </button>
              </div>
            </div>
          </ModalWindow>
        </>
      );
    }

}

// @ts-ignore
ItemReminder.contextTypes = { t: PropTypes.func.isRequired };

export default ItemReminder;
