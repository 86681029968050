import React, { Component, MouseEvent } from 'react';
import './ItemPhoto.scss';

interface IProps {
    urlPhoto?: string;
    deleteItemPhoto?: any;
    id?: number;
    onOpen?: any;
}

class ItemPhoto extends Component<IProps> {

    deletePhoto = (id: number | undefined, event: MouseEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      const { deleteItemPhoto } = this.props;
      deleteItemPhoto(id);
    }

    render() {
      const { urlPhoto, id, onOpen } = this.props;
      return (
        <div onClick={ onOpen }
          style={{ backgroundImage: `url("${urlPhoto}")` }} className="item-photo"
        >
          <span onClick={ (event) => this.deletePhoto(id, event) } className="icon icon-basket" />
        </div>
      );
    };

}

export default ItemPhoto;
