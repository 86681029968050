import React from 'react';
import './Input.scss';
import classNames from 'classnames';

interface IProps {
  placeholder?: string;
  error?: boolean;
  value?: any;
  onChange?: any;
  name?: string;
  type: string;
  maxLength?: number;
  className?: string;
  labelName?: string;
  onKeyUp?: any;
  refInput?: React.RefObject<HTMLInputElement>;
  defaultValue?: any;
  checked?: boolean;
  onBlur?: any;
  classNameEye?: string;
  onClickToggle?: () => void;
  isPasswordVisible?: boolean;
}

export const Input: React.FC<IProps> = (props) => {
  const { placeholder,
    error,
    value,
    onChange,
    name,
    type,
    onBlur,
    maxLength,
    className,
    labelName,
    onKeyUp,
    refInput,
    defaultValue, checked, isPasswordVisible, onClickToggle } = props;
  return (
    <div className="container-input">
      <label className="label-text">{ labelName }</label>
      <div className="input-wrap">
        <input
          className={ classNames(className, { error }) }
          type={ type }
          ref={ refInput }
          placeholder={ placeholder }
          value={ value }
          name={ name }
          maxLength={ maxLength }
          onChange={ onChange }
          onKeyUp={ onKeyUp }
          checked={ checked }
          required
          onBlur={ onBlur }
          defaultValue={ defaultValue } />
        { ((type === 'password') || isPasswordVisible)
          ? <span onClick={ onClickToggle } className={ classNames(
            { 'icon icon-eye-visible': isPasswordVisible },
            { 'icon icon-eye': !isPasswordVisible }
          ) } /> : null }
      </div>
    </div>
  );
};

