import constants from './constants';
import { IState, IAction } from './interfaces';

export const initialState: IState = {
  isFetching: false,
  error: null,
  totalReminders: 0,
  remindersList: [],
};

const remindersReducer = (state: IState = initialState, action: IAction) => {
  switch (action.type) {
    case constants.GET_LIST_REMINDERS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_LIST_REMINDERS_SUCCESS: {
      return {
        ...state,
        remindersList: action.payload.data,
        totalReminders: action.payload.total,
        isFetching: false,
      };
    }

    case constants.GET_LIST_REMINDERS_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case constants.DELETE_ITEM_REMINDER_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.DELETE_ITEM_REMINDER_SUCCESS: {
      return {
        ...state,
        remindersList: state.remindersList.filter((item) => item.id !== action.payload),
        totalReminders: state.totalReminders - 1,
        isFetching: false,
      };
    }

    case constants.DELETE_ITEM_REMINDER_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.UPDATE_REMINDER_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.UPDATE_REMINDER_SUCCESS: {
      return {
        ...state,
        remindersList: state.remindersList.map((item) => (item.id === action.payload.id ? { ...action.payload.data } : item)),
        isFetching: false,
      };
    }

    case constants.UPDATE_REMINDER_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default remindersReducer;
