import BookingPage from './BookingPage';
import { connect } from 'react-redux';
import {
  getBookingList, createItemBooking, getBookingRequestList,
  getEmployeesList, getBrandCarList, getModelCarList, cleanModels
} from './module/actions';
import { IState } from './module/interfaces';
import { getMyProfileInfo } from '../ProfileInfoPage/module/actions';
import { IProfileStation } from '../ProfileInfoPage/module/interfaces';

const mapStateToProps = (state: { bookingReducer: IState, profileStationReducer: IProfileStation }) => ({
  profileInfo: state.profileStationReducer.profileInfo,
  bookingList: state.bookingReducer.bookingList,
  totalBooking: state.bookingReducer.totalBooking,
  totalBookingRequest: state.bookingReducer.totalBookingRequest,
  arrEmployees: state.bookingReducer.employees,
  arrVehicles: state.bookingReducer.vehicles,
  arrBrands: state.bookingReducer.brands,
  arrModels: state.bookingReducer.models,
  bookingListRequest: state.bookingReducer.bookingListRequest
});

const mapDispatchToProps = {
  getBookingList,
  createItemBooking,
  getBookingRequestList,
  getEmployeesList,
  getBrandCarList,
  getModelCarList,
  cleanModels,
  getMyProfileInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingPage);
