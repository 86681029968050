import SignIn from './SignIn';
import { connect } from 'react-redux';
import {
  serviceStationSignIn, errorClear, sendEmailToPassRecovery
} from './module/actions';
import { IAuth } from './module/interfaces';

const mapStateToProps = (state: { auth: IAuth }) => ({
  error: state.auth.error,
  isFetching: state.auth.isFetching,
  statusRestore: state.auth.statusRestore,
  isOpen: state.auth.isOpen
});

const mapDispatchToProps = {
  serviceStationSignIn,
  errorClear,
  sendEmailToPassRecovery,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
