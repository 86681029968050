export const UA = {
  edit_profile_btn: 'Редагувати',
  sign_in: 'Увійти',
  station_added_text: 'СТО додано',
  phone_number: 'Номер телефону',
  second_phone_number: 'Додатковий номер телефону',
  third_phone_number: 'Додатковий номер телефону',
  e_mail: 'Email-адреса',
  station_verified_text: 'Акаунт СТО верифікований',
  country_text: 'Країна',
  city_text: 'Місто',
  address_text: 'Адреса',
  price_list_btn: 'Прейскурант',
  change_password: 'Змінити пароль',
  sign_out: 'Вийти',
  photos_title: 'Фото',
  schedule_title: 'Розклад',
  brands_title: 'Бренди',
  view_photos_btn: 'Перегляд',
  view_list_btn: 'Перегляд',
  edit_schedule_btn: 'Редагувати',
  car_text: 'Автомобіль',
  moto_text: 'Мотоцикл',
  save_btn: 'Зберегти',
  empty_state_brands: 'Натисніть на список вгорі, щоб додати бренди, які ви обслуговуєте. Так користувачі охочіше виберуть саме вас.',
  add_brands_text: 'Додати бренд',
  delete_btn: 'Видалити',
  add_photo_btn: 'Додати фото',
  empty_state_photos: 'У вас поки що немає фото :(',
  day_off_text: 'Вихідний',
  start_time: 'Час початку',
  end_time: 'Час закінчення',
  monday_text: 'Понеділок',
  tuesday_text: 'Вівторок',
  wednesday_text: 'Середа',
  thursday_text: 'Четвер',
  friday_text: 'П\'ятниця',
  saturday_text: 'Субота',
  sunday_text: 'Неділя',
  empty_state_preview_gallery: 'У вас поки що немає фото :(\nНатисніть "Додати", щоб вони з\'явилися тут.',
  restore_password: 'Відновлення паролю',
  email_label: 'Email-адреса',
  password_label: 'Пароль',
  text_restore_pass_success: 'На вашу email-адресу було відправлено ​​посилання. Будь ласка, перевірте пошту.',
  new_password_label: 'Новий пароль',
  confirm_password_label: 'Підтвердити новий пароль',
  submit_btn: 'Відправити',
  account_blocked_text: 'Ваш аккаунт заблокований адміністратором',
  contact_text_carvis: 'Щоб дізнатися подробиці, зв\'яжіться з нами: info@carvis.app',
  text_success_pass_change: 'Ваш пароль успішно змінений',
  error_pass: 'Повинен бути мінімум 8 символів, і містити хоча б 1 букву, 1 цифру.',
  error_confirm_pass: 'Має співпадати з паролем',
  required_filed_text: 'Це обо\'язкове поле',
  add_item_btn: 'Додати позицію',
  search_text: 'Пошук',
  special_offers_text: 'Акції',
  price_text: 'Ціна',
  'successfully_verified ': 'Ваша email-адреса успішно верифікована',
  sub_text_verified: 'Тепер створіть пароль для вашого акаунту, щоб отримати до нього доступ',
  placeholder_create_pass: 'Введіть новий пароль',
  placeholder_confirm_pass: 'Підтвердіть новий пароль',
  registered_pass_label: 'Пароль',
  cancel_btn: 'Скасувати',
  item_name_label: 'Найменування',
  currency_label: 'Валюта',
  enter_currency_text: 'Виберіть валюту',
  adding_item_text: 'Додати позицію',
  edit_text: 'Редагувати',
  remove_btn: 'Видалити',
  show_item_app_text: 'Відображати в додатку',
  remove_item_title: 'Видалити',
  sub_text_remove_item: 'Ви впевнені, що хочете видалити позицію списку?',
  filter_btn: 'Фільтри',
  add_booking_btn: 'Додати бронь',
  booking_list: 'Список броней',
  requests_text: 'Заяви',
  sort_by_text: 'Сортувати за',
  status_upcoming: 'Майбутня',
  status_done: 'Виконано',
  create_service_record: 'Створити запис про обслуговування',
  sorting_early_late: 'Від ранкових до вечірніх',
  sorting_late_early: 'Від вечірніх до ранкових',
  sorting_employee: 'Співробітник СТО',
  sorting_upcoming_first: 'Спершу майбутні',
  sorting_done_first: 'Спершу виконані',
  empty_state_booking: 'Тут скоро з\'являться записи',
  data_label: 'Дата',
  station_employee_label: 'Співробітник СТО',
  booking_text_label: 'Мета броні',
  select_vehicle: 'Виберіть транспорт зі списку',
  new_vehicle_text: 'Новий транспорт',
  vehicle: 'Транспорт',
  telephone_number_booking: 'Номер телефону',
  vehicle_owner_name: 'Iм\'я власника',
  placeholder_select_vehicle: 'Виберіть транспорт зі списку',
  placeholder_station_employees: 'Введіть ім\'я співробітника СТО',
  delete_booking_text: 'Видалити бронь',
  delete_booking_sub_text: 'Ви впевнені, що хочете видалити цю бронь?',
  unassigned_text: 'Співробітник не вибраний',
  confirm_booking_request_text: 'Підтвердити заявку',
  assign_station: 'Призначити співробітнику СТО',
  confirm_btn: 'Підтвердити',
  reset_filter_btn: 'Скинути фільтри',
  vehicle_brand: 'Бренд транспорту',
  vehicle_model: 'Модель транспорту',
  vehicle_owner_telephone_number: 'Номер телефону власника',
  add_employee_btn: '',
  employees: '',
  empty_state_employee: '',
  success_add_employee: '',
  delete_employee_text: '',
  empty_state_reminders: '',
  reminders_list_text: '',
  add_reminder_btn: '',
};
