import React from 'react';
import './ItemBookingRequest.scss';
import Select from 'react-select';

interface IProps {
  dateBooking: string;
  intervalTime: string;
  employeeName: string;
  vinNumber: string;
  ownerName: string;
  ownerPhone: string;
  brandCar: string;
  modelCar: string;
  yearCar: number;
  textBookingRequest: string;
  optionsEmployee?: [];
  onChangeEmployee?: any;
}

export const ItemBookingRequest : React.FC<IProps> = (props) => {
  const { dateBooking, vinNumber, brandCar, modelCar, textBookingRequest,
    yearCar, ownerPhone, intervalTime, ownerName, employeeName, optionsEmployee, onChangeEmployee } = props;
  return (
    <div className="booking-requests-container">
      <div className="item-booking">
        <div className="item-booking-container">
          <div className="booking-request-info">
            <div style={{
              display: 'flex', flexDirection: 'column'
            }}
            >
              <div className="booking-wrap">
                <div className="item-field">
                  <span className="icon icon-booking-calendar" />
                  <span className="text-booking">{ dateBooking }</span>
                </div>
                <div className="item-field">
                  <span className="icon icon-car-booking" />
                  <span className="text-booking">{ `${vinNumber} ${brandCar} ${modelCar} ${yearCar}` }</span>
                </div>
              </div>
              <div className="booking-wrap">
                <div className="item-field">
                  <span className="icon icon-clock" />
                  <span className="text-booking">{ intervalTime }</span>
                </div>
                <div className="item-field">
                  <span className="icon icon-employee" />
                  <span className="text-booking">{ ownerName }</span>
                </div>
              </div>
              <div className="booking-wrap">
                <div className="item-field">
                  <span className="icon icon-employee" />
                  {
                    employeeName ? <span className="text-booking">{ employeeName }</span>
                      : <span className="text-booking text-booking--unassigned">{ 'Unassigned' }</span>
                  }
                </div>
                <div className="item-field">
                  <span className="icon icon-phone" />
                  <span className="text-booking">{ ownerPhone }</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="text-booking-request">{ textBookingRequest }</span>
      <div className="container-select container-select_booking">
        <span className="label-text">{ 'Assign station employee*' }</span>
        <Select
          className="custom-select-wrap"
          onChange={ onChangeEmployee }
          classNamePrefix="custom-select"
          options={ optionsEmployee }
          required />
      </div>
    </div>
  );
};
