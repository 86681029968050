export default {
  GET_LIST_EMPLOYEES_REQUEST: 'GET_LIST_EMPLOYEES_REQUEST',
  GET_LIST_EMPLOYEES_SUCCESS: 'GET_LIST_EMPLOYEES_SUCCESS',
  GET_LIST_EMPLOYEES_FAIL: 'GET_LIST_EMPLOYEES_FAIL',

  DELETE_ITEM_EMPLOYEE_REQUEST: 'DELETE_ITEM_EMPLOYEE_REQUEST',
  DELETE_ITEM_EMPLOYEE_SUCCESS: 'DELETE_ITEM_EMPLOYEE_SUCCESS',
  DELETE_ITEM_EMPLOYEE_FAIL: 'DELETE_ITEM_EMPLOYEE_FAIL',

  CREATE_EMPLOYEE_REQUEST: 'CREATE_EMPLOYEE_REQUEST',
  CREATE_EMPLOYEE_SUCCESS: 'CREATE_EMPLOYEE_SUCCESS',
  CREATE_EMPLOYEE_FAIL: 'CREATE_EMPLOYEE_FAIL',

  UPDATE_EMPLOYEE_REQUEST: 'UPDATE_EMPLOYEE_REQUEST',
  UPDATE_EMPLOYEE_SUCCESS: 'UPDATE_EMPLOYEE_SUCCESS',
  UPDATE_EMPLOYEE_FAIL: 'UPDATE_EMPLOYEE_FAIL',

  GET_ITEM_EMPLOYEE_REQUEST: 'GET_ITEM_EMPLOYEE_REQUEST',
  GET_ITEM_EMPLOYEE_SUCCESS: 'GET_ITEM_EMPLOYEE_SUCCESS',
  GET_ITEM_EMPLOYEE_FAIL: 'GET_ITEM_EMPLOYEE_FAIL',
};
