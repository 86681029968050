import React, { Component, createRef } from 'react';
import './EmployeesPage.scss';
import ItemViewEmployee from './ItemViewEmployee';
import { ItemEmployee } from './module/interfaces';
import ReactPaginate from 'react-paginate';
import { keyCode } from '../../assets/constants';
import { ModalWindow } from '../../commonComponents/ModalWindow/ModalWindow';
import CreateEmployee from './CreateEmployee';
import PropTypes from 'prop-types';

interface IEmployeeProps {
  getListEmployees: any;
  employeeList: ItemEmployee[];
  totalEmployee: number;
  createEmployee: (data: object) => void;
  errorClear: () => void;
  getMyProfileInfo: () => void;
  profileInfo: {
    name: string
  }
}

interface IState {
  searchValue: string;
  isAddEmployee: boolean;
  phoneNumber: string;
  nameEmployee: string;
  errorName: boolean;
  errorPhone: boolean;
}

class EmployeesPage extends Component<IEmployeeProps, IState> {

  private readonly refSearchEmployee: any;

  constructor(props: IEmployeeProps) {
    super(props);
    this.state = {
      searchValue: '',
      nameEmployee: '',
      phoneNumber: '',
      isAddEmployee: false,
      errorName: false,
      errorPhone: false,
    };
    this.refSearchEmployee = createRef();
  }

  componentDidMount() {
    const { getListEmployees, getMyProfileInfo } = this.props;
    getListEmployees();
    getMyProfileInfo();
  }

  onPageChange = (currentPage: any) => {
    const { getListEmployees } = this.props;
    let offset = 0;
    if (currentPage !== 0) {
      offset = currentPage.selected * 10;
    }
    getListEmployees({ offset });
  }

  handleChangeSearch = (event: { target: { value: string } }) => {
    this.setState({ searchValue: event.target.value });
  }

  submitSearch = () => {
    const { getListEmployees } = this.props;
    const { searchValue } = this.state;
    getListEmployees({ search: searchValue });
  }

  enterClick = (event: { keyCode: number }) => {
    if (event.keyCode === keyCode.ENTER) {
      return this.submitSearch();
    }
  };

  cleanSearch = () => {
    const { getListEmployees } = this.props;
    this.setState({ searchValue: '' });
    this.refSearchEmployee.current.value = '';
    getListEmployees();
  }

  handleChangeField = (name: string, value: string) => {
    this.setState({ [name]: value } as unknown as IState);
  }

  saveEmployee = () => {
    const { nameEmployee, phoneNumber, errorPhone } = this.state;
    const { createEmployee } = this.props;
    const isValid = nameEmployee && !errorPhone;
    const data = {
      name: nameEmployee, phoneNumber
    };
    if (isValid) {
      createEmployee(data);
      this.setState({ isAddEmployee: false });
    }
  }

  handleName = (event: { target: { value: string } }) => {
    if (!event.target.value) {
      this.setState({ errorName: true });
    } else {
      this.clearErrorName();
    }
  };

  clearErrorName = () => {
    const { errorName } = this.state;
    const { errorClear } = this.props;

    if (errorName) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorName: false });
    }
  };

  handlePhone = (event: { target: { value: string } }) => {
    const isValid = !event.target.value || (event.target.value.length < 8) || (event.target.value.length > 14);
    if (isValid) {
      this.setState({ errorPhone: true });
    } else {
      this.clearErrorPhone();
    }
  };

  clearErrorPhone = () => {
    const { errorPhone } = this.state;
    const { errorClear } = this.props;

    if (errorPhone) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorPhone: false });
    }
  };

  render() {
    const { employeeList, totalEmployee, profileInfo } = this.props;
    const { searchValue, isAddEmployee, errorName, errorPhone } = this.state;
    const totalPage = Math.ceil(totalEmployee / 10);
    const nextLabel = <div className="arrow arrow_right" />;
    const prevLabel = <div className="arrow arrow_left" />;
    return (
      <>
        <div className="price-list-container">
          <div className="price-list-wrap">
            <span className="station-name">{ profileInfo.name }</span>
            <div className="button-employee-wrap">
              <button onClick={ () => this.setState({ isAddEmployee: true }) } className="button button-add-employee">
                <span className="icon icon-add-employee" />
                <span className="text-btn-price-regular">{ this.context.t('add_employee_btn') }</span>
              </button>
            </div>
            <div className="sorting-panel">
              <span className="text-sorting-panel">{ this.context.t('employees') }</span>
              <div className="sorting-panel-container">
                <span onClick={ this.submitSearch } className="icon icon-search" />
                <input
                  onChange={ this.handleChangeSearch }
                  onKeyUp={ this.enterClick }
                  placeholder={ this.context.t('search_text') }
                  ref={ this.refSearchEmployee }
                  type="text"
                  className="input input-search-item" />
                {
                  searchValue
                    ? <span onClick={ this.cleanSearch } className="icon icon-close-search" />
                    : null
                }
              </div>
            </div>
            <div className="employee-list">
              { (totalEmployee !== 0)
                ? employeeList && employeeList.map((item) => (
                  <ItemViewEmployee
                    id={ item.id }
                    nameEmployee={ item.name }
                    phoneNumber={ item.phoneNumber } />
                ))
                : <div className="empty-state-booking">
                  <span className="empty-state-text">{ 'You don’t have any employee yet' }</span>
                  <span className="icon icon-empty-state icon-empty-state--employee" />
                  </div>
              }
            </div>
          </div>
          {
            (totalEmployee !== 0)
              ? <div className="pagination-wrap">
                <ReactPaginate
                  pageCount={ totalPage }
                  onPageChange={ this.onPageChange }
                  containerClassName="pagination"
                  nextLabel={ nextLabel }
                  previousLabel={ prevLabel }
                  marginPagesDisplayed={ 6 }
                  pageRangeDisplayed={ 6 } />
                </div> : null
          }
        </div>
        <ModalWindow
          modalClassName="modal-add-employee"
          isModalOpen={ isAddEmployee }
        >
          <CreateEmployee
            onChangeName={ (event: { target: { value: string } }) => this.handleChangeField('nameEmployee', event.target.value) }
            onChangeNumber={ (event: { target: { value: string } }) => this.handleChangeField('phoneNumber', event.target.value) }
            onCancel={ () => this.setState({ isAddEmployee: false }) }
            onBlurName={ (event: { target: { value: string } }) => this.handleName(event) }
            onBlurNumber={ (event: { target: { value: string } }) => this.handlePhone(event) }
            errorName={ errorName }
            errorPhone={ errorPhone }
            onSaveEmployee={ this.saveEmployee } />
        </ModalWindow>
      </>
    );
  }

}

// @ts-ignore
EmployeesPage.contextTypes = { t: PropTypes.func.isRequired };

export default EmployeesPage;

