import React from 'react';
import './DatePicker.scss';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export const DatePicker = (props) => {
  const { value, onChangeDate, onSaveDate, onCancel } = props;
  return (
    <div className="container-calendar">
      <Calendar
        className="custom-calendar"
        onChange={ onChangeDate }
        next2Label={ null }
        prev2Label={ null }
        value={ value } />
      <div className="button-calendar">
        <button onClick={ onCancel } className="button button-cancel-calendar">{ 'Cancel' }</button>
        <button onClick={ onSaveDate } className="button button-save">{ 'Save' }</button>
      </div>
    </div>
  );
};
