import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './SideBar.scss';
import { history } from '../index';
import { routesStation } from '../assets/constants';
import { checkClassName } from '../services/utils';

interface IState {
  isOpenSideBar: boolean;
}

class SideBar extends Component<{}, IState> {

  constructor(props: Readonly<{}>) {
    super(props);
    this.state = { isOpenSideBar: false };
  }
    onOpenSideBar = () => {
      this.setState({ isOpenSideBar: !this.state.isOpenSideBar });
    }

    render() {
      const { isOpenSideBar } = this.state;
      const checkRout = (history.location.pathname === routesStation.booking) ? routesStation.booking : routesStation.requests;
      return (
        <div
          className={ !isOpenSideBar ? 'side-bar-container' : 'side-bar-open' }
        >
          <div className="container-logo">
            <span className="icon icon-logo" />
            <span className={ isOpenSideBar ? 'logo-text' : 'logo-text logo-text--none ' }>{ 'CARVIS' }</span>
          </div>
          <div className="side-bar-icon">
            <div
              className={ history.location.pathname === (checkRout) ? 'icon-wrap-active' : 'icon-wrap' }
            >
              <NavLink
                activeClassName="icon icon-booking-active"
                className="icon icon-booking"
                to="/station-service/booking" />
              <span onClick={ () => history.push(routesStation.booking) }
                className={ checkClassName(isOpenSideBar, checkRout) }
              >{ 'Booking list' }
              </span>
            </div>
            <div
              className={ history.location.pathname === routesStation.vehicle ? 'icon-wrap-active' : 'icon-wrap' }
            >
              <NavLink
                activeClassName="icon icon-car-active"
                className="icon icon-car"
                to="/station-service/vehicle" />
              <span onClick={ () => history.push(routesStation.vehicle) }
                className={ checkClassName(isOpenSideBar, routesStation.vehicle) }
              >{ 'Vehicle list' }
              </span>
            </div>
            <div
              className={ history.location.pathname === routesStation.reminders ? 'icon-wrap-active' : 'icon-wrap' }
            >
              <NavLink
                activeClassName="icon icon-reminder-active"
                className="icon icon-reminder"
                to="/station-service/reminders" />
              <span onClick={ () => history.push(routesStation.reminders) }
                className={ checkClassName(isOpenSideBar, routesStation.reminders) }
              >{ 'Reminders' }
              </span>
            </div>
            <div
              className={ history.location.pathname === routesStation.employees ? 'icon-wrap-active' : 'icon-wrap' }
            >
              <NavLink
                activeClassName="icon icon-employees-active"
                className="icon icon-employees"
                to="/station-service/employees" />
              <span onClick={ () => history.push(routesStation.employees) }
                className={ checkClassName(isOpenSideBar, routesStation.employees) }
              >{ 'Employees' }
              </span>
            </div>
            <div
              className={ history.location.pathname === routesStation.profile ? 'icon-wrap-active' : 'icon-wrap' }
            >
              <NavLink
                activeClassName="icon icon-profile-active"
                className="icon icon-profile"
                to="/station-service/profile" />
              <span onClick={ () => history.push(routesStation.profile) }
                className={ checkClassName(isOpenSideBar, routesStation.profile) }
              >{ 'Profile' }
              </span>
            </div>
          </div>
          <span onClick={ this.onOpenSideBar }
            className={ isOpenSideBar ? 'icon icon-btn-back' : 'icon icon-btn-next' } />
        </div>
      );
    }

}

export default SideBar;
