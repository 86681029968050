import React from 'react';
import 'antd/dist/antd.css';
import './TimePiker.scss';
import { TimePicker } from 'antd';

const { RangePicker } = TimePicker;

export const TimePiker = ({ value, onChangeTime }) => (
  <RangePicker
    format={ 'HH:mm' }
    minuteStep={ 15 }
    value={ value }
    onChange={ onChangeTime } />
);
