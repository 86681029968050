import React, { Component } from 'react';
import './RecoveryPassword.scss';
import { Input } from '../../commonComponents/Input/Input';
import { Redirect } from 'react-router';
import { makeFieldValidation } from '../../services/utils';
import { validationPatterns } from '../../assets/constants';
import { find } from 'lodash';
import queryString from 'query-string';
import { history } from '../../index';
import classNames from 'classnames';
import PropTypes from 'prop-types';

interface IProps {
  errorClear: () => void;
  onChange?: () => void;
  accessToken: string;
  passRecoveryRequest: (data: object) => void;
  status: number;
}

interface IState {
  password: string;
  confirmPassword: string;
  error?: boolean;
  errorPass: boolean;
  errorConfirmPass: boolean;
  isPasswordVisible: boolean;
}

class RecoveryPassword extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      error: false,
      errorPass: false,
      errorConfirmPass: false,
      isPasswordVisible: false,
    };
  }

  onFieldChangePass = (event: { target: { value: string } }) => this.setState({ password: event.target.value }, () => {
    this.clearErrorPass();
  });

  onFieldChangeConfirmPass = (event: { target: { value: string } }) => this.setState({ confirmPassword: event.target.value }, () => {
    this.clearErrorConfirmPass();
  });

  redirectSignIn = () => {
    history.push('/');
  };

  onSubmit = () => {
    const { password, confirmPassword } = this.state;
    const { passRecoveryRequest } = this.props;
    const error = {
      password: !!makeFieldValidation(password, validationPatterns.password, 'password is invalid'),
      passwordRepeat: !!makeFieldValidation(confirmPassword, validationPatterns.password, 'confirm password is invalid'),
    };
    const isFormInvalid = find(error, (item) => item);
    if (isFormInvalid || password !== confirmPassword) {
      return this.setState({
        errorConfirmPass: true, errorPass: true
      });
    }
    const recoveryToken = queryString.parse(history.location.search).token;
    passRecoveryRequest({
      token: recoveryToken, password
    });
  };

 handlePass = (event: { target: { value: string } }) => {
   const isPasswordInvalid = makeFieldValidation(event.target.value, validationPatterns.password);
   if (isPasswordInvalid) {
     this.setState({ errorPass: true });
   } else {
     this.clearErrorPass();
   }
 };

  handleConfirmPass = (event: { target: { value: string } }) => {
    const { password, confirmPassword } = this.state;
    const isPasswordRepeat = makeFieldValidation(event.target.value, validationPatterns.password);
    const isPasswordInvalid = password !== confirmPassword;
    if (isPasswordInvalid || isPasswordRepeat) {
      this.setState({ errorConfirmPass: true });
    } else {
      this.clearErrorConfirmPass();
    }
  };

  clearErrorPass = () => {
    const { errorPass } = this.state;
    const { errorClear } = this.props;

    if (errorPass) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorPass: false });
    }
  };

  clearErrorConfirmPass = () => {
    const { errorConfirmPass } = this.state;
    const { errorClear } = this.props;

    if (errorConfirmPass) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorConfirmPass: false });
    }
  };

  toggleVisibleOfPassword = () => {
    const {
      isPasswordVisible,
    } = this.state;
    return this.setState({ isPasswordVisible: !isPasswordVisible });
  };

  render() {
    const { password, errorPass, isPasswordVisible, errorConfirmPass, confirmPassword } = this.state;
    const { accessToken, status } = this.props;
    if (accessToken) {
      return (
        <Redirect
          to={ '/station-service/booking' } />
      );
    }
    return (
      <div className="restore-password-container">
        <div className="icon-sign-in-background">
          <span className="icon icon-sign-in" />
        </div>
        {
          (!status)
            ? <>
              <span className="sign-in-text">{ this.context.t('change_password') }</span>
              <div className={ classNames('change-pass-form', { 'error-wrap': errorPass }) }>
                <Input
                  labelName={ this.context.t('new_password_label') }
                  error={ errorPass }
                  className="input input-restore"
                  placeholder={ 'Enter your new password' }
                  type={ isPasswordVisible ? 'text' : 'password' }
                  name={ 'password' }
                  onChange={ this.onFieldChangePass }
                  onBlur={ (event: { target: { value: string } }) => this.handlePass(event) }
                  value={ password }
                  onClickToggle={ this.toggleVisibleOfPassword }
                  isPasswordVisible={ isPasswordVisible }
                  maxLength={ 30 } />
                { errorPass ? <span className="error-text">
                  { this.context.t('error_pass') }
                              </span> : null }
              </div>
              <div className={ classNames('change-pass-form', { 'error-wrap': errorConfirmPass }) }>
                <Input
                  labelName={ this.context.t('confirm_password_label') }
                  error={ errorConfirmPass }
                  className="input input-restore"
                  placeholder={ this.context.t('placeholder_confirm_pass') }
                  type={ isPasswordVisible ? 'text' : 'password' }
                  onBlur={ (event: { target: { value: string } }) => this.handleConfirmPass(event) }
                  name={ 'password' }
                  onChange={ this.onFieldChangeConfirmPass }
                  value={ confirmPassword }
                  isPasswordVisible={ isPasswordVisible }
                  onClickToggle={ this.toggleVisibleOfPassword }
                  maxLength={ 30 } />
                 { errorConfirmPass ? <span className="error-text">{ this.context.t('error_confirm_pass') }</span> : null }
              </div>
              <button onClick={ this.onSubmit } className="button button-submit">{ this.context.t('submit_btn') }</button>
              </>
            : <>
              <span className="sign-in-text">{ this.context.t('text_success_pass_change') }</span>
              <button onClick={ this.redirectSignIn } className="button button-submit">{ this.context.t('sign_in') }</button>
              </>
        }
      </div>
    );
  }

}

// @ts-ignore
RecoveryPassword.contextTypes = { t: PropTypes.func.isRequired };

export default RecoveryPassword;
