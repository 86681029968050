import ItemBooking from './ItemBooking';
import { connect } from 'react-redux';
import {
  updateItemBooking, deleteItemBooking, getItemBooking, deleteItemBookingRequests,
  getItemBookingRequests, updateBookingRequests, getBookingRequestList, getEmployeesList
} from '../module/actions';
import { IBooking } from '../module/interfaces';

const mapStateToProps = (state: { bookingReducer: IBooking }) => ({
  bookingItem: state.bookingReducer.bookingItem,
  bookingRequestsItem: state.bookingReducer.bookingRequestsItem,
  arrEmployees: state.bookingReducer.employees,
});

const mapDispatchToProps = {
  updateItemBooking,
  deleteItemBooking,
  getItemBooking,
  getItemBookingRequests,
  updateBookingRequests,
  getBookingRequestList,
  getEmployeesList,
  deleteItemBookingRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemBooking);
