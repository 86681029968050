import EditSchedule from './EditSchedule';
import { IProfileStation } from '../module/interfaces';
import { connect } from 'react-redux';
import {
  getScheduleList, updateStartTime, updateEndTime, updateScheduleList
} from '../module/actions';

const mapStateToProps = (state: { profileStationReducer: IProfileStation }) => ({ scheduleList: state.profileStationReducer.scheduleList });

const mapDispatchToProps = {
  getScheduleList,
  updateStartTime,
  updateEndTime,
  updateScheduleList
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(EditSchedule);
