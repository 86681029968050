import React from 'react';
import SideBar from '../SideBar';
import './StationService.scss';
import StationLayout from './StationLayout';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { history } from '../index';

export const StationService: React.FC = () => {
  if (!window.localStorage.getItem('accessToken')) {
    history.push('/');
  }
  return (
    <section className="wrap-main-container">
      <SideBar />
      <StationLayout />
      <NotificationContainer />
    </section>
  );
};
