import constants from './constants';
import { dateAction, getQuery } from '../../../services/utils';
import HTTP from '../../../services/api';
import NotificationManager from 'react-notifications';

export const getListReminders = (query: object) => (dispatch: (arg: {
    type: string, payload?: object }) => void) => {
  dispatch({ type: constants.GET_LIST_REMINDERS_REQUEST });

  const defaultQueryParams = {
    limit: 10,
    offset: 0,
    date: dateAction(new Date()),
  };
  const queryParams = {
    ...defaultQueryParams,
    ...query,
  };

  return HTTP.get(`/api/service_station/reminders${getQuery(queryParams)}`)
    .then((res) => {
      dispatch({
        type: constants.GET_LIST_REMINDERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_LIST_REMINDERS_FAIL,
        payload: err,
      });
    });
};

export const deleteItemReminder = (id: number) => (dispatch: (arg: { type: string, payload?: number }) => void) => {
  dispatch({ type: constants.DELETE_ITEM_REMINDER_REQUEST });

  return HTTP.delete(`/api/service_station/reminders/${id}`)
    .then(() => {
      dispatch({
        type: constants.DELETE_ITEM_REMINDER_SUCCESS,
        payload: id,
      });
      NotificationManager.success('Reminder have been removed!');
    })
    .catch((err) => {
      dispatch({
        type: constants.DELETE_ITEM_REMINDER_FAIL,
        payload: err,
      });
    });
};

export const updateItemReminder = (id: number, data: {
    isDone: boolean
     }) => (dispatch: (arg: {
    type: string, payload?: object }) => void) => {
  dispatch({ type: constants.UPDATE_REMINDER_REQUEST });

  return HTTP.patch(`/api/service_station/reminders/${id}`, data)
    .then((res) => {
      const { data } = res;
      dispatch({
        type: constants.UPDATE_REMINDER_SUCCESS,
        payload: {
          data, id
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.UPDATE_REMINDER_FAIL,
        payload: err,
      });
    });
};
