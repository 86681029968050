import constants from './constants';
import { getQuery } from '../../../services/utils';
import HTTP from '../../../services/api';
import { NotificationManager } from 'react-notifications';

export const getListEmployees = (query: any, search: import('axios').AxiosRequestConfig | undefined) => (dispatch: (arg: {
    type: string, payload?: any }) => void) => {
  dispatch({ type: constants.GET_LIST_EMPLOYEES_REQUEST });

  const defaultQueryParams = {
    limit: 10,
    offset: 0,
  };
  const queryParams = {
    ...defaultQueryParams,
    ...query,
    ...search,
  };

  return HTTP.get(`/api/service_station/employees${getQuery(queryParams)}`, search)
    .then((res) => {
      dispatch({
        type: constants.GET_LIST_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_LIST_EMPLOYEES_FAIL,
        payload: err,
      });
    });
};

export const deleteItemEmployee = (id: number) => (dispatch: (arg: { type: string, payload?: any }) => void) => {
  dispatch({ type: constants.DELETE_ITEM_EMPLOYEE_REQUEST });

  return HTTP.delete(`/api/service_station/employees/${id}`)
    .then(() => {
      dispatch({
        type: constants.DELETE_ITEM_EMPLOYEE_SUCCESS,
        payload: id,
      });
      NotificationManager.success('Employee have been removed!');
    })
    .catch((err) => {
      dispatch({
        type: constants.DELETE_ITEM_EMPLOYEE_FAIL,
        payload: err,
      });
    });
};

export const createEmployee = (data: object) => (dispatch: (arg: {
  type: string, payload?: object }) => void) => {
  dispatch({ type: constants.CREATE_EMPLOYEE_REQUEST });

  return HTTP.post('/api/service_station/employees', data)
    .then((res) => {
      dispatch({
        type: constants.CREATE_EMPLOYEE_SUCCESS,
        payload: res,
      });
      NotificationManager.success('Employee have been successfully added!');
    })
    .catch((err) => {
      dispatch({
        type: constants.CREATE_EMPLOYEE_FAIL,
        payload: err,
      });
    });
};

export const updateItemEmployee = (id: number, data: {
   name: string, phoneNumber: string }) => (dispatch: (arg: {
  type: string, payload?: object }) => void) => {
  dispatch({ type: constants.UPDATE_EMPLOYEE_REQUEST });

  const formData = new FormData();
  formData.append(data.phoneNumber ? 'phoneNumber' : '', data.phoneNumber);
  formData.append(data.name ? 'name' : '', data.name);

  return HTTP.patch(`/api/service_station/employees/${id}`, formData)
    .then((res) => {
      const { data } = res;
      dispatch({
        type: constants.UPDATE_EMPLOYEE_SUCCESS,
        payload: {
          data, id
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.UPDATE_EMPLOYEE_FAIL,
        payload: err,
      });
    });
};

export const getItemEmployee = (id: number) => (dispatch: (arg: { type: string, payload?: object }) => void) => {
  dispatch({ type: constants.GET_ITEM_EMPLOYEE_REQUEST });

  return HTTP.get(`/api/service_station/employees/${id}`)
    .then((res) => {
      dispatch({
        type: constants.GET_ITEM_EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_ITEM_EMPLOYEE_FAIL,
        payload: err,
      });
    });
};
