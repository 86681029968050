import React, { Component } from 'react';
import './ItemProduct.scss';
import { ModalWindow } from '../../../../commonComponents/ModalWindow/ModalWindow';
import CreateProduct from '../CreateProduct';
import { NotificationManager } from 'react-notifications';
import { IProductItems } from '../../module/interfaces';

interface IState {
  isOpenMenu: boolean;
  isOpenModal: boolean;
  isEditItem: boolean;
  imageUrl: null | string,
  fileImg: any;
  price: string;
  name: string;
  newCurrency: any;
  idItem: null | number;
}

interface IProps {
  deleteItemProduct: (id: number) => void;
  getProductItem: (id: number) => void;
  getCurrenciesList: () => void;
  id: number;
  arrCurrencies: [];
  name: string;
  currency: string;
  price: string;
  urlImage: string;
  productItems: IProductItems;
  updateItemProduct: (id: number | null, data: object) => void;
}

const initialState = {
  isOpenMenu: false,
  isOpenModal: false,
  isEditItem: false,
  imageUrl: null,
  fileImg: '',
  price: '',
  name: '',
  newCurrency: {
    label: '',
    value: null
  },
  idItem: null,
};

class ItemProduct extends Component<IProps, IState> {

  private wrapperRef: any;

  constructor(props: IProps) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

    handleClickOutside = (event: { target: any }) => {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({ isOpenMenu: false });
      }
    }

    setWrapperRef = (node: any) => {
      this.wrapperRef = node;
    }

    removeItemProduct = (id: number) => {
      const { deleteItemProduct } = this.props;
      deleteItemProduct(id);
      this.setState({ isOpenModal: false });
    }

    onOpenItem = async (id: number) => {
      await this.props.getProductItem(id);
      await this.setState({
        isEditItem: true, idItem: id
      });
      this.props.getCurrenciesList();
    }

    handleChangeCurrencies = (selectedOption: object) => {
      this.setState({ newCurrency: selectedOption });
    }

    onLoadImg = (file: string | any[]) => {
      const reader = new FileReader();
      const uploadFile = file[file.length - 1];
      const fileSize = parseInt(((uploadFile.size / 1024) / 1024).toFixed(2));
      if (file && fileSize < 5) {
        reader.onload = (event) => {
          this.setState({
            // @ts-ignore
            imageUrl: event.target.result, fileImg: uploadFile
          });
        };
        reader.readAsDataURL(uploadFile);
      } else {
        NotificationManager.error('Image size exceeds 5 mb');
      }
    }

    updateItem = async () => {
      const { fileImg, price, name, newCurrency, idItem } = this.state;
      const { updateItemProduct } = this.props;
      const data = {
        photo_file: fileImg,
        name,
        price,
        currency: newCurrency && newCurrency.value
      };
      await updateItemProduct(idItem, data);
      await this.setState(initialState);
    }

    render() {
      const { urlImage, price, name, currency, id, productItems, arrCurrencies } = this.props;
      const { isOpenMenu, isOpenModal, isEditItem, newCurrency } = this.state;
      const selectObj = {
        value: (newCurrency && newCurrency.value) || (productItems && productItems.currency && productItems.currency.id),
        label: (newCurrency && newCurrency.label) || (productItems && productItems.currency && productItems.currency.name)
      };
      return (
        <>
          <div className="item-product">
            <div className="item-product-wrap">
              <div style={{ backgroundImage: `url("${urlImage || '/icons/icon-product-default.svg'}")` }}
                className="img-container" />
              <div className="product-info">
                <div className="product-info-wrap">
                  <span className="name-product">{ name }</span>
                  <div className="menu-wrap">
                    <button className="button button-menu"
                      onClick={ () => this.setState({ isOpenMenu: true }) }
                    >
                      <span className="icon icon-ellipsis" />
                    </button>
                    {
                      isOpenMenu
                                        && <div ref={ this.setWrapperRef } className="menu">
                                          <span onClick={ () => this.onOpenItem(id) }
                                            className="edit-item"
                                          >{ 'Edit' }
                                          </span>
                                          <span onClick={ () => this.setState({ isOpenModal: true }) }
                                            className="delete-item"
                                          >{ 'Delete' }
                                          </span>
                                           </div>
                    }
                  </div>
                </div>
                <div className="price-wrap">
                  <span className="price-text">{ 'Price:' }</span>
                  <span className="price-currency">{ `${currency} ${price}` }</span>
                </div>
              </div>
            </div>
          </div>
          <ModalWindow
            modalClassName="modal-remove-item"
            isModalOpen={ isOpenModal }
          >
            <div className="content-modal">
              <span className="text-remove-item">{ 'Remove item' }</span>
              <span className="title-field">{ 'Are you sure you want to remove an item?' }</span>
              <div className="button-wrap-sing-out">
                <button onClick={ () => this.setState({ isOpenModal: false }) }
                  className="button button-cancel"
                >{ 'Cancel' }
                </button>
                <button onClick={ () => this.removeItemProduct(id) }
                  className="button button-remove"
                >{ 'Delete' }
                </button>
              </div>
            </div>
          </ModalWindow>
          <ModalWindow
            modalClassName="modal-add-item"
            isModalOpen={ isEditItem }
          >
            <CreateProduct
              onDrop={ this.onLoadImg }
              options={ arrCurrencies }
              image={ this.state.imageUrl || productItems.photoUrl }
              valueItemName={ productItems.name }
              valueItemPrice={ productItems.price }
              onCancel={ () => this.setState(initialState) }
              onSave={ this.updateItem }
              value={ selectObj }
              onChangeSelect={ (selectedOption: object) => this.handleChangeCurrencies(selectedOption) }
              onChangePrice={ (event) => this.setState({ price: event.target.value }) }
              onChangeName={ (event: { target: { value: string } }) => this.setState({ name: event.target.value }) } />
          </ModalWindow>
        </>
      );
    }

}

export default ItemProduct;
