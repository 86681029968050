import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { StationService } from './StationService/StationService';
import VerificationPage from './StationService/VerificationPage';
import RecoveryPassword from './StationService/RecoveryPassword';
import './assets/styles/general.scss';
import Layout from './Layout';

class App extends PureComponent {

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          component={ Layout } />
        <Route
          exact
          path="/station-service/*"
          component={ StationService } />
        <Route
          exact
          path="/service_station/activation"
          component={ VerificationPage } />
        <Route
          exact
          path="/service_station/password_reset/*"
          component={ RecoveryPassword } />
      </Switch>
    );
  }

}

export default App;
