import constants from './constants';
import { IState, IAction } from './interfaces';

export const initialState: IState = {
  isFetching: false,
  error: null,
  totalEmployee: 0,
  employeeList: [],
  employeeItem: {},
};

const employeeReducer = (state: IState = initialState, action: IAction) => {
  switch (action.type) {
    case constants.GET_LIST_EMPLOYEES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_LIST_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        employeeList: action.payload.data,
        totalEmployee: action.payload.total,
        isFetching: false,
      };
    }

    case constants.GET_LIST_EMPLOYEES_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case constants.DELETE_ITEM_EMPLOYEE_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.DELETE_ITEM_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employeeList: state.employeeList.filter((item) => item.id !== action.payload),
        totalEmployee: state.totalEmployee - 1,
        isFetching: false,
      };
    }

    case constants.DELETE_ITEM_EMPLOYEE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.CREATE_EMPLOYEE_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.CREATE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employeeList: [...state.employeeList, action.payload.data],
        totalEmployee: state.totalEmployee + 1,
        isFetching: false,
      };
    }

    case constants.CREATE_EMPLOYEE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.UPDATE_EMPLOYEE_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.UPDATE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employeeList: state.employeeList.map((item) => (item.id === action.payload.id ? { ...action.payload.data } : item)),
        isFetching: false,
      };
    }

    case constants.UPDATE_EMPLOYEE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.GET_ITEM_EMPLOYEE_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_ITEM_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employeeItem: action.payload,
        isFetching: false,
      };
    }
    case constants.GET_ITEM_EMPLOYEE_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default employeeReducer;
