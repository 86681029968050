import RecoveryPassword from './RecoveryPassword';
import { connect } from 'react-redux';
import { errorClear, passRecoveryRequest } from '../SignIn/module/actions';

const mapStateToProps = (state: { auth: { accessToken: string, status: number }}) => ({
  accessToken: state.auth.accessToken,
  status: state.auth.status
});

const mapDispatchToProps = {
  errorClear, passRecoveryRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPassword);
