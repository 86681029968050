import EmployeesPage from './EmployeesPage';
import { connect } from 'react-redux';
import { getListEmployees, createEmployee } from './module/actions';
import { IEmployee } from './module/interfaces';
import { errorClear } from '../SignIn/module/actions';
import { getMyProfileInfo } from '../ProfileInfoPage/module/actions';
import { IProfileStation } from '../ProfileInfoPage/module/interfaces';

const mapStateToProps = (state: { employeeReducer: IEmployee, profileStationReducer: IProfileStation, }) => ({
  employeeList: state.employeeReducer.employeeList,
  totalEmployee: state.employeeReducer.totalEmployee,
  profileInfo: state.profileStationReducer.profileInfo,
});

const mapDispatchToProps = {
  getListEmployees,
  createEmployee,
  errorClear,
  getMyProfileInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesPage);
