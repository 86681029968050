import React from 'react';
import './SelectBookings.scss';
import { history } from '../../../index';
import { routesStation } from '../../../assets/constants';
import classNames from 'classnames';

interface IProps {
    onOpenBooking?: () => void;
    onOpenBookingRequests?: () => void;
    countRequests?: number;
}

export const SelectBookings: React.FC<IProps> = ({ onOpenBooking, onOpenBookingRequests, countRequests }) => (
  <div className="container-button">
    <button
      onClick={ onOpenBooking }
      className={ classNames('booking', { 'booking--active': history.location.pathname === routesStation.booking }) }
    >
      { 'Booking List' }
    </button>
    <button
      onClick={ onOpenBookingRequests }
      className={ classNames('booking booking-requests', { 'booking--active': history.location.pathname === routesStation.requests }) }
    >
      { 'Requests' }
      <span className={ classNames(
        'count-requests',
        { 'count-requests--not-active': history.location.pathname === routesStation.booking }
      ) }
      >{ countRequests }
      </span>
    </button>
  </div>
);
