import React from 'react';
import ReactModal from 'react-modal';
import './ModalWindow.scss';

ReactModal.setAppElement('#root');

interface IModal {
  isModalOpen: boolean,
  modalClassName: string,
}

export const ModalWindow: React.FC<IModal> = ({ children, isModalOpen, modalClassName }) => (
  <ReactModal
    className={ `modal-container ${modalClassName}` }
    isOpen={ isModalOpen }
  >
    { children }
  </ReactModal>
);

