import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BookingPage from '../BookingPage';
import VehiclePage from '../VehiclePage';
import RemindersPage from '../RemindersPage';
import EmployeesPage from '../EmployeesPage';
import ProfileInfoPage from '../ProfileInfoPage';
import PriceList from '../ProfileInfoPage/PriceList';
import EditProfile from '../ProfileInfoPage/EditProfile';
import PhotoGallery from '../ProfileInfoPage/PhotoGallery';
import ChangePassword from '../ProfileInfoPage/ChangePassword';
import EditSchedule from '../ProfileInfoPage/EditSchedule';
import SingleVehicle from '../VehiclePage/SingleVehiclePage';

const StationLayout: React.FC = () => (
  <Switch>
    <Route
      exact
      path="/station-service/booking"
      component={ BookingPage } />
    <Route
      exact
      path="/station-service/booking/requests"
      component={ BookingPage } />
    <Route
      exact
      path="/station-service/vehicle"
      component={ VehiclePage } />
    <Route
      exact
      path="/station-service/reminders"
      component={ RemindersPage } />
    <Route
      exact
      path="/station-service/employees"
      component={ EmployeesPage } />
    <Route
      exact
      path="/station-service/profile"
      component={ ProfileInfoPage } />
    <Route
      exact
      path="/station-service/profile/edit"
      component={ EditProfile } />
    <Route
      exact
      path="/station-service/profile/gallery"
      component={ PhotoGallery } />
    <Route
      exact
      path="/station-service/profile/price-list"
      component={ PriceList } />
    <Route
      exact
      path="/station-service/profile/change-pass"
      component={ ChangePassword } />
    <Route
      exact
      path="/station-service/profile/schedule"
      component={ EditSchedule } />
    <Route
      exact
      path="/station-service/vehicle/:id"
      component={ SingleVehicle } />
  </Switch>

);

export default StationLayout;
