import ItemViewEmployee from './ItemViewEmployee';
import {
  deleteItemEmployee, getItemEmployee, updateItemEmployee
} from '../module/actions';
import { connect } from 'react-redux';
import { IEmployee } from '../module/interfaces';
import { errorClear } from '../../SignIn/module/actions';

const mapStateToProps = (state: { employeeReducer: IEmployee }) => ({
  employeeItem: state.employeeReducer.employeeItem,
});

const mapDispatchToProps = {
  deleteItemEmployee,
  getItemEmployee,
  updateItemEmployee,
  errorClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemViewEmployee);
