import React, { Component } from 'react';
import './CreateItemBooking.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TimePiker } from '../../../commonComponents/TimePicker/TimePiker';
import Select from 'react-select';
import { Input } from '../../../commonComponents/Input/Input';

interface IProps {
    text?: string;
    valueDateBooking?: any;
    handleChangeDate?: any;
    onChangeTime?: (time: string, timeString: string) => void;
    value?: any;
    optionsEmployee?: [];
    defaultValueText?: string;
    onChangeText?: any;
    onSaveBooking?: () => void;
    getEmployeesList: () => void;
    getVehiclesList: () => void;
    optionsVehicles: [];
    onChangeEmployee?: any;
    onChangeVin?: any;
    onChangeVehicle?: any;
    onChangePhoneNumber?: any;
    onChangeNameOwner?: any;
    onCancel?: () => void;
}

interface IState {
    isChecked: boolean;
}

class CreateItemBooking extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = { isChecked: false };
  }

  componentDidMount() {
    const { getEmployeesList, getVehiclesList } = this.props;
    getEmployeesList();
    getVehiclesList();
  }

    handleOptionChange = () => {
      this.setState({ isChecked: !this.state.isChecked });
    };

    render() {
      const { isChecked } = this.state;
      const { valueDateBooking, onChangeText, handleChangeDate, defaultValueText, value,
        onChangeVin, onChangePhoneNumber, onChangeNameOwner, onCancel,
        onChangeTime, optionsEmployee, onSaveBooking, optionsVehicles, onChangeEmployee, onChangeVehicle } = this.props;
      return (
        <div className="add-item-booking">
          <span className="title-modal-window">{ 'Add booking' }</span>
          <div className="container-data-time">
            <div className="data-wrap">
              <span className="title-input">{ 'Date*' }</span>
              <div className="datepicker">
                <DatePicker
                  selected={ valueDateBooking }
                  placeholderText={ 'Select date' }
                  dateFormat={ 'dd.MM.yyyy' }
                  required
                  value={ valueDateBooking }
                  customInput={ <input className="input input-date-picker" required /> }
                  onChange={ handleChangeDate } />
                <i className="datepicker-icon" />
              </div>
            </div>
            <div className="data-wrap">
              <span className="title-input">{ 'Time*' }</span>
              <TimePiker value={ value } onChangeTime={ onChangeTime } />
            </div>
          </div>
          <div className="container-select container-select_booking">
            <span className="label-text">{ 'Station employee*' }</span>
            <Select
              className="custom-select-wrap"
              onChange={ onChangeEmployee }
              classNamePrefix="custom-select"
              options={ optionsEmployee }
              required />
          </div>
          <div className="booking-text-wrap">
            <span className="title-input">{ 'Booking text*' }</span>
            <textarea value={ defaultValueText }
              defaultValue={ defaultValueText }
              onChange={ onChangeText }
              placeholder={ 'Enter booking text' }
              required
              name="text-booking" className="info-text" />
          </div>
          <div className="radio-button-container">
            <div className="radio">
              <input onChange={ this.handleOptionChange } type="radio" checked={ !isChecked } />
              <span className="title-radio-button">{ 'Select vehicle from the list' }</span>
            </div>
            <div className="radio">
              <input onChange={ this.handleOptionChange } type="radio" checked={ isChecked } />
              <span className="title-radio-button">{ 'New vehicle' }</span>
            </div>
          </div>
          <div className="container-select container-select_booking">
            <span className="label-text">{ 'Vehicle' }</span>
            <Select
              className="custom-select-wrap"
              options={ optionsVehicles }
              onChange={ onChangeVehicle }
              classNamePrefix="custom-select" />
          </div>
          <div className="vehicle-info-container">
            <Input placeholder={ 'Enter VIN' }
              labelName={ 'VIN' }
              onChange={ onChangeVin }
              className="input input-vin-number"
              type="text" />
            <Input placeholder={ 'Enter number' }
              labelName={ 'Telephone number' }
              onChange={ onChangePhoneNumber }
              className="input input-vin-number"
              type="text" />
          </div>
          <Input placeholder={ 'Enter name' }
            className="input input-owner-name"
            type="text"
            onChange={ onChangeNameOwner }
            labelName={ 'Vehicle owner name' } />
          <div className="button-wrap-modal">
            <button onClick={ onCancel } className="button button-cancel">{ 'Cancel' }</button>
            <button onClick={ onSaveBooking } className="button button-save">{ 'Save' }</button>
          </div>
        </div>
      );
    }

};

export default CreateItemBooking;
