import axios from 'axios';
import { store } from '../index';

const HTTP = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const prepareAuth = (token: string) => `Bearer  ${token}`;

HTTP.interceptors.response.use((response) => response, (error) => Promise.reject(error));

HTTP.interceptors.request.use((config) => {
  const app = store.getState().auth;
  const { accessToken } = app;
  const authConfig = config;
  if (accessToken) {
    authConfig.headers.Authorization = prepareAuth(accessToken);
  }
  return config;
});

export default HTTP;
