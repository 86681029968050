import PhotoGallery from './PhotoGallery';
import { connect } from 'react-redux';
import { getGalleryList, uploadPhotoAction } from './module/actions';
import { IGallery } from './module/interfaces';

const mapStateToProps = (state: { galleryReducer: IGallery}) => ({
  arrPhotoGallery: state.galleryReducer.arrPhotoGallery,
});

const mapDispatchToProps = {
  getGalleryList,
  uploadPhotoAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGallery);
