import constants from './constants';
import { IState, IAction } from './interfaces';

export const initialState: IState = {
  isFetching: false,
  error: null,
  arrPhotoGallery: [],
  totalPhotos: null,
};

const galleryReducer = (state: IState = initialState, action: IAction) => {
  switch (action.type) {
    case constants.GET_PHOTOS_LIST_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_PHOTOS_LIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        arrPhotoGallery: action.payload.data,
        totalPhotos: action.payload.total,
        error: null,
      };
    }

    case constants.GET_PHOTOS_LIST_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.DELETE_ITEM_PHOTO_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.DELETE_ITEM_PHOTO_SUCCESS: {
      return {
        ...state,
        arrPhotoGallery: state.arrPhotoGallery.filter((item: {id: number}) => item.id !== action.payload),
        isFetching: false,
      };
    }

    case constants.DELETE_ITEM_PHOTO_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.UPLOAD_PHOTO_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.UPLOAD_PHOTO_SUCCESS: {
      return {
        ...state,
        arrPhotoGallery: [...state.arrPhotoGallery, ...action.payload.data],
        isFetching: false,
      };
    }

    case constants.UPLOAD_PHOTO_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default galleryReducer;
