import constants from './constants';
import { IVehicleReducer, IAction } from './interfaces';

export const initialState: IVehicleReducer = {
  isFetching: false,
  error: null,
  totalVehicle: 0,
  vehicleList: [],
};

const vehicleReducer = (state: IVehicleReducer = initialState, action: IAction) => {
  switch (action.type) {
    case constants.GET_VEHICLE_LIST_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_VEHICLE_LIST_SUCCESS: {
      return {
        ...state,
        vehicleList: action.payload.data,
        totalVehicle: action.payload.total,
        isFetching: false,
      };
    }

    case constants.GET_VEHICLE_LIST_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default vehicleReducer;
