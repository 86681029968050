import HTTP from '../../../services/api';
import constants from './constants';
import { getQuery, dateAction } from '../../../services/utils';

export const getBookingList = (query: object) => (dispatch: (arg: {
    type: string; payload?: object }) => void) => {
  dispatch({ type: constants.GET_BOOKING_LIST_REQUEST });

  const defaultQueryParams = {
    limit: 5,
    offset: 0,
    employee: undefined,
    vin: undefined,
    ownerName: undefined,
    ownerPhoneNumber: undefined,
    order: undefined,
    orderBy: undefined,
    date: dateAction(new Date()),
  };
  const queryParams = {
    ...defaultQueryParams,
    ...query,
  };

  return HTTP.get(`/api/service_station/bookings${getQuery(queryParams)}`)
    .then((res) => {
      dispatch({
        type: constants.GET_BOOKING_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_BOOKING_LIST_FAIL,
        payload: err,
      });
    });
};

export const updateItemBooking = (id: number, data: { isDone: boolean }) => (dispatch: (arg: {
  type: string; payload?: object }) => void) => {
  dispatch({ type: constants.UPDATE_ITEM_BOOKING_REQUEST });

  return HTTP.patch(`/api/service_station/bookings/${id}`, data)
    .then((res) => {
      const { data } = res;
      dispatch({
        type: constants.UPDATE_ITEM_BOOKING_SUCCESS,
        payload: {
          data, id
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.UPDATE_ITEM_BOOKING_FAIL,
        payload: err,
      });
    });
};

export const deleteItemBooking = (id: number) => (dispatch: (arg: { type: string; payload?: number }) => void) => {
  dispatch({ type: constants.DELETE_ITEM_BOOKING_REQUEST });

  return HTTP.delete(`/api/service_station/bookings/${id}`)
    .then(() => {
      dispatch({
        type: constants.DELETE_ITEM_BOOKING_SUCCESS,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.DELETE_ITEM_BOOKING_FAIL,
        payload: err,
      });
    });
};

export const createItemBooking = (data: { text: string,
  employee: number, startTime: Date, endTime: Date, createdAt: Date }) => (dispatch: (arg: {
  type: string; payload?: object; }) => void) => {
  dispatch({ type: constants.CREATE_ITEM_BOOKING_REQUEST });

  return HTTP.post('/api/service_station/bookings', data)
    .then((res) => {
      dispatch({
        type: constants.CREATE_ITEM_BOOKING_SUCCESS,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.CREATE_ITEM_BOOKING_FAIL,
        payload: err,
      });
    });
};

export const getItemBooking = (id: number) => (dispatch: (arg: { type: string; payload?: object }) => void) => {
  dispatch({ type: constants.GET_ITEM_BOOKING_REQUEST });

  return HTTP.get(`/api/service_station/bookings/${id}`)
    .then((res) => {
      dispatch({
        type: constants.GET_ITEM_BOOKING_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_ITEM_BOOKING_FAIL,
        payload: err,
      });
    });
};

export const getEmployeesList = () => (dispatch: (arg: { type: string; payload?: any; }) => void) => {
  dispatch({ type: constants.GET_EMPLOYEES_LIST_REQUEST });

  return HTTP.get('/api/service_station/employees')
    .then((res) => {
      dispatch({
        type: constants.GET_EMPLOYEES_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_EMPLOYEES_LIST_FAIL,
        payload: err,
      });
    });
};

export const getVehiclesList = () => (dispatch: (arg: { type: string; payload?: any; }) => void) => {
  dispatch({ type: constants.GET_VEHICLES_LIST_REQUEST });

  return HTTP.get('/api/service_station/vehicles')
    .then((res) => {
      dispatch({
        type: constants.GET_VEHICLES_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_VEHICLES_LIST_FAIL,
        payload: err,
      });
    });
};

export const getBookingRequestList = (query: object) => (dispatch: (arg: {
  type: string; payload?: object }) => void) => {
  dispatch({ type: constants.GET_BOOKING_REQUEST_LIST_REQUEST });

  const defaultQueryParams = {
    limit: 5,
    offset: 0,
    employee: undefined,
    vin: undefined,
    ownerName: undefined,
    ownerPhoneNumber: undefined,
    order: undefined,
    orderBy: undefined,
    date: undefined,
  };
  const queryParams = {
    ...defaultQueryParams,
    ...query,
  };

  return HTTP.get(`/api/service_station/booking_requests${getQuery(queryParams)}`)
    .then((res) => {
      dispatch({
        type: constants.GET_BOOKING_REQUEST_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_BOOKING_REQUEST_LIST_FAIL,
        payload: err,
      });
    });
};

export const getItemBookingRequests = (id: number) => (dispatch: (arg: { type: string, payload?: object }) => void) => {
  dispatch({ type: constants.GET_ITEM_BOOKING_REQUESTS_REQUEST });

  return HTTP.get(`/api/service_station/booking_requests/${id}`)
    .then((res) => {
      dispatch({
        type: constants.GET_ITEM_BOOKING_REQUESTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_ITEM_BOOKING_REQUESTS_FAIL,
        payload: err,
      });
    });
};

export const updateBookingRequests = (id: number, data: { status: string, employee: number }) => (dispatch: (arg: {
  type: string; payload?: object }) => void) => {
  dispatch({ type: constants.UPDATE_BOOKING_REQUESTS_REQUEST });

  return HTTP.patch(`/api/service_station/booking_requests/${id}`, data)
    .then((res) => {
      const { data } = res;
      dispatch({
        type: constants.UPDATE_BOOKING_REQUESTS_SUCCESS,
        payload: {
          data, id
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.UPDATE_BOOKING_REQUESTS_FAIL,
        payload: err,
      });
    });
};

export const deleteItemBookingRequests = (id: number) => (dispatch: (arg: { type: string; payload?: number }) => void) => {
  dispatch({ type: constants.DELETE_ITEM_BOOKING_REQUESTS_REQUEST });

  return HTTP.delete(`/api/service_station/booking_requests/${id}`)
    .then(() => {
      dispatch({
        type: constants.DELETE_ITEM_BOOKING_REQUESTS_SUCCESS,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.DELETE_ITEM_BOOKING_REQUESTS_FAIL,
        payload: err,
      });
    });
};

export const getBrandCarList = () => (dispatch: (arg: { type: string, payload?: [] }) => void) => {
  dispatch({ type: constants.GET_BRANDS_LIST_REQUEST });

  return HTTP.get('/api/service_station/car/brands')
    .then((res) => {
      dispatch({
        type: constants.GET_BRANDS_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_BRANDS_LIST_FAIL,
        payload: err,
      });
    });
};

export const getModelCarList = (idBrand: number) => (dispatch: (arg: { type: string, payload?: [] }) => void) => {
  dispatch({ type: constants.GET_MODEL_LIST_REQUEST });

  return HTTP.get(`/api/service_station/car/brands/${idBrand}/models`)
    .then((res) => {
      dispatch({
        type: constants.GET_MODEL_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_MODEL_LIST_FAIL,
        payload: err,
      });
    });
};

export const cleanModels = () => (dispatch: (arg: { type: string }) => void) => {
  dispatch({ type: constants.CLEAN_MODELS });
};
