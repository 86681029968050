import PriceList from './PriceList';
import { connect } from 'react-redux';
import {
  getMyProfileInfo, getSearchProducts, getCurrenciesList, createItemProduct
} from '../module/actions';
import { IProfileStation } from '../module/interfaces';

const mapStateToProps = (state: { profileStationReducer: IProfileStation}) => ({
  profileInfo: state.profileStationReducer.profileInfo,
  searchProductList: state.profileStationReducer.searchProductList,
  arrCurrencies: state.profileStationReducer.currencies,
  total: state.profileStationReducer.total,
});

const mapDispatchToProps = {
  getMyProfileInfo,
  getSearchProducts,
  getCurrenciesList,
  createItemProduct,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(PriceList);
