import constants from './constants';
import { IState, IAction } from './interfaces';

export const initialState: IState = {
  isFetching: false,
  error: null,
  bookingList: [],
  totalBooking: 0,
  bookingItem: {},
  employees: [],
  vehicles: [],
  bookingListRequest: [],
  totalBookingRequest: 0,
  bookingRequestsItem: {},
  brands: [],
  models: [],
};

const bookingReducer = (state: IState = initialState, action: IAction) => {
  switch (action.type) {
    case constants.GET_BOOKING_LIST_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_BOOKING_LIST_SUCCESS: {
      return {
        ...state,
        bookingList: action.payload.data,
        totalBooking: action.payload.total,
        isFetching: false,
        error: null,
      };
    }

    case constants.GET_BOOKING_LIST_FAIL: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case constants.UPDATE_ITEM_BOOKING_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.UPDATE_ITEM_BOOKING_SUCCESS: {
      return {
        ...state,
        bookingList: state.bookingList.map((item:{id: number}) => (item.id === action.payload.id ? { ...action.payload.data } : item)),
        isFetching: false,
      };
    }

    case constants.UPDATE_ITEM_BOOKING_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.DELETE_ITEM_BOOKING_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.DELETE_ITEM_BOOKING_SUCCESS: {
      return {
        ...state,
        bookingList: state.bookingList.filter((item: {id: number}) => item.id !== action.payload),
        isFetching: false,
      };
    }

    case constants.DELETE_ITEM_BOOKING_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.CREATE_ITEM_BOOKING_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.CREATE_ITEM_BOOKING_SUCCESS: {
      return {
        ...state,
        bookingList: [...state.bookingList, action.payload.data],
        isFetching: false,
      };
    }

    case constants.CREATE_ITEM_BOOKING_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.GET_ITEM_BOOKING_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_ITEM_BOOKING_SUCCESS: {
      return {
        ...state,
        bookingItem: action.payload,
        isFetching: false,
      };
    }
    case constants.GET_ITEM_BOOKING_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case constants.GET_EMPLOYEES_LIST_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_EMPLOYEES_LIST_SUCCESS: {
      const arrEmployees = action.payload.data.map((item: { id: number, name: string }) => ({
        value: item.id,
        label: item.name
      }));
      return {
        ...state,
        isFetching: false,
        employees: arrEmployees,
        error: null,
      };
    }

    case constants.GET_EMPLOYEES_LIST_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.GET_VEHICLES_LIST_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_VEHICLES_LIST_SUCCESS: {
      const arrVehicles = action.payload.data.map((item: {
        id: number, brand: { name: string }, model: { name: string }, manufacturingYear: number }) => ({
        value: item.id,
        label: `${item.brand.name} ${item.model.name} ${item.manufacturingYear}`
      }));
      return {
        ...state,
        isFetching: false,
        vehicles: arrVehicles,
        error: null,
      };
    }

    case constants.GET_VEHICLES_LIST_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.GET_BOOKING_REQUEST_LIST_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_BOOKING_REQUEST_LIST_SUCCESS: {
      return {
        ...state,
        bookingListRequest: action.payload.data,
        totalBookingRequest: action.payload.total,
        isFetching: false,
        error: null,
      };
    }

    case constants.GET_BOOKING_REQUEST_LIST_FAIL: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case constants.GET_ITEM_BOOKING_REQUESTS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_ITEM_BOOKING_REQUESTS_SUCCESS: {
      return {
        ...state,
        bookingRequestsItem: action.payload,
        isFetching: false,
      };
    }
    case constants.GET_ITEM_BOOKING_REQUESTS_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case constants.DELETE_ITEM_BOOKING_REQUESTS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.DELETE_ITEM_BOOKING_REQUESTS_SUCCESS: {
      return {
        ...state,
        bookingListRequest: state.bookingListRequest.filter((item: {id: number}) => item.id !== action.payload),
        isFetching: false,
      };
    }

    case constants.DELETE_ITEM_BOOKING_REQUESTS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.GET_BRANDS_LIST_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_BRANDS_LIST_SUCCESS: {
      const arrBrands = action.payload.data.map((item: { id: number, name: string }) => ({
        value: item.id,
        label: item.name
      }));
      return {
        ...state,
        isFetching: false,
        brands: arrBrands,
        error: null,
      };
    }

    case constants.GET_BRANDS_LIST_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.GET_MODEL_LIST_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case constants.GET_MODEL_LIST_SUCCESS: {
      const arrModel = action.payload.data.map((item: { id: number, name: string }) => ({
        value: item.id,
        label: item.name
      }));
      return {
        ...state,
        isFetching: false,
        models: arrModel,
        error: null,
      };
    }

    case constants.GET_MODEL_LIST_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }

    case constants.CLEAN_MODELS: {
      return {
        ...state,
        models: [],
        isFetching: false,
      };
    }

    default:
      return state;
  }
};

export default bookingReducer;
