import React, { Component } from 'react';
import Select from 'react-select';
import Switch from 'react-switch';
import './InfoDay.scss';
// @ts-ignore
import variables from '../../../../assets/styles/variables.scss';

interface IDay {
    optionsStartTime: any;
    optionsEndTime: any;
    valueStart: {
        value: string,
        label: string
    };
    valueEnd: {
        value: string,
        label: string
    };
    onChangeStart?: any;
    onChangeEnd?: any;
    id: number;
    closedDay: (id: number) => void;
}

class InfoDay extends Component<IDay, {}> {

    handleChecked = async (id: number) => {
      const { closedDay } = this.props;
      closedDay(id);
    };

    render() {
      const { optionsStartTime, optionsEndTime, valueStart, valueEnd, onChangeStart, onChangeEnd, id } = this.props;
      const isCheck = (!(valueStart.value && valueEnd.value));
      return (
        <div className="info-day">
          <div className="container-select container-select__time">
            <span className="label-text">{ 'Start time' }</span>
            <Select
              className="custom-select-wrap"
              options={ optionsStartTime }
              value={ valueStart }
              onChange={ (event: any) => onChangeStart(event) }
              classNamePrefix="custom-select" />
          </div>
          <div className="container-select container-select__time">
            <span className="label-text">{ 'End time' }</span>
            <Select
              className="custom-select-wrap"
              options={ optionsEndTime }
              onChange={ (event: any) => onChangeEnd(event) }
              value={ valueEnd }
              classNamePrefix="custom-select" />
          </div>
          <span className="day-off-text">{ 'Day off' }</span>
          <Switch
            onChange={ () => this.handleChecked(id) }
            checked={ isCheck }
            onColor={ variables.iconActiveBackground }
            offColor={ variables.mainColor }
            checkedIcon={ false }
            uncheckedIcon={ false }
            handleDiameter={ 20 }
            boxShadow={ variables.shadowSwitch }
            height={ 14 }
            width={ 34 } />
        </div>
      );
    };

}

export default InfoDay;
