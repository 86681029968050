import ItemProduct from './ItemProduct';
import { connect } from 'react-redux';
import {
  deleteItemProduct, getProductItem, getCurrenciesList, updateItemProduct
} from '../../module/actions';
import { IProduct } from '../../module/interfaces';

const mapStateToProps = (state: { profileStationReducer: IProduct}) => ({
  productItems: state.profileStationReducer.productItems,
  arrCurrencies: state.profileStationReducer.currencies,
});

const mapDispatchToProps = {
  deleteItemProduct,
  getProductItem,
  getCurrenciesList,
  updateItemProduct,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ItemProduct);

