import React, { Component } from 'react';
import './EditProfile.scss';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Input } from '../../../commonComponents/Input/Input';
import { history } from '../../../index';
import GoogleMapReact from 'google-map-react';
import { CurrentPin } from '../CurrentPin';
import Geocode from 'react-geocode';
import { googleApiKey } from '../../../assets/constants';

Geocode.setApiKey(googleApiKey);

interface IEditProfile {
    updateProfileInfo?: (data: object) => void;
    getMyProfileInfo?: () => void;
    profileInfo: {
        name: string,
        phoneNumber: string,
        secondPhoneNumber: string,
        thirdPhoneNumber: string,
        location: {
            latitude: number,
            longitude: number,
            address: string,
            countryModel: string,
            cityModel: string,
        }
    };
    language: string;
}

interface IState {
    phoneNumber: string;
    phoneNumberSecond: string;
    phoneNumberThird: string;
    stationName: string;
    city: string;
    country: string;
    address: string;
    latitude: null | number,
    longitude: null | number,
}

class EditProfile extends Component<IEditProfile, IState> {

  constructor(props: IEditProfile) {
    super(props);
    this.state = {
      phoneNumber: '',
      phoneNumberSecond: '',
      phoneNumberThird: '',
      stationName: '',
      city: '',
      country: '',
      address: '',
      latitude: null,
      longitude: null,
    };
  }

  componentDidMount(): void {
    const { getMyProfileInfo } = this.props;
    if (getMyProfileInfo) {
      getMyProfileInfo();
    }
  }

    getCoordinate = (value: any) => {
      const { language } = this.props;
      this.setState({
        latitude: value.lat, longitude: value.lng
      });
      Geocode.setLanguage(language);
      Geocode.fromLatLng(value.lat, value.lng).then((response) => {
        const address = response.results;
        this.setState({
          city: (address.length > 6 && address[address.length - 5].formatted_address.split(',')[0]) || 'undefined city',
          country: address[address.length - 1].formatted_address,
          address: (address.length > 6)
            ? `${address[0].formatted_address.split(',')[0]}${address[0].formatted_address.split(',')[1]}` : 'undefined'
        });
      });
    };

    onSaveInfoProfile = () => {
      const { updateProfileInfo, profileInfo } = this.props;
      const { phoneNumber, phoneNumberThird, latitude, longitude, phoneNumberSecond, stationName, address, country, city } = this.state;
      const data = {
        latitude: latitude || profileInfo.location.latitude,
        longitude: longitude || profileInfo.location.longitude,
        name: stationName || profileInfo.name,
        phoneNumber: phoneNumber || profileInfo.phoneNumber,
        secondPhoneNumber: phoneNumberSecond || profileInfo.secondPhoneNumber,
        thirdPhoneNumber: phoneNumberThird || profileInfo.thirdPhoneNumber,
        address: address || profileInfo.location.address,
        countryModel: country || profileInfo.location.countryModel,
        cityModel: city || profileInfo.location.cityModel
      };
      if (updateProfileInfo) {
        updateProfileInfo(data);
      }
    };

    render() {
      const { profileInfo, language } = this.props;
      const { latitude, longitude, city, country, address } = this.state;
      const latitudeProps = profileInfo && profileInfo.location && profileInfo.location.latitude;
      const longitudeProps = profileInfo && profileInfo.location && profileInfo.location.longitude;
      return (
        <div className="edit-profile">
          <div className="edit-profile-container">
            <div className="navigation-wrap">
              <span onClick={ () => history.push('/station-service/profile') } style={{ margin: 0 }} className="icon icon-btn-back" />
              <span style={{ marginLeft: 25 }} className="station-name">{ 'Edit profile' }</span>
            </div>
            <div className="edit-profile edit-profile__wrap">
              <div className="container-maps">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: googleApiKey,
                    language: `${language}`
                  }}
                  onClick={ this.getCoordinate }
                  defaultCenter={{
                    lat: latitude || latitudeProps,
                    lng: longitude || longitudeProps
                  }}
                  center={{
                    lat: latitude || latitudeProps,
                    lng: longitude || longitudeProps
                  }}
                  defaultZoom={ 14 }
                  yesIWantToUseGoogleMapApiInternals
                >
                  <CurrentPin
                    lat={ latitude || latitudeProps }
                    lng={ longitude || longitudeProps } />
                </GoogleMapReact>
              </div>
              <div className="profile">
                <Input
                  defaultValue={ profileInfo.name }
                  onChange={ (event: { target: { value: string } }) => this.setState({ stationName: event.target.value }) }
                  type="text"
                  maxLength={ 50 }
                  labelName={ 'Service station name*' }
                  className="input input-edit-name" />
                <div className="phone-number-wrap">
                  <span className="label-text">{ 'Phone number' }</span>
                  <PhoneInput
                    className="input-phone-number"
                    maxLength={ 14 }
                    value={ profileInfo.phoneNumber }
                    onChange={ (phoneNumber) => this.setState({ phoneNumber }) } />
                </div>
                <div className="phone-number-wrap">
                  <span className="label-text">{ 'Second phone number' }</span>
                  <PhoneInput
                    maxLength={ 14 }
                    className="input-phone-number"
                    value={ profileInfo.secondPhoneNumber }
                    onChange={ (phoneNumberSecond) => this.setState({ phoneNumberSecond }) } />
                </div>
                <div className="phone-number-wrap">
                  <span className="label-text">{ 'Third phone number' }</span>
                  <PhoneInput
                    maxLength={ 14 }
                    className="input-phone-number"
                    value={ profileInfo.thirdPhoneNumber }
                    onChange={ (phoneNumberThird) => this.setState({ phoneNumberThird }) } />
                </div>
                <Input
                  defaultValue={ profileInfo.location && profileInfo.location.countryModel }
                  value={ country || (profileInfo.location && profileInfo.location.countryModel) }
                  onChange={ (event: { target: { value: string } }) => this.setState({ country: event.target.value }) }
                  type="text"
                  labelName={ 'Country*' }
                  className="input input-edit-name" />
                <Input
                  defaultValue={ profileInfo.location && profileInfo.location.cityModel }
                  value={ city || (profileInfo.location && profileInfo.location.cityModel) }
                  onChange={ (event: { target: { value: string } }) => this.setState({ city: event.target.value }) }
                  type="text"
                  labelName={ 'City*' }
                  className="input input-edit-name" />
                <Input
                  defaultValue={ address || (profileInfo.location && profileInfo.location.address) }
                  value={ address || (profileInfo.location && profileInfo.location.address) }
                  onChange={ (event: { target: { value: string } }) => this.setState({ address: event.target.value }) }
                  type="text"
                  labelName={ 'Address*' }
                  className="input input-edit-name" />
              </div>
            </div>
          </div>
          <div className="button-wrap-sing-out">
            <button style={{ marginLeft: 0 }} onClick={ this.onSaveInfoProfile } className="button button-save">{ 'Save' }</button>
          </div>
        </div>
      );
    };

}

export default EditProfile;
