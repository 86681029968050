import ProfileInfoPage from './ProfileInfoPage';
import { connect } from 'react-redux';
import { getMyProfileInfo } from './module/actions';
import { logOut, setLocale } from '../SignIn/module/actions';
import { IProfileStation } from './module/interfaces';

const mapStateToProps = (state: { profileStationReducer: IProfileStation, i18nState: IProfileStation}) => ({
  profileInfo: state.profileStationReducer.profileInfo,
  arrCountries: state.profileStationReducer.countries,
  arrCar: state.profileStationReducer.arrCar,
  arrMotorcycle: state.profileStationReducer.arrMotorcycle,
  language: state.i18nState.lang,
});

const mapDispatchToProps = {
  getMyProfileInfo,
  logOut,
  setLocale,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfoPage);
