import React, { Component, createRef } from 'react';
import {
  dateFormat, timeFormatBooking, dateCalendar, dateAction,
} from '../../services/utils';
import './BookingPage.scss';
import ItemBooking from './ItemBooking';
import { DatePicker } from '../../commonComponents/DatePicker/DatePicker';
import { SelectBookings } from './SelectBookings/SelectBookings';
import { history } from '../../index';
import { routesStation, sortingOptions } from '../../assets/constants';
import { ModalWindow } from '../../commonComponents/ModalWindow/ModalWindow';
import CreateItemBooking from './CreateItemBooking';
import ReactPaginate from 'react-paginate';
import { IBookingList } from './module/interfaces';
import Select from 'react-select';
import { FilterPanel } from './FilterPanel/FilterPanel';


interface IBookingProps {
  getBookingList: any;
  bookingList: IBookingList[];
  bookingListRequest: IBookingList[];
  totalBooking: number;
  totalBookingRequest: number;
  createItemBooking: any;
  arrEmployees: [];
  arrVehicles: [];
  getBookingRequestList: any;
  getEmployeesList: () => void;
  getBrandCarList: () => void;
  arrBrands: [];
  getModelCarList: (id: number) => void;
  arrModels: [];
  cleanModels: () => void;
  profileInfo: {
    name: string;
  };
  getMyProfileInfo: () => void;
}

interface IState {
  date: Date | Date[] | null;
  newDate: Date | Date[] | null;
  isOpenCalendar: boolean;
  isAddBooking: boolean;
  dateAddBooking: Date | null;
  time: any;
  text: string;
  idEmployee: number | null;
  vinNumber: any;
  idVehicle: number | null;
  phoneNumber: string;
  ownerName: string;
  isOpenFilter: boolean;
  vin: string;
  employee: number | null,
  ownerPhoneNumber: string;
  brand: number | null;
  model: number | null;
}

class BookingPage extends Component<IBookingProps, IState> {

  private readonly refEmployee: any;
  private readonly refVin: any;
  private readonly refBrand: any;
  private readonly refModel: any;
  private readonly refName: any;
  private readonly refPhone: any;

  constructor(props: IBookingProps) {
    super(props);
    this.state = {
      date: new Date(),
      isOpenCalendar: false,
      newDate: new Date(),
      isAddBooking: false,
      dateAddBooking: null,
      time: null,
      text: '',
      idEmployee: null,
      idVehicle: null,
      vinNumber: '',
      phoneNumber: '',
      ownerName: '',
      isOpenFilter: false,
      ownerPhoneNumber: '',
      vin: '',
      employee: null,
      brand: null,
      model: null
    };

    this.refEmployee = createRef();
    this.refVin = createRef();
    this.refBrand = createRef();
    this.refModel = createRef();
    this.refName = createRef();
    this.refPhone = createRef();
  }

  componentDidMount(): void {
    const { getBookingList, getBookingRequestList, getMyProfileInfo } = this.props;
    const { date } = this.state;
    getBookingList({ date: dateAction(date) });
    getBookingRequestList();
    getMyProfileInfo();
  }

  cancelSelectDate = () => {
    this.setState({ isOpenCalendar: false });
  };

  handleChangeDate = (date: Date) => {
    this.setState({ date });
  };

  onSelectDate = () => {
    const { date } = this.state;
    const { getBookingList, getBookingRequestList } = this.props;
    this.setState({
      newDate: date, isOpenCalendar: false
    });
    if (history.location.pathname === routesStation.booking) {
      getBookingList({ date: dateAction(date) });
    } else {
      getBookingRequestList({ date: dateAction(date) });
    }
  };

  handleChangeCreateDate = (createDate: Date) => {
    this.setState({ dateAddBooking: createDate });
  };

  handleChangeTime = (time: any) => {
    this.setState({ time });
  };

  handleChangeText = (event: any) => {
    this.setState({ text: event.target.value });
  };

  handleChangeEmployee = (selectOption: any) => {
    this.setState({ idEmployee: selectOption.value });
  };

  handleChangeVehicle = (selectOption: any) => {
    this.setState({ idVehicle: selectOption.value });
  };

  saveItemBooking = async () => {
    const { dateAddBooking, time, ownerName, text, idEmployee, vinNumber, idVehicle, phoneNumber } = this.state;
    const { createItemBooking } = this.props;
    const date = {
      createdAt: dateAddBooking,
      startTime: time[0]._d,
      endTime: time[1]._d,
      text,
      vin: vinNumber,
      ownerPhoneNumber: phoneNumber,
      vehicle: idVehicle,
      employee: idEmployee || undefined,
      ownerName
    };
    await createItemBooking(date);
    await this.setState({
      isAddBooking: false, ownerName: ''
    });
  };

  onPageChange = (currentPage: any) => {
    const { getBookingList, getBookingRequestList } = this.props;
    const { date } = this.state;
    let offset = 0;
    if (currentPage !== 0) {
      offset = currentPage.selected * 5;
      if (history.location.pathname === routesStation.booking) {
        getBookingList({
          date: dateAction(date), offset
        });
      } else {
        getBookingRequestList({ offset });
      }
    }
  };

  getListBooking = () => {
    const { date } = this.state;
    const { getBookingList } = this.props;
    history.push('/station-service/booking');
    getBookingList({ date: dateAction(date) });
  };

  getListBookingRequests = () => {
    const { getBookingRequestList } = this.props;
    history.push('/station-service/booking/requests');
    getBookingRequestList();
  };

  handleSorting = (selectOptions: any) => {
    const { getBookingList } = this.props;
    const { date } = this.state;
    if (selectOptions.value === ('asc' || 'desc')) {
      getBookingList({
        date: dateAction(date), order: selectOptions.value
      });
    } else {
      getBookingList({
        date: dateAction(date), orderBy: selectOptions.value
      });
    }
  };

  onOpenFilter = async () => {
    const { getEmployeesList, getBrandCarList } = this.props;
    await this.setState({ isOpenFilter: true });
    await getEmployeesList();
    await getBrandCarList();
  };

  handleValueFilter = (name: any, value: any) => {
    const { getModelCarList } = this.props;
    this.setState({ [name]: value } as IState, () => this.filterAction());
    if (name === 'brand') {
      this.refModel.current.state.value = null;
      getModelCarList(value);
    }
  };

  filterAction = () => {
    const { date, vin, employee, ownerName, ownerPhoneNumber, model, brand } = this.state;
    const { getBookingList, getBookingRequestList } = this.props;
    if (history.location.pathname === routesStation.booking) {
      getBookingList({
        date: dateAction(date),
        employee,
        vin,
        brand,
        model,
        ownerName,
        ownerPhoneNumber,
      });
    } else {
      getBookingRequestList({
        date: dateAction(date),
        employee,
        vin,
        brand,
        model,
        ownerName,
        ownerPhoneNumber,
      });
    }
  };

  resetFilter = () => {
    const { getBookingList, getBookingRequestList, cleanModels } = this.props;
    const { date } = this.state;
    this.refEmployee.current.state.value = null;
    this.refVin.current.value = null;
    this.refBrand.current.state.value = null;
    this.refModel.current.state.value = null;
    this.refName.current.value = null;
    this.refPhone.current.value = null;
    getBookingList({ date: dateAction(date) });
    getBookingRequestList();
    cleanModels();
  };

  render() {
    const { totalBooking, arrEmployees, arrVehicles, totalBookingRequest, arrBrands, arrModels, profileInfo } = this.props;
    const { isOpenCalendar, date, newDate, isAddBooking, dateAddBooking, isOpenFilter } = this.state;
    const isVisiblePagination = (totalBooking && (history.location.pathname === routesStation.booking))
        || (totalBookingRequest && (history.location.pathname !== routesStation.booking));
    const totalPage = Math.ceil(totalBooking / 5);
    const totalPageRequests = Math.ceil(totalBookingRequest / 5);
    const nextLabel = <div className="arrow arrow_right" />;
    const prevLabel = <div className="arrow arrow_left" />;
    return (
      <>
        <div className="booking-page">
          <div className="booking-page-container">
            <span className="station-name">{ profileInfo.name }</span>
            <div className="filter-panel">
              <SelectBookings
                countRequests={ totalBookingRequest }
                onOpenBookingRequests={ this.getListBookingRequests }
                onOpenBooking={ this.getListBooking } />
              <div className="button-container">
                <button onClick={ this.onOpenFilter } className="button btn btn_filter">
                  <span className="icon icon-filter" />
                  <span className="text-btn">{ 'Filter' }</span>
                </button>
                <button onClick={ () => this.setState({ isOpenCalendar: true }) } className="button btn btn_calendar">
                  <span className="icon icon-calendar" />
                  <span className="text-btn">{ dateCalendar(newDate) }</span>
                </button>
                {
                  isOpenCalendar ? <DatePicker
                    onCancel={ this.cancelSelectDate }
                    onSaveDate={ this.onSelectDate }
                    onChangeDate = { (date: Date) => this.handleChangeDate(date) }
                    value={ date } /> : null
                }
                <button className="button btn btn_booking">
                  <span className="icon icon-add-booking" />
                  <span onClick={ () => this.setState({ isAddBooking: true }) } className="text-btn">{ 'Add booking' }</span>
                </button>
              </div>
            </div>
            <div className="sorting-panel">
              <span className="text-sorting-panel">
                { (history.location.pathname === routesStation.booking) ? 'Booking list' : 'Requests List' }
              </span>
              <div className="sorting-panel-container">
                <span className="sort-text">{ 'Sort by' }</span>
                <Select
                  className="sorting-select-wrap"
                  classNamePrefix="sorting-select"
                  onChange={ (selectOptions) => this.handleSorting(selectOptions) }
                  defaultValue={{
                    value: 'asc', label: 'From early to late'
                  }}
                  options={ sortingOptions } />
              </div>
            </div>
            <div className="container-booking-list">
              { this.renderBooking() }
            </div>
          </div>
          {
            isVisiblePagination
              ? <div className="pagination-wrap">
                <ReactPaginate
                  pageCount={ (history.location.pathname === routesStation.booking) ? totalPage : totalPageRequests }
                  onPageChange={ this.onPageChange }
                  containerClassName="pagination"
                  nextLabel={ nextLabel }
                  previousLabel={ prevLabel }
                  marginPagesDisplayed={ 10 }
                  pageRangeDisplayed={ 10 } />
                </div> : null
          }
          {
            isOpenFilter
              ? <FilterPanel
                arrOptionsEmployee={ arrEmployees }
                refEmployee={ this.refEmployee }
                refVin={ this.refVin }
                refBrand={ this.refBrand }
                refModel={ this.refModel }
                refName={ this.refName }
                refPhone={ this.refPhone }
                onChangeEmployee={ (selectEmployee: any) => this.handleValueFilter('employee', selectEmployee.value) }
                onChangeVin={ (event: any) => this.handleValueFilter('vin', event.target.value) }
                onChangeOwnerName={ (event: any) => this.handleValueFilter('ownerName', event.target.value) }
                onChangePhone={ (event: any) => this.handleValueFilter('ownerPhoneNumber', event.target.value) }
                onChangeBrand={ (selectBrand: any) => this.handleValueFilter('brand', selectBrand.value) }
                onChangeModel={ (selectModel: any) => this.handleValueFilter('model', selectModel.value) }
                onResetFilter={ this.resetFilter }
                arrOptionsBrands={ arrBrands }
                arrOptionsModels={ arrModels }
                onCloseFilter={ () => this.setState({ isOpenFilter: false }) } />
              : null
          }
        </div>
        <ModalWindow
          modalClassName="modal-add-booking"
          isModalOpen={ isAddBooking }
        >
          <CreateItemBooking
            handleChangeDate={ (createDate: Date) => this.handleChangeCreateDate(createDate) }
            onSaveBooking={ this.saveItemBooking }
            onChangeTime={ (time) => this.handleChangeTime(time) }
            onChangeText={ this.handleChangeText }
            onCancel={ () => this.setState({
              isAddBooking: false, ownerName: ''
            }) }
            optionsEmployee={ arrEmployees }
            optionsVehicles={ arrVehicles }
            onChangeVin={ (event: { target: { value: any } }) => this.setState({ vinNumber: event.target.value }) }
            onChangeEmployee={ (selectOption: object) => this.handleChangeEmployee(selectOption) }
            onChangeVehicle={ (selectOption: object) => this.handleChangeVehicle(selectOption) }
            onChangePhoneNumber={ (event: { target: { value: any } }) => this.setState({ phoneNumber: event.target.value }) }
            onChangeNameOwner={ (event: { target: { value: any } }) => this.setState({ ownerName: event.target.value }) }
            valueDateBooking={ dateAddBooking } />
        </ModalWindow>
      </>
    );
  }

  renderBooking() {
    const { totalBooking, bookingListRequest, totalBookingRequest, bookingList } = this.props;
    if (history.location.pathname === routesStation.booking) {
      if (totalBooking) {
        return bookingList && bookingList.map((item) => (<ItemBooking
          dateBooking={ dateFormat(item.createdAt) }
          intervalTime={ `${timeFormatBooking(item.startTime)}-${timeFormatBooking(item.endTime)}` }
          employeeName={ item.employee.name }
          vinNumber={ item.vehicle.vin }
          ownerName={ item.vehicle.ownerName }
          ownerPhone={ item.vehicle.ownerPhoneNumber }
          description={ item.text }
          isDone={ item.isDone }
          brandCar={ item.vehicle.brand.name }
          modelCar={ item.vehicle.model.name }
          yearCar={ item.vehicle.manufacturingYear }
          id={ item.id } />));
      }
      return (<div className="empty-state-booking">
        <span className="empty-state-text">{ 'You don’t have any records yet' }</span>
        <span className="icon icon-empty-state" />
              </div>);
    }
    if (totalBookingRequest) {
      return bookingListRequest && bookingListRequest.map((item) => (<ItemBooking
        dateBooking={ dateFormat(item.createdAt) }
        intervalTime={ `${timeFormatBooking(item.startTime)}-${timeFormatBooking(item.endTime)}` }
        employeeName={ item.employee.name }
        vinNumber={ item.vehicle.vin }
        ownerName={ item.vehicle.ownerName }
        ownerPhone={ item.vehicle.ownerPhoneNumber }
        description={ item.text }
        brandCar={ item.vehicle && item.vehicle.brand && item.vehicle.brand.name }
        modelCar={ item.vehicle && item.vehicle.model && item.vehicle.model.name }
        yearCar={ item.vehicle.manufacturingYear }
        id={ item.id } />));
    }
    return (<div className="empty-state-booking">
      <span className="empty-state-text">{ 'You don’t have any requests yet' }</span>
      <span className="icon icon-empty-state" />
            </div>);
  };

}

export default BookingPage;
