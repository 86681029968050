export default {
  GET_LIST_REMINDERS_REQUEST: 'GET_LIST_REMINDERS_REQUEST',
  GET_LIST_REMINDERS_SUCCESS: 'GET_LIST_REMINDERS_SUCCESS',
  GET_LIST_REMINDERS_FAIL: 'GET_LIST_REMINDERS_FAIL',

  DELETE_ITEM_REMINDER_REQUEST: 'DELETE_ITEM_REMINDER_REQUEST',
  DELETE_ITEM_REMINDER_SUCCESS: 'DELETE_ITEM_REMINDER_SUCCESS',
  DELETE_ITEM_REMINDER_FAIL: 'DELETE_ITEM_REMINDER_FAIL',

  UPDATE_REMINDER_REQUEST: 'UPDATE_REMINDER_REQUEST',
  UPDATE_REMINDER_SUCCESS: 'UPDATE_REMINDER_SUCCESS',
  UPDATE_REMINDER_FAIL: 'UPDATE_REMINDER_FAIL',
};
