import HTTP from '../../../services/api';
import constants from './constants';
import { getQuery } from '../../../services/utils';
import { history } from '../../../index';
import { NotificationManager } from 'react-notifications';

export const getMyProfileInfo = () => (dispatch: (arg: { type: string; payload?: object; }) => void) => {
  dispatch({ type: constants.GET_INFO_PROFILE_REQUEST });

  return HTTP.get('/api/service_station/profile')
    .then((res) => {
      dispatch({
        type: constants.GET_INFO_PROFILE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_INFO_PROFILE_FAIL,
        payload: err,
      });
    });
};

export const updateProfileInfo = (data: object) => (dispatch: (arg: { type: string; payload?: object; }) => void) => {
  dispatch({ type: constants.UPDATE_INFO_PROFILE_REQUEST });

  return HTTP.patch('/api/service_station/profile', data)
    .then((res) => {
      dispatch({
        type: constants.UPDATE_INFO_PROFILE_SUCCESS,
        payload: res.data,
      });
      NotificationManager.success('Profile updated successfully');
    })
    .catch((err) => {
      dispatch({
        type: constants.UPDATE_INFO_PROFILE_FAIL,
        payload: err,
      });
    });
};

export const getSearchProducts = (query: any, search: import('axios').AxiosRequestConfig | undefined) => (dispatch: (arg: {
  type: string; payload?: any; }) => void) => {
  dispatch({ type: constants.GET_SEARCH_PRODUCT_REQUEST });

  const defaultQueryParams = {
    limit: 6,
    offset: 0,
  };
  const queryParams = {
    ...defaultQueryParams,
    ...query,
    ...search,
  };

  return HTTP.get(`/api/service_station/prices${getQuery(queryParams)}`, search)
    .then((res) => {
      dispatch({
        type: constants.GET_SEARCH_PRODUCT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_SEARCH_PRODUCT_FAIL,
        payload: err,
      });
    });
};

export const deleteItemProduct = (id: number) => (dispatch: (arg: { type: string; payload?: any; }) => void) => {
  dispatch({ type: constants.DELETE_ITEM_PRODUCT_REQUEST });

  return HTTP.delete(`/api/service_station/prices/${id}`)
    .then(() => {
      dispatch({
        type: constants.DELETE_ITEM_PRODUCT_SUCCESS,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.DELETE_ITEM_PRODUCT_FAIL,
        payload: err,
      });
    });
};

export const getCurrenciesList = () => (dispatch: (arg: { type: string; payload?: any; }) => void) => {
  dispatch({ type: constants.GET_CURRENCIES_REQUEST });

  return HTTP.get('/api/service_station/currencies')
    .then((res) => {
      dispatch({
        type: constants.GET_CURRENCIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_CURRENCIES_FAIL,
        payload: err,
      });
    });
};

export const createItemProduct = (photoFile: string, name: string, price: string, currency: string) => (dispatch: (arg: {
  type: string; payload?: any; }) => void) => {
  dispatch({ type: constants.CREATE_ITEM_PRODUCT_REQUEST });
  const formData = new FormData();
  formData.append(photoFile ? 'photo_file' : '', photoFile);
  formData.append('name', name);
  formData.append('price', price);
  formData.append('currency', currency);

  return HTTP.post('/api/service_station/prices', formData)
    .then((res) => {
      dispatch({
        type: constants.CREATE_ITEM_PRODUCT_SUCCESS,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.CREATE_ITEM_PRODUCT_FAIL,
        payload: err,
      });
    });
};

export const getProductItem = (id: number) => (dispatch: (arg: { type: string; payload?: object; }) => void) => {
  dispatch({ type: constants.GET_ITEM_PRODUCT_REQUEST });

  return HTTP.get(`/api/service_station/prices/${id}`)
    .then((res) => {
      dispatch({
        type: constants.GET_ITEM_PRODUCT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_ITEM_PRODUCT_FAIL,
        payload: err,
      });
    });
};

export const updateItemProduct = (id: number, data: {
  photo_file: string; name: string; price: string; currency: string; }) => (dispatch: (arg: {
    type: string; payload?: object; }) => void) => {
  dispatch({ type: constants.UPDATE_ITEM_PRODUCT_REQUEST });
  const formData = new FormData();
  formData.append(typeof data.photo_file !== 'object' ? '' : 'photo_file', data.photo_file);
  formData.append(data.name ? 'name' : '', data.name);
  formData.append(data.price ? 'price' : '', data.price);
  formData.append(data.currency ? 'currency' : '', data.currency);

  return HTTP.patch(`/api/service_station/prices/${id}`, formData)
    .then((res) => {
      const { data } = res;
      dispatch({
        type: constants.UPDATE_ITEM_PRODUCT_SUCCESS,
        payload: {
          data, id
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.UPDATE_ITEM_PRODUCT_FAIL,
        payload: err,
      });
    });
};

export const getSelfBrandsList = () => (dispatch: (arg: { type: string, payload?: any }) => void) => {
  dispatch({ type: constants.GET_SELF_BRANDS_REQUEST });

  return HTTP.get('/api/service_station/brands')
    .then((res) => {
      dispatch({
        type: constants.GET_SELF_BRANDS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_SELF_BRANDS_FAIL,
        payload: err,
      });
    });
};

export const changePassAction = (data: object, idStation: number) => (dispatch: (arg: { type: string; payload?: object; }) => void) => {
  dispatch({ type: constants.CHANGE_PASS_STATION_REQUEST });

  return HTTP.patch(`/api/service_station/${idStation}/password`, data)
    .then((res) => {
      dispatch({
        type: constants.CHANGE_PASS_STATION_SUCCESS,
        payload: res.data,
      });
      NotificationManager.success('Password changed successfully');
      history.push('/station-service/profile');
    })
    .catch((err) => {
      dispatch({
        type: constants.CHANGE_PASS_STATION_FAIL,
        payload: err,
      });
    });
};

export const getScheduleList = () => (dispatch: (arg: { type: string, payload?: any }) => void) => {
  dispatch({ type: constants.GET_SCHEDULE_LIST_REQUEST });

  return HTTP.get('/api/service_station/schedules')
    .then((res) => {
      dispatch({
        type: constants.GET_SCHEDULE_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: constants.GET_SCHEDULE_LIST_FAIL,
        payload: err,
      });
    });
};

export const updateStartTime = (id: number, startTime: string) => (dispatch: (arg: { type: any,
  payload: { id: number, startTime?: string } }) => void) => {
  dispatch({
    type: constants.UPDATE_START_TIME,
    payload: {
      id, startTime
    }
  });
};

export const updateEndTime = (id: number, endTime: string) => (dispatch: (arg: { type: any,
  payload: { id: number, endTime?: string } }) => void) => {
  dispatch({
    type: constants.UPDATE_END_TIME,
    payload: {
      id, endTime
    }
  });
};

export const closedDay = (id: number) => (dispatch: (arg: { payload: number, type: string }) => void) => {
  dispatch({
    type: constants.CLOSED_DAY,
    payload: id
  });
};

export const updateScheduleList = (data: []) => (dispatch: (arg: { type: string, payload?: [] }) => void) => {
  dispatch({ type: constants.UPDATE_LIST_SCHEDULE_REQUEST });

  return HTTP.patch('/api/service_station/schedules', data)
    .then((res) => {
      dispatch({
        type: constants.UPDATE_LIST_SCHEDULE_SUCCESS,
        payload: res.data,
      });
      history.push('/station-service/profile');
    })
    .catch((err) => {
      dispatch({
        type: constants.UPDATE_LIST_SCHEDULE_FAIL,
        payload: err,
      });
    });
};

