import React, { Component } from 'react';
import './ItemViewEmployee.scss';
import { ModalWindow } from '../../../commonComponents/ModalWindow/ModalWindow';
import CreateEmployee from '../CreateEmployee';
import { ItemEmployee } from '../module/interfaces';
import PropTypes from 'prop-types';

interface IProps {
    getListEmployees?: any;
    nameEmployee?: string;
    phoneNumber?: string;
    id: number;
    deleteItemEmployee: (id: number) => void;
    getItemEmployee: (id: number) => void;
    errorClear: () => void;
    employeeItem: ItemEmployee;
    updateItemEmployee: (id: number, data: { name: string, phoneNumber: string }) => void;
}

interface IState {
  isOpenMenu: boolean;
  idEmployee: null | number;
  isOpenModal: boolean;
  isUpdateItem: boolean;
  nameEmployee: string;
  phoneNumber: string;
  errorName: boolean;
  errorPhone: boolean;
}

class ItemViewEmployee extends Component<IProps, IState> {

  private wrapperRef: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpenMenu: false,
      idEmployee: null,
      isOpenModal: false,
      isUpdateItem: false,
      nameEmployee: '',
      phoneNumber: '',
      errorName: false,
      errorPhone: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: { target: any }) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpenMenu: false });
    }
  }

  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  }

  onRemoveEmployee = (id: number) => {
    this.props.deleteItemEmployee(id);
    this.setState({ isOpenModal: false });
  }

  onOpenModalUpdate = async (id: number) => {
    await this.props.getItemEmployee(id);
    await this.setState({ isUpdateItem: true, idEmployee: id });
  }

  handleName = (event: { target: { value: string } }) => {
    if (!event.target.value) {
      this.setState({ errorName: true });
    } else {
      this.clearErrorName();
    }
  };

  clearErrorName = () => {
    const { errorName } = this.state;
    const { errorClear } = this.props;

    if (errorName) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorName: false });
    }
  };

  handlePhone = (event: { target: { value: string } }) => {
    const isValid = !event.target.value || (event.target.value.length < 8) || (event.target.value.length > 14);
    if (isValid) {
      this.setState({ errorPhone: true });
    } else {
      this.clearErrorPhone();
    }
  };

  clearErrorPhone = () => {
    const { errorPhone } = this.state;
    const { errorClear } = this.props;

    if (errorPhone) {
      if (errorClear) {
        errorClear();
      }
      return this.setState({ errorPhone: false });
    }
  };

  updateEmployee = () => {
    const { nameEmployee, phoneNumber, idEmployee, errorName, errorPhone } = this.state;
    if (idEmployee !== null && !errorName && !errorPhone) {
      this.props.updateItemEmployee(idEmployee, {
        name: nameEmployee, phoneNumber
      });
      this.setState({ isUpdateItem: false });
    }
  }

  render() {
    const { isOpenMenu, isOpenModal, isUpdateItem, errorName, errorPhone } = this.state;
    const { nameEmployee, phoneNumber, id, employeeItem } = this.props;
    return (
      <>
        <div className="item-employee">
          <div className="employee-wrap">
            <span className="icon icon-employee" />
            <span className="name-employee">{ nameEmployee }</span>
          </div>
          <div className="employee-wrap">
            <span className="icon icon-phone" />
            <span className="name-employee name-employee--phone">{ phoneNumber }</span>
            <div className="menu-wrap menu-wrap--employee">
              <button className="button button-menu"
                onClick={ () => this.setState({ isOpenMenu: true }) }
              >
                <span className="icon icon-ellipsis" />
              </button>
              { isOpenMenu
              && <div ref={ this.setWrapperRef } className="menu">
                <span onClick={ () => this.onOpenModalUpdate(id) } className="edit-item">{ this.context.t('edit_text') }</span>
                <span onClick={ () => this.setState({ isOpenModal: true }) } className="delete-item">{ this.context.t('remove_btn') }</span>
                 </div>
              }
            </div>
          </div>
        </div>
        <ModalWindow
          modalClassName="modal-remove-item"
          isModalOpen={ isOpenModal }
        >
          <div className="content-modal">
            <span className="text-remove-item">{ this.context.t('remove_employee_text') }</span>
            <span className="title-field">{ this.context.t('remove_employee_sub_text') }</span>
            <div className="button-wrap-sing-out">
              <button onClick={ () => this.setState({ isOpenModal: false }) }
                className="button button-cancel"
              >{ this.context.t('cancel_btn') }
              </button>
              <button onClick={ () => this.onRemoveEmployee(id) }
                className="button button-remove"
              >{ this.context.t('remove_btn') }
              </button>
            </div>
          </div>
        </ModalWindow>
        <ModalWindow
          modalClassName="modal-add-employee"
          isModalOpen={ isUpdateItem }
        >
          <CreateEmployee
            onChangeName={ (event: { target: { value: string } }) => this.setState({ nameEmployee: event.target.value }) }
            onChangeNumber={ (event: { target: { value: string } }) => this.setState({ phoneNumber: event.target.value }) }
            onCancel={ () => this.setState({ isUpdateItem: false }) }
            onBlurName={ (event: { target: { value: string } }) => this.handleName(event) }
            onBlurNumber={ (event: { target: { value: string } }) => this.handlePhone(event) }
            errorName={ errorName }
            errorPhone={ errorPhone }
            defaultValueName={ employeeItem.name }
            defaultValueNumber={ employeeItem.phoneNumber }
            onSaveEmployee={ this.updateEmployee } />
        </ModalWindow>
      </>
    );
  }

}

// @ts-ignore
ItemViewEmployee.contextTypes = { t: PropTypes.func.isRequired };

export default ItemViewEmployee;
