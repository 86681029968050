import React, { Component } from 'react';
import './ProfileInfoPage.scss';
import {
  dateFormat, timeFormat, ucFirst
} from '../../services/utils';
import { ModalWindow } from '../../commonComponents/ModalWindow/ModalWindow';
import { history } from '../../index';
import Select from 'react-select';
import { arrLanguages } from '../../assets/constants';
import PropTypes from 'prop-types';
import { ISchedule, IGalleryItem } from './module/interfaces';

interface IProfileProps {
  getMyProfileInfo: () => void;
  profileInfo: IProfileInfo,
  logOut: () => void;
  getCountriesList: () => void;
  arrCountries: [],
  setLocale: (local: string) => void;
  language: string;
  arrCar: [];
  arrMotorcycle: [];
}

interface IProfileInfo {
  name: string,
  email: string,
  phoneNumber: string,
  secondPhoneNumber: string,
  thirdPhoneNumber: string,
  createdAt: string,
  verifiedAt: string,
  schedule: ISchedule[],
  location: {
    address: string,
    countryModel: string,
    cityModel: string,
  },
  gallery: IGalleryItem[]
}

interface IState {
  isOpenModal: boolean;
}

class ProfileInfoPage extends Component<IProfileProps, IState> {

  constructor(props: IProfileProps) {
    super(props);
    this.state = { isOpenModal: false };
  }

  componentDidMount(): void {
    const { getMyProfileInfo } = this.props;
    getMyProfileInfo();
  }

  onOpenPriceList(): void {
    history.push('/station-service/profile/price-list');
  }

  editProfile = () => {
    history.push('/station-service/profile/edit');
  };

  handleChangeLocale = (selectLanguage: any) => {
    this.props.setLocale(selectLanguage.value);
    localStorage.setItem('locale', selectLanguage.value);
  };

  render() {
    const { logOut, profileInfo, language, arrCar, arrMotorcycle } = this.props;
    const { isOpenModal } = this.state;
    return (
      <>
        <div className="profile-info-page">
          <div className="profile-wrap">
            <div className="wrapper-page">
              <div className="profile-info-wrap">
                <div className="localization-container">
                  <span className="station-name">{ profileInfo.name }</span>
                  <Select
                    onChange={ (selectLanguage) => this.handleChangeLocale(selectLanguage) }
                    className="language-select-wrap"
                    classNamePrefix="language-select"
                    defaultValue={{
                      value: language, label: language.toUpperCase()
                    }}
                    value={{
                      value: language, label: language.toUpperCase()
                    }}
                    options={ arrLanguages } />
                </div>
                <div className="field-wrap">
                  <span className="title-field">{ this.context.t('e_mail') }</span>
                  <span className="field-value">{ profileInfo.email }</span>
                </div>
                <div className="contact-info">
                  <div className="contact-info-wrap">
                    <div className="field-wrap">
                      <span className="title-field">{ this.context.t('phone_number') }</span>
                      <span className="field-value">{ profileInfo.phoneNumber }</span>
                    </div>
                    <div className="field-wrap">
                      <span className="title-field">{ this.context.t('second_phone_number') }</span>
                      <span className="field-value">{ profileInfo.secondPhoneNumber }</span>
                    </div>
                    <div className="field-wrap">
                      <span className="title-field">{ this.context.t('third_phone_number') }</span>
                      <span className="field-value">{ profileInfo.thirdPhoneNumber }</span>
                    </div>
                    <div className="field-wrap">
                      <span className="title-field">{ this.context.t('station_added_text') }</span>
                      <span className="field-value">{ dateFormat(profileInfo.createdAt) }</span>
                    </div>
                  </div>
                  <div className="contact-info-wrap contact-info-wrap--info">
                    <div className="field-wrap">
                      <span className="title-field">{ this.context.t('country_text') }</span>
                      <span className="field-value">{ profileInfo.location && profileInfo.location.countryModel }</span>
                    </div>
                    <div className="field-wrap">
                      <span className="title-field">{ this.context.t('city_text') }</span>
                      <span className="field-value">{ profileInfo.location && profileInfo.location.cityModel }</span>
                    </div>
                    <div className="field-wrap">
                      <span className="title-field">{ this.context.t('address_text') }</span>
                      <div className="wrap-address">
                        <div className="field-value-address">{ profileInfo.location && profileInfo.location.address }</div>
                        <div className="full-address">{ profileInfo.location && profileInfo.location.address }</div>
                      </div>
                    </div>
                    <div className="field-wrap">
                      <span className="title-field">{ this.context.t('station_verified_text') }</span>
                      <span className="field-value">{ dateFormat(profileInfo.verifiedAt) }</span>
                    </div>
                  </div>
                </div>
                <div className="button-wrap">
                  <button onClick={ this.onOpenPriceList } className="button button-price-list">
                    <span style={{ marginLeft: 0 }} className="text-btn">{ this.context.t('price_list_btn') }</span>
                  </button>
                  <button onClick={ () => history.push('/station-service/profile/change-pass') }
                    className="button button-change-password"
                  >{ this.context.t('change_password') }
                  </button>
                  <button onClick={ this.editProfile }
                    className="button button-edit-profile"
                  >{ this.context.t('edit_profile_btn') }
                  </button>
                  <button onClick={ () => this.setState({ isOpenModal: true }) }
                    className="button button-sign-out"
                  >{ this.context.t('sign_out') }
                  </button>
                </div>
              </div>
              <div className="profile-info-wrap profile-info-wrap--gallery">
                <span className="station-name">{ this.context.t('photos_title') }</span>
                { profileInfo.gallery && !profileInfo.gallery.length
                  ? <span className="empty-state-gallery">{ this.context.t('empty_state_preview_gallery') }</span>
                  : <div className="photos-list">
                    {
                      profileInfo.gallery && profileInfo.gallery.map((item) => (
                        <span style={{ backgroundImage: `url("${item.previewUrl}")` }} className="container-photo" />))
                    }
                  </div>
                }
                <div className="schedule schedule--button">
                  <button onClick={ () => history.push('/station-service/profile/gallery') } className="button button-edit-profile">
                    { profileInfo.gallery ? this.context.t('view_photos_btn') : this.context.t('add_photo_btn') }
                  </button>
                </div>
              </div>
            </div>
            <div className="wrapper-page">
              <div className="profile-info-wrap profile-info-wrap--schedule">
                <div className="schedule">
                  <span className="station-name">{ this.context.t('schedule_title') }</span>
                  <div className="schedule-list">
                    {
                      profileInfo && profileInfo.schedule
                    && profileInfo.schedule.map((item) => (
                      <div className="day-item-container">
                        <span className="time-interval time-interval_day-item">{ ucFirst(item.weekday.name, 3) }</span>
                        <span
                          className="time-interval"
                        >{ (item.startTime) ? `${timeFormat(item.startTime)} - ${timeFormat(item.endTime)}` : 'closed' }
                        </span>
                      </div>
                    )) }
                  </div>
                </div>
                <div className="schedule schedule--button">
                  <button onClick={ () => history.push('/station-service/profile/schedule') }
                    className="button button-edit-profile"
                  >{ this.context.t('edit_schedule_btn') }
                  </button>
                </div>
              </div>
              <div className="profile-info-wrap profile-info-wrap--brands">
                <div className="schedule">
                  <span className="station-name">{ this.context.t('brands_title') }</span>
                  <div className="schedule-list">
                    <div className="brands-type">
                      <span className="time-interval time-interval_day-item">{ this.context.t('car_text') }</span>
                      <span className="time-interval time-interval_day-item">{ arrCar.length }</span>
                    </div>
                    <div className="brands-type">
                      <span className="time-interval time-interval_day-item">{ this.context.t('moto_text') }</span>
                      <span className="time-interval time-interval_day-item">{ arrMotorcycle.length }</span>
                    </div>
                  </div>
                </div>
                <div className="schedule schedule--button">
                  <button className="button button-edit-profile">{ this.context.t('view_list_btn') }</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalWindow
          modalClassName="modal-sign-out"
          isModalOpen={ isOpenModal }
        >
          <div className="sign-out-wrap">
            <span className="text-sign-out">{ this.context.t('sign_out') }</span>
            <span className="title-field">{ 'Are you sure you want to sign out ?' }</span>
            <div className="button-wrap-sing-out">
              <button onClick={ () => this.setState({ isOpenModal: false }) }
                className="button button-cancel"
              >{ this.context.t('cancel_btn') }
              </button>
              <button onClick={ logOut }
                className="button button-sign-out"
              >{ this.context.t('sign_out') }
              </button>
            </div>
          </div>
        </ModalWindow>
      </>
    );
  }

}

// @ts-ignore
ProfileInfoPage.contextTypes = { t: PropTypes.func.isRequired };

export default ProfileInfoPage;
